import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailService } from 'src/app/services/mail/mail.service';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-modificadatos-dialog',
  templateUrl: './modificadatos-dialog.component.html',
  styleUrls: ['./modificadatos-dialog.component.scss']
})
export class ModificadatosDialogComponent implements OnInit{

  // declarations
  eleccion: string;
  numContratosLuz: number;
  numContratosGas: number;
  entorno: string;
  form: FormGroup;
  cuenta: any;
  nombre: any;
  direccion: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private _mailService: MailService,
    private fb: FormBuilder,
    private almnService:AlmacenamientoService,
    public dialogRef: MatDialogRef<ModificadatosDialogComponent>){
    this.dialogRef.updateSize('100%','100%');
    this.eleccion = "none";
    this.numContratosLuz = this.data.numContratosLuz;
    this.numContratosGas = this.data.numContratosGas;
    this.entorno = this.data.entorno;

  }

  ngOnInit() {
    this.form = this.fb.group({
      cuenta: new FormControl(),
      nombre: new FormControl(),
      direccion: new FormControl()
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.eleccion);
  }

  clickEleccion(eleccion){
    this.eleccion = eleccion;
    // this.dialogRef.close(this.eleccion);
  }

  onEnviarCambios() {

      let cuerpo = '';
      let replyTo = '';
      
      (this.data.EmailContacto !="") ? replyTo = this.data.EmailContacto : null;

      if (this.almnService.getValue('TipoCliente') == '3') {
        cuerpo = '<h3>Los datos solicitados para modificar por el Administrador "' + this.almnService.getValue('usuarioCambioPass') + '"  son:</h3>';
      }  else {
        cuerpo = '<h3>Los datos solicitados para modificar por el Cliente "' + this.almnService.getValue('usuarioCambioPass') + '" son:</h3>';
      }
      (replyTo!="") ? cuerpo = '<p>El cliente con email: ' + replyTo + '  </p>' : null;
      cuerpo = cuerpo + '<p>Cuenta: ' + this.form.value.cuenta + '</p>';
      cuerpo = cuerpo + '<p>Nombre: ' + this.form.value.nombre + '</p>';
      cuerpo = cuerpo + '<p>Dirección: ' + this.form.value.direccion + '</p>';

      let email: Email = {Asunto: 'Envío de formulario de Modifica tus datos', Destinatario: environment.email, Cuerpo: cuerpo, ReplyTo: replyTo};

      this._mailService
        .getEnvioEmail(email)
        .subscribe(async data => {
            // this.isLoading = false;
            // console.log(data);
          });

  }

}
