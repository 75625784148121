import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../config/global/global.service';
import { catchError } from 'rxjs/operators';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class ResumenService {
  urlApi: string;
  token: string;
  adminInfo: any;
  response:any;

  constructor(private http: HttpClient, private _globalService: GlobalService,private almnService:AlmacenamientoService) {
    this.urlApi = this._globalService.getUrlApi();
    this.token = this.almnService.getValue('token');
  }

  getAdminInfo(id, isAdmin): Observable<any> {
    const body = { Id: id, IsAdministrador: isAdmin};
    return this.http.post<any>(this.urlApi + 'Resumen/GetInfoAdmin', body, this._globalService.getHttpHeaders())
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    // console.log(error.status);
    return (['error']);
  }

}


