<app-header></app-header>

<div class="pageContent">
  <div>
    <!-- <h3 id="contratosActivosResumen">Contratos activos: {{ultimosContratos.length}}</h3> -->

    <mat-card class="tablaListadoContratos">
      <h3 style="margin-bottom: 10px;">Contratos activos: {{ultimosContratos.length}}</h3>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>{{dataSource[0].CodigoContrato}}</div>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].ContratoSituacion">
                    {{dataSource[0].ContratoSituacion}} 
                    <i>-</i>
                    <span>{{dataSource[0].Tarifa}}</span>
                  </td>
                  <td>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].NombreCliente">{{dataSource[0].Tarifa}}</td>
                  <td title="dataSource[0].NombreCliente"><span>{{dataSource[0].NombreCliente}}</span></td>
                  <td title="{{dataSource[0].DireccionSuministro}}"><span>{{dataSource[0].DireccionSuministro}}</span></td>
                  <td>
                    <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                  </td>
                  <td>
                    <div>
                      <i class="fal fa-angle-down"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel-header>
          <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

            <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
            
            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
                <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource[0].CodigoContrato"> 
                  <td>
                    <div >{{contrato.CodigoContrato}}</div>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td>
                    {{contrato.ContratoSituacion}}
                    <i>-</i>
                    <span>{{contrato.Tarifa}}</span>
                  </td>
                  <td>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                  <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                  <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
                </tr>
              </tbody>
            </table>
          </div>
           
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <div class="container1">

    <mat-card class="cardsContents cardDatosPersonales">
      <article>
        <div>
          <h2 class="tituloCards">Datos personales</h2>

          <table>
            <tr>
              <td>Nombre</td>
              <td *ngIf="!editarDatosPersonales">{{NombreCliente}}</td>
              <td *ngIf="editarDatosPersonales">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="NombreCliente">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Primer apellido</td>
              <td *ngIf="!editarDatosPersonales">{{Apellido1Cliente}}</td>
              <td *ngIf="editarDatosPersonales">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="Apellido1Cliente">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Segundo apellido</td>
              <td *ngIf="!editarDatosPersonales">{{Apellido2Cliente}}</td>
              <td *ngIf="editarDatosPersonales">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="Apellido2Cliente">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td *ngIf="!editarDatosPersonales">{{Email}}</td>
              <td *ngIf="editarDatosPersonales">
                
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="Email">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Teléfono</td>
              <td *ngIf="!editarDatosPersonales">{{Tlf}}</td>
              <td *ngIf="editarDatosPersonales">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="Tlf">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- <div>
          <button *ngIf="!editarDatosPersonales" class="btn btnStyle_2" (click)="editarDatosPersonales = true; editarFacturacion = false; editarPrevision = false; editarSuministro = false;">Modificar <i class="fas fa-pencil"></i></button>
          <button *ngIf="editarDatosPersonales" class="btn btnStyle_2" style="float: left; margin-left: 20px;" (click)="editarDatosPersonales = false;">Cancelar <i class="fas fa-pencil"></i></button>
          <button *ngIf="editarDatosPersonales" class="btn btnStyle_2" style="float: right;" (click)="modificarDatos('personales')">Enviar <i class="fas fa-pencil"></i></button>
        </div> -->
      </article>

    </mat-card>

    <mat-card class="cardsContents cardDatosFacturacion">
      <h2 class="tituloCards">Datos de facturación</h2>

      <table>
        <tr>
          <td>Nombre</td>
          <td *ngIf="!editarFacturacion">{{NombreCliente}}</td>
          <td *ngIf="editarFacturacion">
            <div style="position: relative;">
              <input type="text" [(ngModel)]="NombreCliente">
              <span class="focus-borde">
                <i></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>DNI</td>
          <td *ngIf="!editarFacturacion">{{IdentificadorClienteDNI}}</td>
          <td *ngIf="editarFacturacion">
            <div style="position: relative;">
              <input type="text" [(ngModel)]="IdentificadorClienteDNI">
              <span class="focus-borde">
                <i></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Dirección</td>
          <td *ngIf="!editarFacturacion">{{direccionFacturacion}}</td>
          <td *ngIf="editarFacturacion">
            <div style="position: relative;">
              <input type="text" [(ngModel)]="direccionFacturacion">
              <span class="focus-borde">
                <i></i>
              </span>
            </div>
          </td>
        </tr>
      </table>

      <hr>

      <h2 class="tituloCards">Datos de bancarios</h2>

      <table>
        <tr>
          <td>Titular</td>
          <td *ngIf="!editarFacturacion">{{NombrePagador}}</td>
          <td *ngIf="editarFacturacion">
            <div style="position: relative;">
              <input type="text" [(ngModel)]="NombrePagador">
              <span class="focus-borde">
                <i></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>Número de cuenta</td>
          <td *ngIf="!editarFacturacion">{{IBAN}}</td>
          <td *ngIf="editarFacturacion">
            <div style="position: relative;">
              <input type="text" [(ngModel)]="IBAN">
              <span class="focus-borde">
                <i></i>
              </span>
            </div>
          </td>
        </tr>
      </table>

      <!-- <div>
        <button *ngIf="!editarFacturacion" class="btn btnStyle_2" (click)="editarFacturacion = true; editarDatosPersonales = false; editarPrevision = false; editarSuministro = false;">Modificar <i class="fas fa-pencil"></i></button>
        <button *ngIf="editarFacturacion" class="btn btnStyle_2" style="float: left; margin-left: 20px;" (click)="editarFacturacion = false;">Cancelar <i class="fas fa-pencil"></i></button>
        <button *ngIf="editarFacturacion" class="btn btnStyle_2" style="float: right;" (click)="modificarDatos('facturacion')">Enviar <i class="fas fa-pencil"></i></button>
      </div> -->

    </mat-card>
  </div>

  <!-- <mat-card class="cardsContents cardComun">
    <h2 class="tituloCards">Datos previsión de consumo</h2>

    <h3>Habitantes</h3>

    <table>
      <tr>
        <td>Días más concurridos en el hogar:</td>
        <td>L-V</td>
      </tr>
      <tr>
        <td>Horas más concurridas en el hogar:</td>
        <td class="finalLinea">5pm-12pm</td>
      </tr>
    </table>

    <h3>Hogar</h3>

    <table>
      <tr>
        <td>Vivienda:</td>
        <td>Habitual</td>
      </tr>
      <tr>
        <td>M2 vivienda:</td>
        <td>60m2-120m2</td>
      </tr>
      <tr>
        <td>Tipo de vivienda:</td>
        <td>Unifamiliar</td>
      </tr>
      <tr>
        <td>Jardin: </td>
        <td class="especial" style="display: inline-flex !important;">Sí <input type="radio" name="jardin" value="si"> No<input type="radio" name="jardin" value="no"></td>
      </tr>
      <tr>
        <td>Orientación vivienda:</td>
        <td class="finalLinea">Noreste</td>
      </tr>
    </table>

    <br>

  <div style="display: flex; justify-content: space-between;"> 
      <p *ngIf="!editarPrevision"></p>
      <button *ngIf="!editarPrevision" class="btn btnStyle_2" (click)="editarPrevision = true; editarDatosPersonales = false; editarFacturacion = false; editarSuministro = false;">Modificar <i class="fas fa-pencil"></i></button>
      <button *ngIf="editarPrevision" class="btn btnStyle_2" style="-left: 20px;" (click)="editarPrevision = false;">Cancelar <i class="fas fa-pencil"></i></button>
      <button *ngIf="editarPrevision" class="btn btnStyle_2" (click)="modificarDatos('prevision')">Enviar <i class="fas fa-pencil"></i></button>
    </div> 

  </mat-card> -->

  <mat-card class="cardsContents cardComun">
    <h2 class="tituloCards">Datos puntos de suministro</h2>

    <table>
      <tr>
        <td>CUPS:</td>
        <td *ngIf="!editarSuministro">{{dataSource[0].Cups}}</td>
        <td *ngIf="editarSuministro">
          <div style="position: relative;">
            <input type="text" [(ngModel)]="dataSource[0].Cups">
            <span class="focus-borde">
              <i></i>
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td>Dirección punto de suministro:</td>
        <td *ngIf="!editarSuministro">{{dataSource[0].DireccionSuministro}}</td>
        <td *ngIf="editarSuministro">
          <div style="position: relative;">
            <input type="text" [(ngModel)]="dataSource[0].DireccionSuministro">
            <span class="focus-borde">
              <i></i>
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td>Tarifa de acceso:</td>
        <td *ngIf="!editarSuministro">{{dataSource[0].Tarifa}}</td>
        <td *ngIf="editarSuministro">
          <div style="position: relative;">
            <input type="text" [(ngModel)]="dataSource[0].Tarifa">
            <span class="focus-borde">
              <i></i>
            </span>
          </div>
        </td>
      </tr>
      <tr> <!-- *ngIf="isProductoPrecarga" -->
        <td>Potencia contratada:</td>
        <td>
          <b *ngIf="dataSource[0].P1">P1: {{dataSource[0].P1}}&nbsp;</b>
          <b *ngIf="dataSource[0].P2">P2: {{dataSource[0].P2}}&nbsp;</b>
          <b *ngIf="dataSource[0].P3">P3: {{dataSource[0].P3}}&nbsp;</b>
          <b *ngIf="dataSource[0].P4">P4: {{dataSource[0].P4}}&nbsp;</b>
          <b *ngIf="dataSource[0].P5">P5: {{dataSource[0].P5}}&nbsp;</b>
          <b *ngIf="dataSource[0].P6">P6: {{dataSource[0].P6}}&nbsp;</b>
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Distribuidora:</td>
        <td *ngIf="!editarSuministro">{{dataSource[0].NombreDistribuidora}}</td>
        <td *ngIf="editarSuministro">
          <div style="position: relative;">
            <input type="text" [(ngModel)]="Distribuidora">
            <span class="focus-borde">
              <i></i>
            </span>
          </div>
        </td>
      </tr>
    </table>

    <br>

    <!-- <div style="display: flex; justify-content: space-between;">
      <p *ngIf="!editarSuministro"></p>
      <button *ngIf="!editarSuministro" class="btn btnStyle_2" (click)="editarSuministro = true; editarDatosPersonales = false; editarFacturacion = false; editarPrevision = false;">Modificar <i class="fas fa-pencil"></i></button>
      <button *ngIf="editarSuministro" class="btn btnStyle_2" style="-left: 20px;" (click)="editarSuministro = false;">Cancelar <i class="fas fa-pencil"></i></button>
      <button *ngIf="editarSuministro" class="btn btnStyle_2" (click)="modificarDatos('suministro')">Enviar <i class="fas fa-pencil"></i></button>
    </div> -->

  </mat-card>
</div>
<!-- <app-footer></app-footer> -->