import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../../interfaces/token';
import { GlobalService } from '../../services/config/global/global.service';
import { Observable } from 'rxjs';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class CchService {
  token: any;
  urlApi: string;
  constructor(private http: HttpClient, private _globalService: GlobalService,private almnService:AlmacenamientoService) {
    // Direccion url API
    this.urlApi = this._globalService.getUrlApiCch();
    this.token = this.almnService.getValue('token');
  }

   getFact(cups, fechaInicio, fechaFin): Observable<Token> {
     const body = {
       ListaCUPS: cups,
       FechaInicio: fechaInicio,
       FechaFin: fechaFin
     };
     return this.http.post<Token>(this.urlApi + 'CCH/FACT', body, this._globalService.getHttpHeaders());
   }

  getTCurvas(codigocontrato, fechaInicio, fechaFin): Observable<Token> {
    const body = {
      CodigoContrato: codigocontrato,
      FechaInicio: fechaInicio,
      FechaFin: fechaFin
    };
    return this.http.post<Token>(this.urlApi + 'CCH/TCurvas', body, this._globalService.getHttpHeaders());
  }

  // Nos traemos los datos de curvas enviando de filtro EL/LOS CUPS y fechas
  getTCurvasWithCups(cups, fechaInicio, fechaFin): Observable<Token> {
    const body = {
      CodigoCUPS: cups,
      FechaInicio: fechaInicio,
      FechaFin: fechaFin
    };
    // return this.http.post<Token>(this.urlApi + 'CCH/TCurvasCUPS', body, this._globalService.getHttpHeaders());
    return this.http.post<Token>(this.urlApi + 'CCH/TCurvasCUPS', body, this._globalService.getHttpHeaders());
  }
}
