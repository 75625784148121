<div mat-dialog-content fxLayout="column" class="dialog">
    
    <mat-card class="cardsContents cardDatosPersonales">
        <h2>Datos personales</h2>
       
        <table>
          <tr>
            <td>Nombre</td>
            <td><input type="text" id="nombre"></td>
          </tr>
          <tr>
            <td>Primer apellido</td>
            <td><input type="text" id="apellido1"></td>
          </tr>
          <tr>
            <td>Segundo apellido</td>
            <td><input type="text" id="apellido2"></td>
          </tr>
          <tr>
            <td>Email</td>
            <td><input type="text" id="email"></td>
          </tr>
          <tr>
            <td>Teléfono</td>
            <td><input type="text" id="tlf"></td>
          </tr>
          <tr>
            <td>Fecha de nacimiento</td>
            <td><input type="text" id="fecha"></td>
          </tr>
        </table>
  
        <div>
          <button class="btn btnStyle_2">Enviar cambios <i class="fa-solid fa-paper-plane"></i></button>
        </div>
        
      </mat-card>
</div>