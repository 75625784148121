
import { Component, OnInit, Inject, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail/mail.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cambiosNotificacion-dialog.component',
    templateUrl: './cambiosNotificacion-dialog.component.html',
    styleUrls: ['./cambiosNotificacion-dialog.component.scss']
  })

  export class cambiosNotificacionComponent implements OnInit{

    constructor(
      public dialogRef: MatDialogRef<cambiosNotificacionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private _mailService: MailService,
      ){}

  ngOnInit() {
 
  }

  cerrarDialog(): void {
    this.dialogRef.close();
  }

  aceptarCambios() {
    console.log(this.data);

    this._mailService.sendMail(this.data.destinatario, this.data, false).subscribe(data => {
      //MENSAJE CONFIRMACIÓN CLIENTE 
      console.log(data)
    })
    this._mailService.sendMail(environment.email, this.data, true).subscribe(data => {
      //MENSAJE BACKOFFICE
      console.log(data)
    })
    this.dialogRef.close();
  }
}
