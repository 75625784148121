<div mat-dialog-content fxLayout="column" class="dialog">
    
    <mat-card class="cardsContents cardDatosPersonales">
        <h2>Datos previsión de consumo</h2>
       
        <h2>Habitantes</h2>

        <table>
            <tr>
              <td>Días más concurridos en el hogar:</td>
              <td><input type="text" id="dias"></td>
            </tr>
            <tr>
              <td>Horas más concurridas en el hogar:</td>
              <td><input type="text" id="horas"></td>
            </tr>
          </table>

        <h2>Hogar</h2>

        <table>
          <tr>
            <td>Vivienda:</td>
            <td><input type="text" id="vivienda"></td>
          </tr>
          <tr>
            <td>M2 vivienda:</td>
            <td><input type="text" id="m2"></td>
          </tr>
          <tr>
            <td>Tipo de vivienda:</td>
            <td><input type="text" id="vivienda"></td>
          </tr>
          <tr>
            <td>Jardin:</td>
            <td><input type="text" id="cuenta"></td>
          </tr>
        </table>
  
        <div>
          <button class="btn btnStyle_2">Enviar cambios <i class="fa-solid fa-paper-plane"></i></button>
        </div>
        
      </mat-card>
</div>