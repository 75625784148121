<app-header></app-header>

<div class="pageContent">
  <mat-card class="tablaListadoContratos">
    <!-- <h3>Contratos activos 2</h3> -->

    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{{dataSource2[0].CodigoContrato}}</div>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].ContratoSituacion">
                  {{dataSource2[0].ContratoSituacion}}
                  <i>-</i>
                  <span>{{dataSource2[0].Tarifa}}</span>
                </td>
                <td>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].NombreCliente">{{dataSource2[0].Tarifa}}</td>
                <td title="dataSource2[0].NombreCliente"><span>{{dataSource2[0].NombreCliente}}</span></td>
                <td title="{{dataSource2[0].DireccionSuministro}}"><span>{{dataSource2[0].DireccionSuministro}}</span></td>
                <td>
                  <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                </td>
                <td>
                  <div>
                    <i class="fal fa-angle-down"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel-header>
        <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

          <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
              <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource2[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource2[0].CodigoContrato"> 
                <td>
                  <div >{{contrato.CodigoContrato}}</div>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  {{contrato.ContratoSituacion}}
                  <i>-</i>
                  <span>{{contrato.Tarifa}}</span>
                </td>
                <td>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
              </tr>
            </tbody>
          </table>
        </div>
         
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

  <div class="container1">
    <mat-card class="cardsContents cardRecargaEstimacion" *ngIf="isProductoPrecarga">
      <h2 class="tituloCards">Recarga vs estimación: <span style="font-weight: 400;">{{previsionGasto.mes}}
        {{previsionGasto.anio}}</span>
      </h2>
      
      <article>
        <div>
          <p>Recarga</p>
          <p>Consumo</p>
        </div>

        <section>
          <circle-progress id="Firstgrafico" [percent]="previsionGasto.porcentajeRecarga" [radius]="65" [outerStrokeWidth]="4"
            [innerStrokeWidth]="0" [outerStrokeColor]="'#6f49ce'" [showTitle]="false" [maxPercent]="4000" [showUnits]="false"
            [showSubtitle]="false" [responsive]="true" [space]="0">
          </circle-progress>

          <circle-progress [percent]="previsionGasto.porcentajeConsumo" [radius]="65" [outerStrokeWidth]="4"
            [innerStrokeWidth]="0" [outerStrokeColor]="'#fff523'" [showTitle]="false" [maxPercent]="4000" [showUnits]="false"
            [showSubtitle]="false" [responsive]="true" [space]="0">
          </circle-progress>

          <div id="innerCircle">
            {{previsionGasto.porcentajeDiffConsumo}}%
          </div>
        </section>
      </article>

      <!-- <div class="buttonFooterCards">
        <button class="btn btnStyle_2">Previsión consumo</button>
      </div> -->

      <span style="display: flex;align-items: center;gap: 10px;" *ngIf="previsionGasto.porcentajeRecarga < previsionGasto.porcentajeConsumo">
        <img src="assets/img/iconoAvisosBlanco.png" width="37px"> Según la estimación tu recarga es inferior al consumo
      </span>
    
      <div class="blurProductoPrecarga" *ngIf="!isProductoPrecarga">
        <div id="blurBackGround"></div>

        <div id="textBlur">
          <span style="color: #fff523">I</span> <span style="color: #6f49ce;">N</span> <span style="color: #fff523">F</span> <span style="color: #6f49ce;">Ó</span> <span style="color: #fff523">R</span> <span style="color: #6f49ce;">M</span> <span style="color: #fff523">A</span> <span style="color: #6f49ce;">T</span> <span style="color: #fff523">E</span> <span style="color: #6f49ce;">&nbsp;</span> <span style="color: #fff523;">&nbsp;</span> <span style="color: #6f49ce;">A</span> <span style="color: #fff523">Q</span> <span style="color: #6f49ce;">U</span> <span style="color: #fff523">Í</span>
        </div>
      </div>
    </mat-card>

    <mat-card class="cardsContents cardAvisos" *ngIf="mostrarAvisos">
      <h2>Avisos</h2>

      <ul>
        <li>Según la estimación podrás perder tu descuento "Acierta".</li>
        <li>Cambia tu plan para el mes que viene.
          <br>
          <button class="btn btnStyle_2" [routerLink]="['/transacciones/cambios']">Cambia de plan</button>
        </li>
        <li>Sigue los siguientes consejos para reducir tu consumo.</li>
      </ul>
    </mat-card>

    <mat-card class="cardsContents cardDescuentosMes">
      <h2 fxLayout="row" fxLayoutAlign="space-between center" class="tituloCards">
        <span>Descuentos mes en curso</span>
        <span style="font-weight: 400;font-size: 12px;width: 85px;">Marzo 2022</span>
      </h2>
      <table>
        <tr>
          <td *ngIf="isProductoPrecarga">Descuento Tarifa</td>
          <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[2] ? tusDescuentosTarifaGrupo[2].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Tarifa</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
          <td>
            <span *ngIf="isProductoPrecarga">Activo</span>
            <span *ngIf="!isProductoPrecarga">Desactivado</span>
          </td>
          <td>
            <img *ngIf="isProductoPrecarga" src="assets/img/checkDescuentos.png" width="35px">
            <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
          </td>
        </tr>
        <tr>
          <td>Descuento Online</td>
          <td>{{tusDescuentosTarifaGrupo[0] ? tusDescuentosTarifaGrupo[0].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td><span>{{descuentoOnline}}</span></td>
          <td [ngSwitch]="descuentoOnline">
            <img src="assets/img/checkDescuentos.png" width="35px" *ngSwitchCase="'Activo'">
            <img src="assets/img/crossDescuentos.png" width="35px" *ngSwitchCase="'Perdido'">
            <img src="assets/img/interrogationDescuentos.png" width="35px" *ngSwitchCase="'Peligro'">
          </td>
        </tr>
        <tr>
          <td *ngIf="isProductoPrecarga">Descuento Acierta</td>
          <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[1] ? tusDescuentosTarifaGrupo[1].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Acierta</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
          <td>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90">Activo</span>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110">En peligro</span>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110">Perdido</span>
            <span *ngIf="!isProductoPrecarga">Desactivado</span>
          </td>
          <td>
            <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90" src="assets/img/checkDescuentos.png" width="35px">
            <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110" src="assets/img/crossDescuentos.png" width="35px">
            <img *ngIf="isProductoPrecarga  && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110" src="assets/img/interrogationDescuentos.png" width="35px">
            <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
          </td>
        </tr>
      </table>

      <div class="buttonFooterCards">
        <a href="https://wekiwi.es/te-ayudamos/" target="_blank">
          <button class="btn btnStyle_2">Saber más</button>
        </a>
      </div>
    </mat-card>
  </div>

  <mat-card class="cardsContents cardConsumoMesxDias">
    <div class="tituloConsumoMesxDias">
      <h2 class="tituloCards">Consumo mensual por dias</h2>
      <mat-select placeholder="Mes" (selectionChange)="cargarGraficos()"
        [(ngModel)]="mesFiltradoConsumo">
        <mat-option [value]="mes" *ngFor="let mes of listadoMeses; let i = index;" [disabled]="fechaActual.getMonth() < i">{{ mes }}</mat-option>
      </mat-select>
    </div>

    <highcharts-chart [Highcharts]="Highcharts" class="grafico" [(options)]="chartConsumoMesxDias"
      style="width: 100%; display: block; overflow: hidden;"></highcharts-chart>

      <p><b>*Consumo real recogido en tus últimas lecturas.</b></p>
  </mat-card>

  <mat-card class="cardsContents cardConsejo" *ngIf="mostrarConsejos">
    <h2 class="tituloCards">Consejos</h2>

    <div>
      <table>
        <!--Esta controlado solo para que vengan 3 datos-->
        <tr>
          <td><i class="fal fa-lightbulb"></i></td>
          <td><span>Los meses de verano consumes de media X kwh menos que los de invierno. Con Wekiwi puedes elegir tu
              recarga verano e invierno de manera independiente. Te enseñamos como hacerlo.</span></td>
        </tr>
        <tr>
          <td><i class="fal fa-lightbulb"></i></td>
          <td><span>En el último año has perdido 8 veces el descuento "Acierto". Te recomendamos aumentar tu recarga y
              obtener un X% de descuento en los próximos meses.</span>
          </td>
        </tr>
        <tr>
          <td><i class="fal fa-lightbulb"></i></td>
          <td><span>En el último año has contactado 2 veces de froma telefónica perdiendo el descuento online.
              Te recomendamos acomunicarte a tarvés de nuestra sección "Contacto" y obtener en tu próxima factura x% de
              descuento</span></td>
        </tr>
        <tr>
          <td><i class="fal fa-lightbulb"></i></td>
          <td><span>En el último año has contactado 2 veces de froma telefónica perdiendo el descuento online.
              Te recomendamos acomunicarte a tarvés de nuestra sección "Contacto" y obtener en tu próxima factura x% de
              descuento</span></td>
        </tr>
      </table>
    </div>

    <div class="buttonFooterCards">
      <a href="https://wekiwi.es/blog/" target="_blank">
        <button class="btn btnStyle_2">Más consejos</button>
      </a>
    </div>
  </mat-card>

  <mat-card class="cardsContents cardEstiloVida" *ngIf="false">
    <article>
      <h2 class="tituloCards">Estilo de vida</h2>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
      </p>

      <mat-select id="selectorEV" placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
        [(ngModel)]="estiloVidaFiltro">
        <mat-option [value]="0"></mat-option>
        <mat-option [value]="1">Datos</mat-option>
      </mat-select>
    </article>

    <div *ngIf="estiloVidaFiltro == 1">
      <article>
        <section class="tablasEstiloVida">
          <h3>Número de personas en la vivienda: </h3>

          <table *ngFor="let number of [1,2]" id="tablaPersonas">
            <tr>
              <td id="personaEV">Persona {{number}}:</td>
              <td id="datosPersonaEV">
            <tr>
              <td class="tituloRow"><b>Edad: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="edadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">De 18-30</mat-option>
                  <mat-option [value]="1">De 30-50</mat-option>
                  <mat-option [value]="2">De más de 50</mat-option>
                </mat-select>
              </td>
              <td class="tituloRow"><b>Actividad: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">Mayormente en el hogar</mat-option>
                  <mat-option [value]="1">Mayormente fuera del hogar</mat-option>
                  <mat-option [value]="2">Mitad y mitad</mat-option>
                </mat-select>
              </td>
            </tr>
            </td>
            </tr>
          </table>

          <div class="buttonFooterCards">
            <button class="btn">Añadir persona <i class="fal fa-user-plus"></i></button>
          </div>

          <br>

        </section>
      </article>

      <hr>

      <article>
        <section class="tablasEstiloVida">
          <h3>Concurrecia en el hogar: </h3>

          <table>
            <tr id="concurrenciaEstiloVida">
              <td class="tituloRow"><b>Días más concurridos: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">De L a V</mat-option>
                  <mat-option [value]="1">Fin de semanas</mat-option>
                  <mat-option [value]="2">Toda la semana</mat-option>
                </mat-select>
              </td>
              <td class="tituloRow"><b>Horas más concurridas: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">Mayormente en el hogar</mat-option>
                  <mat-option [value]="1">Mayormente fuera del hogar</mat-option>
                  <mat-option [value]="2">Mitad y mitad</mat-option>
                </mat-select>
              </td>
            </tr>
          </table>

          <div class="buttonFooterCards">
            <button class="btn">Añadir persona <i class="fal fa-user-plus"></i></button>
          </div>

          <br>

        </section>
      </article>

      <hr>

      <article>
        <section class="tablasEstiloVida">
          <h3>Datos del hogar: </h3>

          <table>
            <tr id="datosHogasEstiloVida">
              <td class="tituloRow"><b>Orientación vivienda: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">Norte</mat-option>
                  <mat-option [value]="1">Sur</mat-option>
                  <mat-option [value]="2">Este</mat-option>
                  <mat-option [value]="3">Oeste</mat-option>
                </mat-select>
              </td>
              <td class="tituloRow" class="tituloRow"><b>M2 Vivienda: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">De 30m2 a 60m2</mat-option>
                  <mat-option [value]="1">De 60m2 a 120m2</mat-option>
                  <mat-option [value]="2">Más de 120m2</mat-option>
                </mat-select>
              </td>
            </tr>
            <tr id="datosHogasEstiloVida">
              <td class="tituloRow"><b>Tipo de vivienda: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">Familiar</mat-option>
                  <mat-option [value]="1">Unifamiliar</mat-option>
                  <mat-option [value]="2">Pareja</mat-option>
                </mat-select>
              </td>
              <td class="tituloRow"><b>Calefacción: </b></td>
              <td>
                <mat-select placeholder="Estilo de vida" (selectionChange)="filtarDatosGrafico($event.value)"
                  [(ngModel)]="actividadEstiloVida" class="selectorDatosPersona">
                  <mat-option [value]="0">Centralizada</mat-option>
                </mat-select>
              </td>
            </tr>
            <tr id="datosHogasEstiloVida">
              <td class="tituloRow"><b>Jardín particular: </b></td>
              <td>
                <section>
                  Sí
                  <mat-slide-toggle color="primary" [checked]="checkJardin">
                    No
                  </mat-slide-toggle>
                </section>
              </td>
            </tr>
          </table>
        </section>
      </article>
    </div>
    
  </mat-card>
</div>