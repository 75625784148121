import { Component, HostListener } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { environment } from '../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  DesarrolloBonarea: boolean;
  idCliente: any;
  tipoCliente: string;
  margenizquierda: any;
  margenderecha: any;
  MostrarConsumosPorHora: any;
  MostrarConsumosPorHoraClientes: any;
  idiomas: boolean;
  MostrarCierreSesion: boolean;
  MostrarCambioPass: boolean;
  urlPagininaInicial: string;
  nombreCliente: any = this.almnService.getValue('nombreCliente');

  constructor(
    private _loginService: LoginService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService
  ) {
    this._loginService.token = this.almnService.getValue('token');
    this.tipoCliente = this.almnService.getValue('TipoCliente');

    switch (environment.alinearmenusuperior) {
      case 'derecha': {
        this.margenizquierda = 'fill-space';
        this.margenderecha = '';
        break;
      }
      case 'izquierda': {
        this.margenderecha = 'fill-space';
        this.margenizquierda = '';
        break;
      }
      case 'centro': {
        this.margenderecha = 'fill-space';
        this.margenizquierda = 'fill-space';
        break;
      }
    }
  }

  ngOnInit() {
    this.MostrarConsumosPorHora = environment.MostrarConsumosPorHora;
    this.MostrarConsumosPorHoraClientes = environment.MostrarConsumosPorHoraClientes;
    this.MostrarCierreSesion = environment.MostrarCierreSesion;
    this.MostrarCambioPass = environment.MostrarCambioPass;
    this.idiomas = environment.idiomas;
    this.DesarrolloBonarea = environment.DesarrolloBonarea;
    this.urlPagininaInicial = environment.urlPagininaInicial;
  }

  goToTop() {
    document.body.scrollIntoView({
      behavior: "smooth"
    });
  };

  scrollContainer = () => {
    return document.documentElement || document.body;
  };

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {

    const pageProgressBar = document.querySelector(".progress-bar");
    const backToTopButton = document.querySelector(".back-to-top");
    const showOnPx = 100;

    const scrolledPercentage =
      (this.scrollContainer().scrollTop /
        (this.scrollContainer().scrollHeight - this.scrollContainer().clientHeight)) *
      100;

    pageProgressBar['style'].width = `${scrolledPercentage}%`;

    if (this.scrollContainer().scrollTop > showOnPx) {
      backToTopButton.classList.remove("hidden");
    } else {
      backToTopButton.classList.add("hidden");
    }
  }
}
