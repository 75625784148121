import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-formulario-pago-redsys-dialog',
  templateUrl: './formulario-pago-redsys.component.html',
  styleUrls: ['./formulario-pago-redsys.component.scss']
})
export class FormularioPagoRedsysComponent implements OnInit {

  factura: any;
  companyName: any;
  library: any;
  params: any;
  signature: any;
  parametrosredsys: any;
  version: any;
  urltpv: any;
  amount: any;
  idFactura: any;
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FormularioPagoRedsysComponent>){
    this.dialogRef.updateSize('100%','100%');
    this.factura = this.data.factura;
    this.factura['companyName']= this.data.companyName;
    this.factura['token']= this.data.token;
    this.factura['internalApiUrl']= this.data.internalApiUrl;
    this.factura.NumeroFactura = [this.data.factura.NumeroFactura];
    this.factura.CodigoContrato = [this.data.factura.CodigoContrato];
    this.factura.Serie = [this.data.factura.Serie];
    //añadir api a factura
    // console.log(this.factura);
  }
  ngOnInit() {
    this.form = this.fb.group({});
    const options = {headers: new HttpHeaders({'Content-Type': 'html/text'})};
    // return this.http.post<any>('http://80.35.34.4:8383/ov/eleia/libs/php/ctrl_aceptacion_condiciones.php', body, options);
    this.library = this.http.post<any>('./libs/conexion_sisPHP7.php', this.factura, options).subscribe(async data => {
      this.library = data;
      // console.log(this.library);
        this.params = this.library[0];
        this.signature = this.library[1];
        this.version = this.library[2];
        this.urltpv = this.library[3];
        this.amount = this.library[4];
        this.idFactura = this.library[5];
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clickEleccion(){
    this.dialogRef.close();
  }
  submitToRedsys(){
    // console.log("entro");
  }
}
