import {Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AlmacenamientoService {
  
constructor(public router : Router) {}

excluidos = ['cryptoKey','cryptoIV'];
softVariables = ['refrescoContratos','ContratoVisible','recargaEnHistorico','listadoContratos','historico','mostrarContrato','tusDescuentosHistorico','tusDescuentosTarifaGrupo'];

 public Variables = {
  "cryptoKey": "",
  "cryptoIV": "",
  "urlApi":"",
  "idioma":"",
  "TipoCliente":"",
  "urlCch":"",
  "companyName":"",
  "usuarioCambioPass":"",
  "passCambioPass":"",
  "u":"",
  "p":"",
  "token":"",
  "isAdminFincas":"",
  "idCliente":"",
  "CambioPassword":"",
  "IsAdministrador":"",
  "actualCupsFilter":"",
  "actualCupsFilterLanguage":"",
  "cupsFiltered":"",
  "IsBateriaVirtual":"",
  "mailCliente":"",
  "refrescoContratos":"",
  "ContratoVisible":"",
  "recargaEnHistorico":"",
  "listadoContratos":"",
  "historico":"",
  "mostrarContrato":"",
  "tusDescuentosHistorico":"",
  "tusDescuentosTarifaGrupo":"",
  "nombreCliente":"",
  "importeCliente":"",
}

objectKeys = Object.keys(this.Variables);

setValue(nombre:string,valor:any){
this.Variables[nombre] = String(valor);
}
getValue(nombre:string){
return this.Variables[nombre] != "" ? this.Variables[nombre] : null;
}
clear(param1?:string){
  if(this.objectKeys.includes(param1)){
      this.Variables[param1] = "";
  }else{
    for (var key of this.objectKeys){
       if(param1 == 'soft' && this.softVariables.includes(key)){
         this.Variables[key] = "";
       }else if(param1 == 'hard' && !this.softVariables.includes(key)){
         this.Variables[key] = "";
      }else if(param1 == undefined){
        this.Variables[key] = "";
      }
    }
  }
}

saveSesion(){
this.objectKeys.forEach(function(key){
sessionStorage.setItem(key,this.Variables[key])
},this)
}

loadSesion(){
if(sessionStorage.getItem('token') != "" && sessionStorage.getItem('token') != null){
this.objectKeys.forEach(function(key){
this.Variables[key] = sessionStorage.getItem(key)
},this)
sessionStorage.clear();
}else{
this.router.navigate(['/login'])
}
}

showValues(){
 for (var variable of this.objectKeys){
  if(this.Variables[variable].startsWith('[{') && !this.excluidos.includes(variable)){
    var parsed = JSON.parse(this.Variables[variable])
    console.log("%c"+variable+"%c :","font-weight: bold;color:red;","font-weight: bold;color:orange;");
    console.log(parsed);
  }else if(!this.excluidos.includes(variable)){
    console.log("%c"+variable+"%c --> %c"+this.Variables[variable],"font-weight: bold;color:red;","font-weight: bold;color:orange;","font-weight: normal;");
  }
 }
}
}



