import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResumenComponent } from '../../usuario/resumen/resumen.component';
@Component({
    selector: 'app-detalles-dialog',
    templateUrl: './detalles-dialog.html',
    styleUrls: ['./detalles-dialog.scss']
  })
  export class DetallesDialogComponent implements OnInit{
    mensajeModal: string;
    tituloModal: string;
    isPrecarga: boolean;
    estado: boolean;

    constructor(
      public dialogRef: MatDialogRef<ResumenComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.mensajeModal = data.texto;
      this.tituloModal = data.titulo;
      this.isPrecarga = data.isPrecarga;
      this.estado = data.estado;
    }

    ngOnInit() {
    }


    onNoClick(): void {
      this.dialogRef.close();
    }
  }
