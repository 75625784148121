<app-header></app-header>

<div class="pageContent">
  <mat-card class="cardsContents">
    <h2 fxLayout="row" fxLayoutAlign="space-between center" class="tituloCards">
      <span>Tu saldo</span>
    </h2>
    <br>
    <div id="headerPrecio">
      <span></span>
      <h1>{{importeCliente}} <span *ngIf="importeCliente >= 0">€</span></h1>
      <input type="button" class="btn btnStyle_2" value="Reembolsar" (click)="reembolsarImporte()" [disabled]="importeCliente <= 0 || importeCliente == 'En trámite'">
    </div>
    <section>
      <div class="infoBody">
        <img src="assets/img/wallet.png" width="130px" alt="Wallet">
        <h1>¡Bienvenido a tu Wallet Wekiwi!</h1>
        <span>
          Te explicamos de que va En esta sección se irán acumulando los euros que no consumas de tu recarga. Es decir, si un mes tu recarga ha sido, por ejemplo, de 30€, y el consumo real de ese mismo mes fue de 20€, en tu Wallet aparecerán 10€ (30€ de la recarga - 20€ del consumo final)
        </span>
      </div>
      <div class="infoBody">
        <img src="assets/img/give.png" width="130px" alt="Give">
        <h1>¿Qué puedes hacer con tu saldo acumulado?</h1>
        <span>
          Pues bien, puedes dejarlos en la misma Wallet para que se apliquen automáticamente a tu próxima recarga o bien, puedes reclamarlos y te los ingresaremos directamente en tu cuenta bancaria, tu eliges.
        </span>
      </div>
    </section>
  </mat-card>
</div>