<app-header></app-header>

<div class="pageContent">

  <mat-card class="tablaListadoContratos">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{{dataSource2[0].CodigoContrato}}</div>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].NombreCliente">{{dataSource2[0].Tarifa}}</td>
                <td title="dataSource2[0].NombreCliente">{{dataSource2[0].NombreCliente}}</td>
                <td title="{{dataSource2[0].DireccionSuministro}}">{{dataSource2[0].DireccionSuministro}}</td>
                <td>
                  <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                </td>
                <td>
                  <div>
                    <i class="fal fa-angle-down"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel-header>
        <div id="tablaAcordeon">

          <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato)">
              <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource2[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource2[0].CodigoContrato"> 
                <td>
                  <div >{{contrato.CodigoContrato}}</div>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
              </tr>
            </tbody>
          </table>
        </div>
         
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

  <h3>Así es como has participado en crear un planeta mejor</h3>
  <mat-card class="cardsContents cardEnergiaVerde">
    <h2 class="tituloCards">Energía Verde</h2>

    <section>
      <div>
        <article>
          <i class="fas fa-leaf"></i>
          <h4>70kWh</h4>
          <p>
            Tu consumo medio diario en el último mes
          </p>
        </article>
        <article>
          <i class="fas fa-leaf"></i>
          <h4>65kWh</h4>
          <p>
            Tu consumo medio diario en lo que va de año
          </p>
        </article>
      </div>
      <article id="energiaVerdeTexto">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.</p>
      </article>
    </section>
  </mat-card>

  <h3>Adopta un árbol</h3>
  <div id="container1">

    <mat-card class="cardsContents cardTuCodigoRecolecta">
      <h2 class="tituloCards">Tu código</h2>

      <section>
        <article>
          Tu código: MARÍAFREE2
        </article>
        <span>Desactivado</span>

        <p><b>*Debes activar tu código en la página de Treedom</b></p>
        <button class="btn btnStyle_2">Ver tu árbol &nbsp;<i class="fas fa-tree"></i></button>
      </section>
    </mat-card>

    <mat-card class="cardsContents cardSaberMasPlastic">
      <section>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.</p>

        <a href="http://wekiwi.es/" target="_blank"><button class="btn btnStyle_2">Saber más &nbsp;<i class="fas fa-tree"></i></button></a>
      </section>
    </mat-card>
  </div>

  <mat-card class="cardsContents cardBosque">
    <h2 class="tituloCards">Bosque Wekiwi</h2>

    <ul>
      <li>
        <p><span></span> Todo árbol, alo largo de su vida, absorbe CO2 (dióxino de carbono) de la átmosfera y lo fija en
          sus partes leñosas. Resultarán aún mayores cuantos más árboles crezcan y vivan durante mucho tiempo</p>
        <img src="assets/img/iconoArbolArbol.png" width="45px" style="margin-right: 9px">
      </li>

      <li>
        <p><span></span> actividad permite extrar el C02 de la átmosfera y sus efectos</p>
        <img src="assets/img/c02.png" width="60px">
      </li>

      <li>
        <p><span></span> Los árboles de este bosque absorberán 192,91 t* de C02</p>
        <img src="assets/img/arboles.png" width="60px">
      </li>
    </ul>
  </mat-card>

  <mat-card class="cardsContents cardVerBosque" style="background-image: url('assets/img/mapaMundo.png')">
    <!-- <iframe
      src='https://www.treedom.net/es/map/e/52045?title=true&user=true&tree=true&emission=true&project=true&generallink=true&event=true&code=false&href=true'
      frameborder='0' width='100%' height='600px'></iframe> -->
    <article>

      <div id="segundoFlex">
        <img src="assets/img/logoNegro.png" width="200px">

        <section>
          <div>
            <p>2953</p>
            <p>Árboles</p>
          </div>

          <div>
            <p class="dotP">-192,96 t*</p>
            <p>CO2</p>
          </div>

          <div>
            <p class="dotP">7</p>
            <p>Paises</p>
          </div>
        </section>
      </div>

      <a href="https://www.treedom.net/es/organization/wekiwi/map/event/wekiwi-forest" target="_blank"><button class="btn btnStyle_2">Ver bosque &nbsp;<i class="fas fa-trees"></i></button></a>
    </article>
  </mat-card>

  <h3>¡Gracias por formar parte del cambio, sin ti no sería posible!</h3>

  <div id="container1">
    <mat-card class="cardsContents cardTuCodigoRecolecta">
      <h2 class="tituloCards">Recolecta de plásticos</h2>

      <section>
        <img src="assets/img/bottles.png" alt="Bottles">

        <h1>Llevas 6kg recolectados</h1>

        <p>Equivale a 3000 botellas recicladas</p>
      </section>

    </mat-card>

    <mat-card class="cardsContents cardSaberMasPlastic">
      <h2 class="tituloCards">Plasticbank</h2>

      <section>
        <h3><b>Ayuda a la recolecta de plástico de los mares.</b></h3>
        <img src="assets/img/plasticbank.PNG" alt="Plasticbank">
        <h4><b>¿Quieres conocer más este movimiento?</b></h4>

        <a href="http://wekiwi.es/" target="_blank"><button class="btn btnStyle_2">Saber más</button></a>
      </section>
    </mat-card>
  </div>

  <mat-card class="cardsContents cardPlasticBank">
    <h4 style="font-weight: bold;">En wekiwi tenemos el compromiso de crear un mundo mejor. Por ello colaboramos con
      Treedom cada año en la recolecta de plásticos</h4>
    <h4> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris porta commodo eros, non congue lacus porttitor
      sed. Mauris non mauris convallis,
      eleifend tortor ac, tincidunt ligula. In facilisis mollis ex, eu consequat dolor.</h4>
    <img src="assets/img/Certificate.PNG" alt="Certificate">
    <br>
  </mat-card>

  <mat-card class="cardsContents cardGracias">
    <section>
      <article>
        <h2 style="display: flex; gap: 20px; align-items: center;">Gracias María, el mundo necesita más gente como tú <img
            src="assets/img/stars.png" width="30px"></h2>
        <p>
          Wekiwi es una empresa Bcorp. Utilizamos la fuerza de nuestra marca para generar un impacto positivo en las
          personas y el planeta
        </p>
      </article>
      <img src="assets/img/b.PNG" alt="Certificate" height="120px">
    </section>

    <br>
    <div style="display: flex; justify-content: end; align-items: center;">
      <a href="http://wekiwi.es/" target="_blank"><button class="btn btnStyle_2">Saber más</button></a>
    </div>
  </mat-card>
</div>