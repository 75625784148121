import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { LoginService } from '../../services/login/login.service';
import { environment } from '../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {
  // idFacturaVentaCabecera: any;
  token: string;
  urlApi: string;
  bodyApiToken: any;
  listadoFacturas: any; // TODO: Pasar a Factura[]
  ultimasFacturas: any; // TODO
  agrupacontratoscups = environment.agrupacontratoscups;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService) {
    // Direccion url API
    this.urlApi = this._globalService.getUrlApi();
    this.token = this.almnService.getValue('token');
    
  }
  refreshtoken(){
    if(this.urlApi != this._globalService.getUrlApi()){ //refresh api(multilogin)
      this.urlApi= this._globalService.getUrlApi();
    }
    this.token = this.almnService.getValue('token');
  }
  getListadoFacturas(codigo, Entorno): Observable<any> {
    this.refreshtoken();
    

    return this.http.post<any>(this.urlApi + 'Contrato/GetInfoRecargasReport', {'CodigoContrato':codigo,'Entorno':Entorno.replace('E', 'G')}, this._globalService.getHttpHeaders());
  }

  getListadoFacturasOld(codigo): Observable<any> {
    this.refreshtoken();
    let body = {};
    body = { Codigo: codigo, IsCliente: false};
  
    return this.http.post(this.urlApi + 'Facturas/GetFacturasListaComprobarClienteWeKiwi', body, this._globalService.getHttpHeaders());
  }

  getDescargaFactura(idContratoDocumento): Observable<Blob> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdContratoDocumento: idContratoDocumento };
    return this.http.post<any>(
      this.urlApi + 'Facturas/GetFacturaPDFCompruebaCliente',
      body,
      options
    );
  }

  getDescargaPrecios(idContratoDocumento): Observable<Blob> {
    this.refreshtoken()
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdContratoDocumento: idContratoDocumento };
    return this.http.post<any>(
      this.urlApi + 'Facturas/GetFacturaPreciosXLSX',
      body,
      options
    );
  }

  getDescargaFacturasZip(array): Observable<Blob> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { 'Ids': array };

    return this.http.post<any>(
      this.urlApi + 'Facturas/FacturasDownloadComprobarCliente',
      body,
      options
    );
  }

  getDesgloseFacturasExcel(idfacturas, isatr): Observable<any> {
    this.refreshtoken();
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    const body = { IdsFacturas: idfacturas, IsATR: isatr };
    if(environment.companyName=='Conecta2 Energía'){
      // console.log('Entra');
      return this.http.post<any>(this.urlApi + 'Facturas/DescargaExcelFacturasDesgloseComprobarClienteConecta2', body, options);
    }else{
      // console.log('No entra' );
      return this.http.post<any>(this.urlApi + 'Facturas/DescargaExcelFacturasDesgloseComprobarClienteV2', body, options); 
    }
  }
  

  getUltimasFacturas(idAdministrador, fechaInicio, fechaFin): Observable<any> {
    const body = { IdAdministrador: idAdministrador, FechaInicio: fechaInicio, FechaFin: fechaFin };
    return this.http.post<any>(this.urlApi + 'Resumen/UltimasFacturas', body, this._globalService.getHttpHeaders());
  }

  getImportesBianual(idCups, fechaInicio, fechaFinal): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal };
    return this.http.post<any>(this.urlApi + 'Resumen/GraficoBianualImportes', body, this._globalService.getHttpHeaders());
  }

  getImportes(idCups, fechaInicio, fechaFinal, idCon, idCli): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal, IdContrato: idCon, IdCliente: idCli };
    return this.http.post<any>(this.urlApi + 'Resumen/Importes', body, this._globalService.getHttpHeaders());
    //  const body = {"IdCups":18,"FechaInicio":"2020-01-01 00:00:00","FechaFin":"2021-01-01 00:00:00","IdContrato":"17","IdCliente":"17"};
    // return this.http.post<any>(/*this.urlApi + */'https://apiovdemo.portalswitching.com/APIOV/Resumen/Importes', body, this._globalService.pruebaasdasdas());
  }

  getConsumosBianual(idCups, fechaInicio, fechaFinal): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal };
    return this.http.post<any>(this.urlApi + 'Resumen/GraficoBianualConsumos', body, this._globalService.getHttpHeaders());
  }

  getConsumos(idCups, fechaInicio, fechaFinal, idCon, idCli): Observable<any> {
    const body = { IdCups: idCups, FechaInicio: fechaInicio, FechaFin: fechaFinal, IdContrato: idCon, IdCliente: idCli };
    return this.http.post<any>(this.urlApi + 'Resumen/Consumos', body, this._globalService.getHttpHeaders());
    // const body = {"IdCups":18,"FechaInicio":"2020-01-01 00:00:00","FechaFin":"2021-01-01 00:00:00","IdContrato":"17","IdCliente":"17"};
    // return this.http.post<any>(/*this.urlApi +*/ 'https://apiovdemo.portalswitching.com/APIOV/Resumen/Consumos', body, this._globalService.pruebaasdasdas());
  }

  getRecarga(serv, anio, idCon, idCli): Observable<any> {
    const body = { Entorno: serv, Fecha: anio, IdContrato: idCon, IdCliente: idCli };
    return this.http.post<any>(this.urlApi + 'Resumen/Recargas', body, this._globalService.getHttpHeaders());
  }

  getIdFacturasByCups(id, isAdministrador, fechaInicio, fechaFin, listadoCups,
    cif,importe,selectedtarifa): Observable<any> {
    const body = { 
      IdCliente: id, 
      IsAdministrador: isAdministrador, 
      FechaInicio: fechaInicio, 
      FechaFin: fechaFin, 
      CUPS: listadoCups,
      Cif: cif,
      Importe: importe,
      Tarifa: selectedtarifa

    };
    if(environment.companyName=='Conecta2 Energía'){
      // console.log('Entra');
      return this.http.post<any>(this.urlApi + 'Facturas/GetIdFacturasListaConecta2', body, this._globalService.getHttpHeaders());
    }else{
      // console.log('No entra' );
      return this.http.post<any>(this.urlApi + 'Facturas/GetIdFacturasLista', body, this._globalService.getHttpHeaders());
    }
   
  }


  postPagoTpvRedsys(tarjetaNumero, tarjetaNombre, tarjetaMes, tarjetaAno, tarjetaCcv, importeTotal, numeroFactura): Observable<any> {


    const timeStamp = Math.floor(Date.now());
    const body = {
      "DS_MERCHANT_AMOUNT": importeTotal,
      "DS_MERCHANT_ORDER": numeroFactura,
      "DS_MERCHANT_MERCHANTCODE": `${numeroFactura} - ${timeStamp}`,
      "DS_MERCHANT_CURRENCY":"978",
      "DS_MERCHANT_TRANSACTIONTYPE":"0",
      "DS_MERCHANT_TERMINAL":"1",
      "DS_MERCHANT_MERCHANTURL":"http:\/\/www.prueba.com\/urlNotificacion.php",
      "DS_MERCHANT_URLOK":"http:\/\/www.prueba.com\/urlOK.php",
      "DS_MERCHANT_URLKO":"http:\/\/www.prueba.com\/urlKO.php",
      "DS_MERCHANT_PAN": tarjetaNumero,
      "DS_MERCHANT_EXPIRYDATE":`${tarjetaAno}${tarjetaMes}`,
      "DS_MERCHANT_CVV2":`${tarjetaCcv}`
    };
    return this.http.post<any>(this.urlApi + 'TpvRedsys/Pago', body, this._globalService.getHttpHeaders());
  }
  
}
