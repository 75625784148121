<div mat-dialog-content fxLayout="column" class="dialog">
    
    <mat-card class="cardsContents cardDatosPersonales">
        <h2>Datos personales</h2>
       
        <table>
          <tr>
            <td>Nombre</td>
            <td><input type="text" id="nombre"></td>
          </tr>
          <tr>
            <td>DNI</td>
            <td><input type="text" id="dni"></td>
          </tr>
          <tr>
            <td>Titular</td>
            <td><input type="text" id="titular"></td>
          </tr>
          <tr>
            <td>Número de cuenta</td>
            <td><input type="text" id="cuenta"></td>
          </tr>
        </table>
  
        <div>
          <button class="btn btnStyle_2">Enviar cambios <i class="fa-solid fa-paper-plane"></i></button>
        </div>
        
      </mat-card>
</div>