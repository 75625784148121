<div align="center" mat-dialog-title>
<p *ngIf="!estado"><i class="far fa-exclamation-circle fa-lg"></i></p>
<p *ngIf="estado"><i class="far fa-check-circle fa-lg"></i></p>
<h3>{{ tituloModal }}</h3>
</div>

<mat-dialog-content align="center">
    <p>
        <span class="mat-typography">
            {{ mensajeModal }}
        </span>
    </p>
    
    <div style="display: flex;justify-content: center;">
        <button (click)="onNoClick()" class="btn btnStyle_2">Aceptar</button>
    </div>
</mat-dialog-content>