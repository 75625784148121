<h2 mat-dialog-title>
    {{ tituloModal }}
    <i class="fas fa-times" (click)="onNoClick()"></i>
</h2>

<mat-dialog-content>
    <span class="mat-typography">
        <!-- <span *ngIf="!isPrecarga && !tituloModal.includes('Online')">Con tu tarifa actual no puedes beneficiarte de este descuento ya que solo es aplicable en la Tarifa Wekiwi.</span> -->
        {{ mensajeModal }}
        <!-- <span *ngIf="!isPrecarga && !tituloModal.includes('Online')"><a href="transacciones/cambios">Cambiar a Tarifa Wekiwi</a></span> -->
    </span>

    <mat-divider></mat-divider>

    <!-- <input style="float: right;" type="button" class="btn btnStyle_2" value="Aceptar" (click)="onNoClick()"> -->

</mat-dialog-content>