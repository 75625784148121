import { Injectable } from '@angular/core';
import { GlobalService } from './../config/global/global.service';
import * as CryptoJS from 'crypto-js';
import { ContratosService } from '../contratos/contratos.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from "jwt-decode";
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class FuncionesService {
  iv: any;
  data: string;
  key: string;
  _enid: string;

  constructor(private _globalService: GlobalService,
    private _contratosService: ContratosService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private almnService:AlmacenamientoService) { }

  decodificarToken(token) {
    try {
      this.key = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoKey());
      this.iv = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoIV());
      const decrypted = CryptoJS.AES.decrypt(token.TextoCifrado, this.key, {
        keySize: 256,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    } catch (error) {
      console.error('Error al decodificar Token: ' + error);
    }
  }
  decodificarTokensss(token) {
    try {
      this.key = CryptoJS.enc.Utf8.parse('TVPNPMN48e6MVeWcvsSmrZJAMbXaaRPz');
        this.iv = CryptoJS.enc.Utf8.parse('W9DurFjh85EDHhLt');
      const decrypted = CryptoJS.AES.decrypt(token.TextoCifrado, this.key, {
        keySize: 256,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    } catch (error) {
      console.error('Error al decodificar Token: ' + error);
    }
  }

  codificarDatos(data: string) {
    try {
      this.key = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoKey());
      this.iv = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoIV());
      const json = JSON.stringify(data);
      const encrypted = CryptoJS.AES.encrypt(json, this.key, {
        keySize: 256,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return JSON.stringify({ 'TextoCifrado': CryptoJS.enc.Base64.stringify(encrypted.ciphertext) });
    } catch (error) {
      console.error('Error al codificar: ' + error);
    }
    // return JSON.parse(this._enid);
  }

  buscarEmailContactos(contactos) {

    let email = "";

    contactos.forEach(contacto => {
      (contacto.TipoContacto == "E") ? email = contacto.ValorContacto : null;
    });

    return email;
  }

  comporbarContratos = function () {
    let promise = new Promise((resolve, reject) => {
      var now = new Date();
      if (now.getTime() - parseInt(this.almnService.getValue('refrescoContratos')) > 600000) {
        this._contratosService.getListadoContratos(this.almnService.getValue('idCliente'))
          .subscribe(async data => {
            this.almnService.setValue("listadoContratos", JSON.stringify(data));
            this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
            resolve(true);

          }, err => {
            resolve(false);
          }
          );
      } else {
        resolve(false);
      }
    });

    return promise;
  }

  
  controlStatusCallBacks(body,texto) {
    if(body.status == 401) { //Unauthorized
      // localStorage.clear();
      // sessionStorage.clear();
      this.almnService.clear();
      this.router.navigate(['/login']);
    }
    
    this._snackBar.open('Error en la carga de ' + texto, 'Cerrar', {
      horizontalPosition: 'end',
      duration: 3000,
      verticalPosition: 'bottom',
    });
  }
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
}
