import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { LoginService } from '../../../services/login/login.service';
import { ResumenService } from '../../../services/resumen/resumen.service';
import { Contrato } from '../../../interfaces/contrato';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DetallesDialogComponent } from '../../dialogs/detalles-dialog/detalles-dialog';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-historico-lectura',
  templateUrl: './historico-lectura.component.html',
  styleUrls: ['./historico-lectura.component.scss']
})
export class HistoricoLecturaComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  datosLectura = new MatTableDataSource;
  
  //Variables Cliente 
  TipoCliente: string;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;

  //Variables Contratos
  dataSource: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];
  
  displayedColumns: string[] = [];
  contratoSeleccionado: any;
  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  Entorno: any;
  isProductoPrecarga: any = true;

  //Variables Gráficos
  Highcharts = Highcharts;
  options: any;
  oneToOneFlag: boolean;
  updateFlag: boolean;

  datosConsumoHistorico: any = [];
  datosImporteHistorico: any = [];
  datosRecargaHistorico: any = [];
  datosConsumoKilovatios: any = [];
  charHistoricoConsumo: any = {};
  fechaGrafico: any = new Date().getFullYear();

  //Variables Generales
  tokenvalidado: any;
  NombreEmpresa = environment.companyName;
  listadoYears: any = [];
  fechaHoy: any = moment().format('YYYY-MM-DD HH:mm:ss').toString(); // Hoy
  fechaUnAño: any = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString(); // Un año
  isLoading = true;
  filtroTabla: any = '';

  constructor(
    private router: Router,
    private _facturasService: FacturasService,
    public dialog: MatDialog,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    private almnService:AlmacenamientoService,
    private _contratosService: ContratosService,
    private _resumenService: ResumenService,
    private _GraficosService: GraficosService
  ) { }

  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;
      console.log("3");
      if (respuesta.__zone_symbol__value) {
        console.log("4");
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);

    this.updateFlag = this.oneToOneFlag = false;
  }

  comprobarDatosContratos() {

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
    } else {
      this.cargarListadoContratos();
    }
  }

  cargarListadoContratos() {
    this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
      async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data))
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    // Listado contratos
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    // console.log(this.ultimosContratos);
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    // if (ajuste) { sessionStorage.clear() }
    if (ajuste) { this.almnService.clear('soft') }
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;
    this.dataSource[0] = this.ultimoContratoActivo;
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));
    this.displayedColumns = [];
    this.displayedColumns.push('FechaLectura');
    
    if('LUZ' == this.dataSource[0].Entorno.toUpperCase()) {
      this.displayedColumns.push('ConsumoActivoP1');
      this.displayedColumns.push('ConsumoActivoP2');
      this.displayedColumns.push('ConsumoActivoP3');
  
      if(this.dataSource[0].IdTarifa != 202020) {
        this.displayedColumns.push('ConsumoActivoP4');
        this.displayedColumns.push('ConsumoActivoP5');
        this.displayedColumns.push('ConsumoActivoP6');
      }
    } else {
      this.displayedColumns.push('Caudal');
    }
   
    this.cargarHistorico();
    // TODO lanzar funcion recuperacián datos toda la página
  }

  cargarHistorico() {
    this.isLoading = true;
    this._contratosService.getHistoricoLecturas(this.dataSource[0].CodigoContrato).subscribe(
      async data => {
        this.isLoading = false;
        var lecturas = this._funcionesService.decodificarToken(data);

        lecturas.forEach(element => {
          var fehcaPartida = element.FechaLectura.split('/');
        element.FechaLectura = new Date(fehcaPartida[2].substr(0,4), parseInt(fehcaPartida[1]) - 1, fehcaPartida[0])
        });
        
        // console.log(lecturas);

        this.datosLectura = new MatTableDataSource<{FechaLectura?: string;ConsumoActivoP1?: number;ConsumoActivoP2?: number;ConsumoActivoP3?: number;ConsumoActivoP4?: number;ConsumoActivoP5?: number;ConsumoActivoP6?: number;}>(lecturas);
        this.datosLectura.sort = this.sort;
        this.datosLectura.paginator = this.paginator;

        // this.almnService.setValue('tusDescuentosTarifaGrupo', btoa(JSON.stringify(descuentos)))
    }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'el historico de lecturas');
    })
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }
}
