<app-header></app-header>

<div class="pageContent">
  <div>
    <!-- <h3 id="contratosActivosResumen">Contratos activos: <b>{{ultimosContratos.length}}</b></h3> -->

    <mat-card class="tablaListadoContratos">
      <h3>Contratos activos: <b>{{ultimosContratos.length}}</b></h3>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>{{dataSource[0].CodigoContrato}}</div>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].ContratoSituacion">
                    {{dataSource[0].ContratoSituacion}} 
                    <i>-</i>
                    <span>{{dataSource[0].Tarifa}}</span>
                  </td>
                  <td>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].NombreCliente">{{dataSource[0].Tarifa}}</td>
                  <td title="dataSource[0].NombreCliente"><span>{{dataSource[0].NombreCliente}}</span></td>
                  <td title="{{dataSource[0].DireccionSuministro}}"><span>{{dataSource[0].DireccionSuministro}}</span></td>
                  <td>
                    <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                  </td>
                  <td>
                    <div>
                      <i class="fal fa-angle-down"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel-header>
          <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

            <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
                <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource[0].CodigoContrato"> 
                  <td>
                    <div >{{contrato.CodigoContrato}}</div>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td>
                    {{contrato.ContratoSituacion}}
                    <i>-</i>
                    <span>{{contrato.Tarifa}}</span>
                  </td>
                  <td>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                  <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                  <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
                </tr>
              </tbody>
            </table>
          </div>
           
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <mat-card class="cardsContents">
    <h3><b>Historico de lecturas</b></h3>
    
    <table style="width: 100%;" mat-table [dataSource]="datosLectura" matSort>

      <ng-container matColumnDef="FechaLectura">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Fecha lectura' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.FechaLectura | date : 'dd-MM-yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Caudal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Caudal' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP1}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P1' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP1}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P2' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP2}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP3">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P3' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP3}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP4">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P4' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP4}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP5">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P5' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP5}} kWh
        </td>
      </ng-container>

      <ng-container matColumnDef="ConsumoActivoP6">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Consumo P6' | translate}} </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ConsumoActivoP6}} kWh
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </mat-card>
</div>

<!-- <app-footer></app-footer> -->