import { Component, OnInit, Input } from '@angular/core';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from 'src/app/services/funciones/funciones.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LoginDialogComponent } from '../../dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionesPerfilService } from '../../../services/config/validaciones/perfil/validaciones-perfil.service';
import { environment } from '../../../../environments/environment';
import {ModificacionesComponent} from '../../dialogs/modificaciones/modificiaciones-dialog.component';

import { CambiosDatosTitularComponent } from '../../dialogs/cambios-datos-titular/cambios-datos-titular.component';
import { CambiosDatosPagoContactoEnvioComponent } from '../../dialogs/cambios-datos-pago-contacto-envio/cambios-datos-pago-contacto-envio.component';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-perfil-identidad',
  templateUrl: './perfil-identidad.component.html',
  styleUrls: ['./perfil-identidad.component.scss']
})
export class PerfilIdentidadComponent implements OnInit {

  // @Input() codigo: string;

  codigo: any;

  ResultadoIdentidad: any;
  IdentidadCliente: string;
  NombreCliente: string;
  CodigoPostalCliente: string;
  ProvinciaCliente: string;
  CiudadCliente: string;
  DireccionCliente: string;
  MetodosPago: any;
  DatosContacto: any;
  DatosEnvio: any;

  textoModal: any;

  PerfilIdentidadIdentidadClienteDisabled: boolean;
  PerfilIdentidadNombreClienteDisabled: boolean;
  PerfilIdentidadCodigoPostalClienteDisabled: boolean;
  PerfilIdentidadProvinciaClienteDisabled: boolean;
  PerfilIdentidadCiudadClienteDisabled: boolean;
  PerfilIdentidadDireccionClienteDisabled: boolean;
  PerfilIdentidadNombrePagador: boolean;
  PerfilIdentidadColectivoPagador: boolean;
  PerfilIdentidadIdentificadorPagador: boolean;
  PerfilIdentidadTipoCobroPagador: boolean;
  PerfilIdentidadIBANPagador: boolean;
  PerfilIdentidadBancoPagador: boolean;
  PerfilIdentidadNombreContacto: boolean;
  PerfilIdentidadTipoContacto: boolean;
  PerfilIdentidadValorContacto: boolean;

  PerfilIdentidadNombreEnvio:boolean;
  PerfilIdentidadPoblacionEnvio:boolean;
  PerfilIdentidadCodigoPostalEnvio:boolean;
  PerfilIdentidadDireccionEnvio:boolean;
  PerfilIdentidadNumeroEnvio:boolean;
  PerfilIdentidadAclaradorEnvio:boolean;

  MostrarPerfilContratoColectivo: any;
  UnirPerfilDatosEnvioYContacto: any;
  MostrarDatosContratoEnPerfil: any;
  BotonElekluz: any;
  MostrarBotonModificiacionDatosPerfil: any;

  DatosContrato: any;

  Cups: any;
  CodigoContrato: any;
  Potencias: any;
  Tarifa: any;

  dialogRef: any;
  OcultarContactosEleia = environment.OcultarContactosEleia;


  constructor(
    private router: Router,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _activatedRoute: ActivatedRoute,
    private almnService:AlmacenamientoService,
    public dialog: MatDialog,
    private _validacionesPerfilService: ValidacionesPerfilService,
  ) { }

  ngOnInit() {

    this.MostrarPerfilContratoColectivo = environment.MostrarPerfilContratoColectivo;
    this.UnirPerfilDatosEnvioYContacto = environment.UnirPerfilDatosEnvioYContacto;
    this.MostrarDatosContratoEnPerfil = environment.MostrarDatosContratoEnPerfil;
    this.BotonElekluz = environment.BotonElekluz;

    this.MostrarBotonModificiacionDatosPerfil = environment.MostrarBotonModificiacionDatosPerfil;

    this._activatedRoute.params.subscribe(
      params => {
        if (this.ResultadoIdentidad === undefined) {
          var split = params.idcliente;
          var splitted = split.split("&", 3);
          console.log(splitted[0]);
          this._contratosService.getDatosCliente(splitted[0]).subscribe(
            data => {
              this.ResultadoIdentidad = this._funcionesService.decodificarToken(data);

              // console.log("this.ResultadoIdentidad")
              // console.log(this.ResultadoIdentidad);

              let resultado = '';
              // console.log(this._contratosService.listadoContratos);

              // this._contratosService.listadoContratos = undefined;

              this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
                datos => {
                  this._contratosService.listadoContratos = this._funcionesService.decodificarToken(datos);
                  resultado = this._contratosService.listadoContratos.find(idcliente => idcliente.IdCliente === params.idcliente);
                }
              );


              // console.log(resultado);

              if (resultado !== undefined) {
                this.IdentidadCliente = this.ResultadoIdentidad.Identidad;
                this.NombreCliente = this.ResultadoIdentidad.Nombre;
                this.DireccionCliente = this.ResultadoIdentidad.Direccion;
                this.CiudadCliente = this.ResultadoIdentidad.Ciudad;
                this.ProvinciaCliente = this.ResultadoIdentidad.Provincia;
                this.CodigoPostalCliente = this.ResultadoIdentidad.CP;
                this.MetodosPago = this.ResultadoIdentidad.MetodosPago;
                this.DatosContacto = this.ResultadoIdentidad.Contactos;
                this.DatosEnvio = this.ResultadoIdentidad.Envios;
              } else {
                this.textoModal = 'El cliente buscado no corresponde con tus datos de administrador';
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    texto: this.textoModal,
                    titulo: 'Cliente Incorrecto'
                  }
                });
              }
            }
          );

        }

      }
    );


    if(this.MostrarDatosContratoEnPerfil){

      this._activatedRoute.params.subscribe(params => {

      var split = params.idcliente;
      var splitted = split.split("&", 3);

      this._contratosService.getDatosContrato(splitted[1])
        .toPromise()
        .then(async data => {
          if(data == "error"){
            this.router.navigate(['/salir']);
          }
          this.DatosContrato = this._funcionesService.decodificarToken(data);

          this.Cups = this.DatosContrato.General.CUPS;
          this.CodigoContrato = this.DatosContrato.General.CodigoContrato;
          this.Potencias = this.DatosContrato.Potencias;
          this.Tarifa = this.DatosContrato.Facturacion.Tarifa;

        });

      });

    }


    this.PerfilIdentidadIdentidadClienteDisabled = this._validacionesPerfilService.PerfilIdentidadIdentidadClienteDisabled;
    this.PerfilIdentidadNombreClienteDisabled = this._validacionesPerfilService.PerfilIdentidadNombreClienteDisabled;
    this.PerfilIdentidadCodigoPostalClienteDisabled = this._validacionesPerfilService.PerfilIdentidadCodigoPostalClienteDisabled;
    this.PerfilIdentidadProvinciaClienteDisabled = this._validacionesPerfilService.PerfilIdentidadProvinciaClienteDisabled;
    this.PerfilIdentidadCiudadClienteDisabled = this._validacionesPerfilService.PerfilIdentidadCiudadClienteDisabled;
    this.PerfilIdentidadDireccionClienteDisabled = this._validacionesPerfilService.PerfilIdentidadDireccionClienteDisabled;


    this.PerfilIdentidadNombrePagador = this._validacionesPerfilService.PerfilIdentidadNombrePagador;
    this.PerfilIdentidadColectivoPagador = this._validacionesPerfilService.PerfilIdentidadColectivoPagador;
    this.PerfilIdentidadIdentificadorPagador = this._validacionesPerfilService.PerfilIdentidadIdentificadorPagador;
    this.PerfilIdentidadTipoCobroPagador = this._validacionesPerfilService.PerfilIdentidadTipoCobroPagador;
    this.PerfilIdentidadIBANPagador = this._validacionesPerfilService.PerfilIdentidadIBANPagador;
    this.PerfilIdentidadBancoPagador = this._validacionesPerfilService.PerfilIdentidadBancoPagador;

    this.PerfilIdentidadNombreContacto = this._validacionesPerfilService.PerfilIdentidadNombreContacto;
    this.PerfilIdentidadTipoContacto = this._validacionesPerfilService.PerfilIdentidadTipoContacto;
    this.PerfilIdentidadValorContacto = this._validacionesPerfilService.PerfilIdentidadValorContacto;

    this.PerfilIdentidadNombreEnvio = this._validacionesPerfilService.PerfilIdentidadNombreEnvio;
    this.PerfilIdentidadPoblacionEnvio = this._validacionesPerfilService.PerfilIdentidadPoblacionEnvio;
    this.PerfilIdentidadCodigoPostalEnvio = this._validacionesPerfilService.PerfilIdentidadCodigoPostalEnvio;
    this.PerfilIdentidadDireccionEnvio = this._validacionesPerfilService.PerfilIdentidadDireccionEnvio;
    this.PerfilIdentidadNumeroEnvio = this._validacionesPerfilService.PerfilIdentidadNumeroEnvio;
    this.PerfilIdentidadAclaradorEnvio = this._validacionesPerfilService.PerfilIdentidadAclaradorEnvio;

  }


  openDialogCambiosDatosTitular() {

    this.dialogRef = this.dialog.open(CambiosDatosTitularComponent, {
      data:{
        'IsAdministrador': this.almnService.getValue('isAdminFincas'),
        'IdCliente': this.almnService.getValue('idCliente'),
        'Entorno': this.almnService.getValue('entorno'),
        'IdentificadorClienteDNI': this.IdentidadCliente,
        'RazonSocialCliente': this.NombreCliente,
        'Apellido1Cliente': this.NombreCliente,
        'Apellido2Cliente': this.NombreCliente,
        'CodigoPostalCliente': this.CodigoPostalCliente,
        'ProvinciaCliente': this.ProvinciaCliente,
        'CiudadCliente': this.CiudadCliente,
        'DireccionCliente': this.DireccionCliente,
        // 'IdentificadorRepresentante': this.IdentificadorRepresentante,
        // 'ColectivoRepresentante': this.ColectivoRepresentante,
        // 'NombreRepresentante': this.NombreRepresentante
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }


  openDialogCambiosDatosPagoContactoEnvio() {

    this.dialogRef = this.dialog.open(CambiosDatosPagoContactoEnvioComponent, {
      data:{
        'IsAdministrador': this.almnService.getValue('isAdminFincas'),
        'IdCliente': this.almnService.getValue('idCliente'),
        'Entorno': this.almnService.getValue('entorno'),
        'IdentificadorClienteDNI': this.IdentidadCliente,

        'MetodosPago': this.MetodosPago,
        'DatosContacto': this.DatosContacto,
        'DatosEnvio': this.DatosEnvio,

      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

  openDialogCambiosDatos() {

    this.dialogRef = this.dialog.open(ModificacionesComponent, {

    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

}
