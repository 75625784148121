import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class GraficosService {
  graficosOption: any;
  idioma: any;
  MensajeCCHConExplicacion = environment.MensajeCCHConExplicacion;
  constructor(private almnService:AlmacenamientoService) {
    // this.idioma = this.almnService.getValue('idioma');
    this.idioma = environment.defaultLang;
    this.graficosOption = JSON.stringify(
      {
        contextButtonTitle: (this.idioma == 'es') ? 'Descargar datos' :
                            (this.idioma == 'en') ? 'Download data':
                            (this.idioma == 'cat') ? 'Descarregar dades':
                            'Descargar datos',
        decimalPoint: ',',
        downloadCSV:  (this.idioma == 'es') ? 'Descargar CSV' :
                      (this.idioma == 'en') ? 'Download CSV':
                      (this.idioma == 'cat') ? 'Descarregar CSV':
                      'Descargar CSV',
        downloadJPEG: (this.idioma == 'es') ? 'Descargar imagen JPEG' :
                      (this.idioma == 'en') ? 'Download JPEG':
                      (this.idioma == 'cat') ? 'Descarregar JPEG':
                      'Descargar imagen JPEG',
        downloadPDF:  (this.idioma == 'es') ? 'Descargar documento PDF' :
                      (this.idioma == 'en') ? 'Download PDF':
                      (this.idioma == 'cat') ? 'Descarregar PDF':
                      'Descargar documento PDF',
        downloadPNG:  (this.idioma == 'es') ? 'Descargar PNG' :
                      (this.idioma == 'en') ? 'Download PNG':
                      (this.idioma == 'cat') ? 'Descarregar PNG':
                      'Descargar PNG',
        downloadSVG:  (this.idioma == 'es') ? 'Descargar SVG':
                      (this.idioma == 'en') ? 'Download SVG':
                      (this.idioma == 'cat') ? 'Descarregar SVG':
                      'Descargar SVG',
        downloadXLS:  (this.idioma == 'es') ? 'Descargar XLS':
                      (this.idioma == 'en') ? 'Download XLS':
                      (this.idioma == 'cat') ? 'Descarregar XLS':
                      'Descargar XLS',
        drillUpText:  (this.idioma == 'es') ? 'Volver a { series.name }':
                      (this.idioma == 'en') ? 'Back to { series.name }':
                      (this.idioma == 'cat') ? 'Tornar a { series.name }':
                      'Volver a { series.name }',
        invalidDate: undefined,
        loading:  (this.idioma == 'es') ? 'Cargando...':
                  (this.idioma == 'en') ? 'Loading...':
                  (this.idioma == 'cat') ? 'Carregant...':
                  'Cargando...',
        months: [
          (this.idioma == 'es') ? 'Enero': (this.idioma == 'en') ? 'January': (this.idioma == 'cat') ? 'Gener': 'Enero',
          (this.idioma == 'es') ? 'Febrero': (this.idioma == 'en') ? 'February': (this.idioma == 'cat') ? 'Febrer': 'Febrero',
          (this.idioma == 'es') ? 'Marzo': (this.idioma == 'en') ? 'March': (this.idioma == 'cat') ? 'Març': 'Marzo',
          (this.idioma == 'es') ? 'Abril': (this.idioma == 'en') ? 'April': (this.idioma == 'cat') ? 'Abril': 'Abril',
          (this.idioma == 'es') ? 'Mayo': (this.idioma == 'en') ? 'May': (this.idioma == 'cat') ? 'Maig': 'Mayo',
          (this.idioma == 'es') ? 'Junio': (this.idioma == 'en') ? 'June': (this.idioma == 'cat') ? 'Juny': 'Junio',
          (this.idioma == 'es') ? 'Julio': (this.idioma == 'en') ? 'July': (this.idioma == 'cat') ? 'Juliol': 'Julio',
          (this.idioma == 'es') ? 'Agosto': (this.idioma == 'en') ? 'August': (this.idioma == 'cat') ? 'Agost': 'Agosto',
          (this.idioma == 'es') ? 'Septiembre': (this.idioma == 'en') ? 'September': (this.idioma == 'cat') ? 'Setembre': 'Septiembre',
          (this.idioma == 'es') ? 'Octubre': (this.idioma == 'en') ? 'October': (this.idioma == 'cat') ? 'Octubre': 'Octubre',
          (this.idioma == 'es') ? 'Noviembre': (this.idioma == 'en') ? 'November': (this.idioma == 'cat') ? 'Novembre': 'Noviembre',
          (this.idioma == 'es') ? 'Diciembre': (this.idioma == 'en') ? 'December': (this.idioma == 'cat') ? 'Desembre': 'Diciembre',
        ],
        noData: this.traduccionDeTextos(this.almnService.getValue('idioma'),'No hay datos disponibles para mostrar'),
        // numericSymbolMagnitude: 1000,
        // numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
        openInCloud:  (this.idioma == 'es') ? 'Abrir en Highcharts Cloud':
                      (this.idioma == 'en') ? 'Open in Highcharts Cloud':
                      (this.idioma == 'cat') ? 'Obrir en Highcharts Cloud':
                      'Abrir en Highcharts Cloud',
        printChart: (this.idioma == 'es') ? 'Imprimir gráfica':
                    (this.idioma == 'en') ? 'Print Chart':
                    (this.idioma == 'cat') ? 'Imprimir gràfica':
                    'Imprimir gráfica',
        resetZoom:  (this.idioma == 'es') ? 'Resetear zoom':
                    (this.idioma == 'en') ? 'Reset zoom':
                    (this.idioma == 'cat') ? 'resetejar zoom':
                    'Resetear zoom',
        resetZoomTitle: (this.idioma == 'es') ? 'Resetear nivel de zoom 1:1':
                        (this.idioma == 'en') ? 'Reset zoom 1:1':
                        (this.idioma == 'cat') ? 'Resetejar nivell de zoom':
                        'Resetear nivel de zoom 1:1',
        shortMonths: [
          (this.idioma == 'es') ? 'Ene': (this.idioma == 'en') ? 'Jan': (this.idioma == 'cat') ? 'Gen': 'En',
          (this.idioma == 'es') ? 'Feb': (this.idioma == 'en') ? 'Feb': (this.idioma == 'cat') ? 'Feb': 'Feb',
          (this.idioma == 'es') ? 'Mar': (this.idioma == 'en') ? 'Mar': (this.idioma == 'cat') ? 'Mar': 'Mar',
          (this.idioma == 'es') ? 'Abr': (this.idioma == 'en') ? 'Apr': (this.idioma == 'cat') ? 'Abr': 'Abr',
          (this.idioma == 'es') ? 'May': (this.idioma == 'en') ? 'May': (this.idioma == 'cat') ? 'Mai': 'May',
          (this.idioma == 'es') ? 'Jun': (this.idioma == 'en') ? 'Jun': (this.idioma == 'cat') ? 'Jun': 'Jun',
          (this.idioma == 'es') ? 'Jul': (this.idioma == 'en') ? 'Jul': (this.idioma == 'cat') ? 'Jul': 'Jul',
          (this.idioma == 'es') ? 'Ago': (this.idioma == 'en') ? 'Aug': (this.idioma == 'cat') ? 'Ago': 'Ago',
          (this.idioma == 'es') ? 'Sep': (this.idioma == 'en') ? 'Sep': (this.idioma == 'cat') ? 'Set': 'Sep',
          (this.idioma == 'es') ? 'Oct': (this.idioma == 'en') ? 'Oct': (this.idioma == 'cat') ? 'Oct': 'Oct',
          (this.idioma == 'es') ? 'Nov': (this.idioma == 'en') ? 'Nov': (this.idioma == 'cat') ? 'Nov': 'Nov',
          (this.idioma == 'es') ? 'Dic': (this.idioma == 'en') ? 'Dec': (this.idioma == 'cat') ? 'Des': 'Dic',
        ],
        shortWeekdays: undefined,
        thousandsSep: '\u0020',
        viewData: (this.idioma == 'es') ? 'Ver datos en formato tabla':
                  (this.idioma == 'en') ? 'View data in table format':
                  (this.idioma == 'cat') ? 'Veure dades en format taula':
                  'Ver datos en formato tabla',
        weekdays: [
          (this.idioma == 'es') ? 'Domingo': (this.idioma == 'en') ? 'Sunday': (this.idioma == 'cat') ? 'Diumenge ': 'Domingo',
          (this.idioma == 'es') ? 'Lunes': (this.idioma == 'en') ? 'Monday': (this.idioma == 'cat') ? 'Dilluns': 'Lunes',
          (this.idioma == 'es') ? 'Martes': (this.idioma == 'en') ? 'Tuesday': (this.idioma == 'cat') ? 'Dimarts': 'Martes',
          (this.idioma == 'es') ? 'Miércoles': (this.idioma == 'en') ? 'Wednesday': (this.idioma == 'cat') ? 'Dimecres': 'Miércoles',
          (this.idioma == 'es') ? 'Jueves': (this.idioma == 'en') ? 'Thursday': (this.idioma == 'cat') ? 'Dijous': 'Jueves',
          (this.idioma == 'es') ? 'Viernes': (this.idioma == 'en') ? 'Friday': (this.idioma == 'cat') ? 'Divendres': 'Viernes',
          (this.idioma == 'es') ? 'Sábado': (this.idioma == 'en') ? 'Saturday': (this.idioma == 'cat') ? 'Dissabte': 'Sábado'
        ]
      }
    );

  }

  traduccionDeTextos(idioma,cadena){

    let valor;

    switch (idioma) {
      case 'es':
          switch (cadena) {
            case 'Detalle Facturas':
              valor = 'Detalle Facturas';
            break;
            case 'Comparativa Importes Facturas':
              valor = 'Comparativa Importes Facturas';
            break;
            case 'Se muestran las facturas detalladas.':
              valor = 'Se muestran las facturas detalladas.';
            break;
            case 'Desglose Conceptos €':
              valor = 'Desglose Conceptos €';
            break;
            case 'Comparativa importes de las facturas año actual y año anterior.':
              valor = 'Comparativa importes de las facturas año actual y año anterior.';
            break;
            case 'Importe en €':
              valor = 'Importe en €';
            break;
            case 'No hay datos disponibles para mostrar':
              if(this.MensajeCCHConExplicacion) {
                valor = 'No hay datos disponibles para mostrar, modifique el filtro de fecha';
              } else {
                valor = 'No hay datos disponibles para mostrar';
              }
            break;
            case 'Año Anterior':
              valor = 'Año Anterior';
            break;
            case 'Año Actual':
              valor = 'Año Actual';
            break;
            case 'Histórico Importes Facturas':
              valor = 'Histórico Importes Facturas';
            break;
            case 'Histórico de los importes de las facturas.':
              valor = 'Histórico de los importes de las facturas.';
            break;
            case 'Importes':
              valor = 'Importes';
            break;
            case 'Activa P':
              valor = 'Activa P';
            break;
            case 'Activa':
              valor = 'Activa';
            break;
            case 'Reactiva P':
              valor = 'Reactiva P';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Consumos Facturas':
              valor = 'Consumos Facturas';
            break;
            case 'Desglose de consumos de facturas por mes':
              valor = 'Desglose de consumos de facturas por mes';
            break;
            case 'Comparativa Consumos Facturas':
              valor = 'Histórico de consumo';
            break;
            case 'Comparativa consumos de las facturas año actual y año anterior.':
              valor = 'Comparativa consumos de las facturas año actual y año anterior.';
            break;
            case 'Consumo en kWh':
              valor = 'Consumo en kWh';
            break;
            case 'Año Actual Activa':
              valor = 'Año Actual Activa';
            break;
            case 'Año Actual Reactiva':
              valor = 'Año Actual Reactiva';
            break;
            case 'Año Anterior Activa':
              valor = 'Año Anterior Activa';
            break;
            case 'Año Anterior Reactiva':
              valor = 'Año Anterior Reactiva';
            break;
            case 'Estados de los contratos':
              valor = 'Estados de los contratos';
            break;
            case 'Se muestran la cantidad de contratos que existen en cada estado.':
              valor = 'Se muestran la cantidad de contratos que existen en cada estado.';
            break;
            case 'Número de contratos.':
              valor = 'Número de contratos.';
            break;
            case 'Desglose Consumos':
              valor = 'Desglose Consumos';
            break;
            case 'Selecciona dentro del gráfico para hacer zoom':
              valor = 'Selecciona dentro del gráfico para hacer zoom';
            break;
            case 'Alquiler':
              valor = 'Alquiler';
            break;
            case 'Derechos':
              valor = 'Derechos';
            break;
            case 'Descuentos Cargos':
              valor = 'Descuentos Cargos';
            break;
            case 'Energia':
              valor = 'Energia';
            break;
            case 'Excesos':
              valor = 'Excesos';
            break;
            case 'Gas':
              valor = 'Gas';
            break;
            case 'Impuesto Electrico':
              valor = 'Impuesto Electrico';
            break;
            case 'Potencia':
              valor = 'Potencia';
            break;
            case 'Productos':
              valor = 'Productos';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Varios':
              valor = 'Varios';
            break;
            case 'Impuesto':
              valor = 'Impuesto';
            break;
            case 'Importe Consumo':
              valor = 'Importe Consumo';
            break;
            case 'Importe Peaje':
              valor = 'Importe Peaje';
            break;
            case 'Término Fijo':
              valor = 'Término Fijo';
            break;
            case 'Impuesto Hidrocarburo':
              valor = 'Impuesto Hidrocarburo';
            break;
          }
        break;
      case 'en':

        switch (cadena) {
          case "Detalle Facturas":
              valor = 'Billing Details';
            break;
            case "Comparativa Importes Facturas":
              valor = 'Comparison Amounts Invoices';
            break;
            case "Se muestran las facturas detalladas.":
              valor = 'Detailed bills are shown.';
            break;
            case "Desglose Conceptos €":
              valor = 'Breakdown Concepts €';
            break;
            case "Comparativa importes de las facturas año actual y año anterior.":
              valor = 'Comparative amounts of current and previous year invoices.';
            break;
            case "Importe en €":
              valor = 'Amount in €';
            break;
            case "No hay datos disponibles para mostrar":
              if(this.MensajeCCHConExplicacion) {
                valor = 'No data available to display, modify the date filter';
              } else {
                valor = 'No data available to display';
              }
            break;
            case "Año Anterior":
              valor = 'Last year';
            break;
            case "Año Actual":
              valor = 'Current year';
            break;
            case "Histórico Importes Facturas":
              valor = 'Invoice Amount History';
            break;
            case "Histórico de los importes de las facturas.":
              valor = 'Invoice Amount History';
            break;
            case "Importes":
              valor = 'Amounts';
            break;
            case 'Activa P':
              valor = 'Active P';
            break;
            case 'Activa':
              valor = 'Active';
            break;
            case 'Reactiva P':
              valor = 'Reactive P';
            break;
            case 'Reactiva':
              valor = 'Reactive';
            break;
            case 'Consumos Facturas':
              valor = 'Billing Details';
            break;
            case 'Desglose de consumos de facturas por mes':
              valor = 'Detailed bills are shown';
            break;
            case 'Comparativa Consumos Facturas':
              valor = 'Invoice Consumption Comparison';
            break;
            case 'Comparativa consumos de las facturas año actual y año anterior.':
              valor = 'Invoice Consumption Comparison. Current year and Last Year.';
            break;
            case 'Consumo en kWh':
              valor = 'KWh consumption';
            break;
            case 'Año Actual Activa':
              valor = 'Active Current  Year';
            break;
            case 'Año Actual Reactiva':
              valor = 'Reactive Current  Year';
            break;
            case 'Año Anterior Activa':
              valor = 'Active Last Year';
            break;
            case 'Año Anterior Reactiva':
              valor = 'Reactive Last Year ';
            break;
            case 'Estados de los contratos':
              valor = 'Contract States';
            break;
            case 'Se muestran la cantidad de contratos que existen en cada estado.':
              valor = 'The number of contracts that exist in each state are shown.';
            break;
            case 'Número de contratos.':
              valor = 'Number of contracts.';
            break;
            case 'Desglose Consumos':
              valor = 'Detail of Consumptions';
            break;
            case 'Selecciona dentro del gráfico para hacer zoom':
              valor = 'Select inside the chart to zoom';
            break;

            case 'Alquiler':
              valor = 'Rental';
            break;
            case 'Derechos':
              valor = 'Rights';
            break;
            case 'Descuentos Cargos':
              valor = 'Discounts Charges';
            break;
            case 'Energia':
              valor = 'Energy';
            break;
            case 'Excesos':
              valor = 'Excess';
            break;
            case 'Gas':
              valor = 'Gas';
            break;
            case 'Impuesto Electrico':
              valor = 'Electric Tax';
            break;
            case 'Potencia':
              valor = 'Power';
            break;
            case 'Productos':
              valor = 'Products';
            break;
            case 'Reactiva':
              valor = 'Reactive';
            break;
            case 'Varios':
              valor = 'Various';
            break;
            case 'Impuesto':
              valor = 'Tax';
            break;
            case 'Importe Consumo':
              valor = 'Consumption Amount';
            break;
            case 'Importe Peaje':
              valor = 'Toll Amount';
            break;
            case 'Término Fijo':
              valor = 'Fix Term';
            break;
            case 'Impuesto Hidrocarburo':
              valor = 'Hydrocarbon Amount';
            break;
        }
        break;
      case "cat":
          switch (cadena) {
            case "Detalle Facturas":
              valor = 'Detall Factures';
            break;
            case "Comparativa Importes Facturas":
              valor = 'Comparativa Imports Factures';
            break;
            case "Se muestran las facturas detalladas.":
              valor = 'Es mostren les factures detallades.';
            break;
            case "Desglose Conceptos €":
              valor = 'Desglossament Conceptos €';
            break;
            case "Comparativa importes de las facturas año actual y año anterior.":
              valor = 'Comparativa importes dels  factures any actual i any anterior.';
            break;
            case "Importe en €":
              valor = 'Import en €';
            break;
            case "No hay datos disponibles para mostrar":
              if(this.MensajeCCHConExplicacion) {
                valor = 'No hi ha dades disponibles per mostrar, modifiqui el filtre de data';
              } else {
                valor = 'No hi ha dades disponibles per mostrar';
              }
            break;
            case "Año Anterior":
              valor = 'Any Anterior';
            break;
            case "Año Actual":
              valor = 'Any Actual';
            break;
            case "Histórico Importes Facturas":
              valor = 'Històric Imports Factures';
            break;
            case "Histórico de los importes de las facturas.":
              valor = 'Històric dels imports de les factures.';
            break;
            case "Importes":
              valor = 'Imports';
            break;
            case 'Activa P':
              valor = 'Activa P';
            break;
            case 'Activa':
              valor = 'Activa';
            break;
            case 'Reactiva P':
              valor = 'Reactiva P';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Consumos Facturas':
              valor = 'Consums Factures';
            break;
            case 'Desglose de consumos de facturas por mes':
              valor = 'Desglossament de consums de factures per mes';
            break;
            case 'Comparativa Consumos Facturas':
              valor = 'Comparativa Consums Factures';
            break;
            case 'Comparativa consumos de las facturas año actual y año anterior.':
              valor = 'Comparativa consums de les factures any actual i any anterior.';
            break;
            case 'Consumo en kWh':
              valor = 'Consum en kWh';
            break;
            case 'Año Actual Activa':
              valor = 'Any Actual Activa';
            break;
            case 'Año Actual Reactiva':
              valor = 'Any Actual Reactiva';
            break;
            case 'Año Anterior Activa':
              valor = 'Any Anterior Activa';
            break;
            case 'Año Anterior Reactiva':
              valor = 'Any Anterior Reactiva';
            break;
            case 'Estados de los contratos':
              valor = 'Estats dels contractes';
            break;
            case 'Se muestran la cantidad de contratos que existen en cada estado.':
              valor = 'Se mostra la quantitat de contractes que existeixen en cada estat.';
            break;
            case 'Número de contratos.':
              valor = 'Número de contractes.';
            break;
            case 'Desglose Consumos':
              valor = 'Detall Consums';
            break;
            case 'No hay datos disponibles para mostrar':
              if(this.MensajeCCHConExplicacion) {
                valor = 'No hi ha dades disponibles per mostrar, modifiqui el filtre de data';
              } else {
                valor = 'No hi ha dades disponibles per mostrar';
              }
            break;
            case 'Selecciona dentro del gráfico para hacer zoom':
              valor = 'Selecciona dins el gràfic per fer zoom';
            break;

            case 'Alquiler':
              valor = 'Lloguer';
            break;
            case 'Derechos':
              valor = 'Drets';
            break;
            case 'Descuentos Cargos':
              valor = 'Descomptes Càrrecs';
            break;
            case 'Energia':
              valor = 'Energia';
            break;
            case 'Excesos':
              valor = 'Excesos';
            break;
            case 'Gas':
              valor = 'Gas';
            break;
            case 'Impuesto Electrico':
              valor = 'Impost Elèctric';
            break;
            case 'Potencia':
              valor = 'Potència';
            break;
            case 'Productos':
              valor = 'Productes';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Varios':
              valor = 'Diversos';
            break;
            case 'Impuesto':
              valor = 'Impost';
            break;
            case 'Importe Consumo':
              valor = 'Import Consum';
            break;
            case 'Importe Peaje':
              valor = 'Import Peatge';
            break;
            case 'Término Fijo':
              valor = 'Terme Fix';
            break;
            case 'Impuesto Hidrocarburo':
              valor = 'Impost Hidrocarbur';
            break;

          }
        break;
      default:
          switch (cadena) {
            case "Detalle Facturas":
              valor = 'Detalle Facturas';
            break;
            case "Comparativa Importes Facturas":
              valor = 'Comparativa Importes Facturas';
            break;
            case "Se muestran las facturas detalladas.":
              valor = 'Se muestran las facturas detalladas.';
            break;
            case "Desglose Conceptos €":
              valor = 'Desglose Conceptos €';
            break;
            case "Comparativa importes de las facturas año actual y año anterior.":
              valor = 'Comparativa importes de las facturas año actual y año anterior.';
            break;
            case "Importe en €":
              valor = 'Importe en €';
            break;
            case "No hay datos disponibles para mostrar":
              if(this.MensajeCCHConExplicacion) {
                valor = 'No hay datos disponibles para mostrar, modifique el filtro de fecha';
              } else {
                valor = 'No hay datos disponibles para mostrar';
              }
            break;
            case "Año Anterior":
              valor = 'Año Anterior';
            break;
            case "Año Actual":
              valor = 'Año Actual';
            break;
            case "Histórico Importes Facturas":
              valor = 'Histórico Importes Facturas';
            break;
            case "Histórico de los importes de las facturas.":
              valor = 'Histórico de los importes de las facturas.';
            break;
            case "Importes":
              valor = 'Importes';
            break;
            case 'Activa P':
              valor = 'Activa P';
            break;
            case 'Activa':
              valor = 'Activa';
            break;
            case 'Reactiva P':
              valor = 'Reactiva P';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Consumos Facturas':
              valor = 'Consumos Facturas';
            break;
            case 'Desglose de consumos de facturas por mes':
              valor = 'Desglose de consumos de facturas por mes';
            break;
            case 'Comparativa Consumos Facturas':
              valor = 'Histórico de consumo';
            break;
            case 'Comparativa consumos de las facturas año actual y año anterior.':
              valor = 'Comparativa consumos de las facturas año actual y año anterior.';
            break;
            case 'Consumo en kWh':
              valor = 'Consumo en kWh';
            break;
            case 'Año Actual Activa':
              valor = 'Año Actual Activa';
            break;
            case 'Año Actual Reactiva':
              valor = 'Año Actual Reactiva';
            break;
            case 'Año Anterior Activa':
              valor = 'Año Anterior Activa';
            break;
            case 'Año Anterior Reactiva':
              valor = 'Año Anterior Reactiva';
            break;
            case 'Estados de los contratos':
              valor = 'Estados de los contratos';
            break;
            case 'Se muestran la cantidad de contratos que existen en cada estado.':
              valor = 'Se muestran la cantidad de contratos que existen en cada estado.';
            break;
            case 'Número de contratos.':
              valor = 'Número de contratos.';
            break;
            case 'Desglose Consumos':
              valor = 'Desglose Consumos';
            break;
            case 'No hay datos disponibles para mostrar':
              if(this.MensajeCCHConExplicacion) {
                valor = 'No hay datos disponibles para mostrar, modifique el filtro de fecha';
              } else {
                valor = 'No hay datos disponibles para mostrar';
              }
            break;
            case 'Selecciona dentro del gráfico para hacer zoom':
              valor = 'Selecciona dentro del gráfico para hacer zoom';
            break;
            case 'Alquiler':
              valor = 'Alquiler';
            break;
            case 'Derechos':
              valor = 'Derechos';
            break;
            case 'Descuentos Cargos':
              valor = 'Descuentos Cargos';
            break;
            case 'Energia':
              valor = 'Energia';
            break;
            case 'Excesos':
              valor = 'Excesos';
            break;
            case 'Gas':
              valor = 'Gas';
            break;
            case 'Impuesto Electrico':
              valor = 'Impuesto Electrico';
            break;
            case 'Potencia':
              valor = 'Potencia';
            break;
            case 'Productos':
              valor = 'Productos';
            break;
            case 'Reactiva':
              valor = 'Reactiva';
            break;
            case 'Varios':
              valor = 'Varios';
            break;
            case 'Impuesto':
              valor = 'Impuesto';
            break;
            case 'Importe Consumo':
              valor = 'Importe Consumo';
            break;
            case 'Importe Peaje':
              valor = 'Importe Peaje';
            break;
            case 'Término Fijo':
              valor = 'Término Fijo';
            break;
            case 'Impuesto Hidrocarburo':
              valor = 'Impuesto Hidrocarburo';
            break;
          }
        break;
    }

    // console.log(valor);


    return valor;
  }
}
