<app-header></app-header>

<div class="wrapper">

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <!-- Datos del CUPS -->
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px">
      <mat-card fxFlex>
        <mat-card-header>
          <mat-card-title>{{'DATOS DEL CUPS' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra los datos principales del cups.' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Código Cups -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código Cups' | translate}}" value="{{CodigoCups}}" disabled="PerfilCupsCodigoCupsDisabled">
            </mat-form-field>
            <!-- Nombre Distribuidora -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Nombre Distribuidora' | translate}}" value="{{Distribuidora}}" disabled="PerfilCupsDistribuidoraDisabled">
            </mat-form-field>
            <!-- Código CNAE -->
            <mat-form-field fxFlex *ngIf="isActivo">
              <input matInput placeholder="{{'Código CNAE' | translate}}" value="{{CodigoCnae}}" disabled="PerfilCupsCodigoCnaeDisabled">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Razón Social -->
            <mat-form-field fxFlex *ngIf="RazonSocial && isActivo">
              <input matInput placeholder="{{'Razón Social' | translate}}" value="{{RazonSocial}}" *ngIf="!CambiarTitularElekluz" disabled="PerfilCupsRazonSocialDisabled">
              <input matInput placeholder="{{'Titular' | translate}}" value="{{RazonSocial}}" *ngIf="CambiarTitularElekluz" disabled="PerfilCupsRazonSocialDisabled">
            </mat-form-field>
            <!-- Nombre -->
            <mat-form-field fxFlex *ngIf="Nombre && isActivo">
              <input matInput placeholder="{{'Nombre' | translate}}" value="{{Nombre}}" disabled="PerfilCupsNombreDisabled">
            </mat-form-field>
            <!-- Apellidos -->
            <mat-form-field fxFlex *ngIf="Apellidos && isActivo">
              <input matInput placeholder="{{'Apellidos' | translate}}" value="{{Apellidos}}" disabled="PerfilCupsApellidosDisabled">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Código Postal -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostal}}" disabled="PerfilCupsCodigoPostalDisabled">
            </mat-form-field>
            <!-- Ciudad -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Ciudad'| translate}}" value="{{Ciudad}}" disabled="PerfilCupsCiudadDisabled">
            </mat-form-field>
            <!-- Dirección -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Dirección' | translate}}" value="{{Direccion}}" disabled="PerfilCupsDireccionDisabled">
            </mat-form-field>
            <!-- Número -->
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Número' | translate}}" value="{{Numero}}" disabled="PerfilCupsNumeroDisabled">
            </mat-form-field>
            <!-- Aclarador -->
            <mat-form-field fxFlex *ngIf="Aclarador">
              <input matInput placeholder="{{'Aclarador' | translate}}" value="{{Aclarador}}" disabled="PerfilCupsAclaradorDisabled">
            </mat-form-field>
            <!-- Referencia Catastral -->
            <mat-form-field fxFlex *ngIf="RefCatastral">
              <input matInput placeholder="{{'RefCatastral' | translate}}" value="{{RefCatastral}}">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
            <!-- Observaciones -->
            <mat-form-field fxFlexFill>
              <textarea matInput placeholder="{{'Observaciones'| translate}}" value="{{Observaciones}}" disabled="PerfilCupsObservacionesDisabled"></textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilCupsCodigoCupsDisabled ||
              !PerfilCupsDistribuidoraDisabled ||
              !PerfilCupsCodigoCnaeDisabled ||
              !PerfilCupsRazonSocialDisabled ||
              !PerfilCupsNombreDisabled ||
              !PerfilCupsApellidosDisabled ||
              !PerfilCupsCodigoPostalDisabled ||
              !PerfilCupsCiudadDisabled ||
              !PerfilCupsDireccionDisabled ||
              !PerfilCupsNumeroDisabled ||
              !PerfilCupsAclaradorDisabled ||
              !PerfilCupsObservacionesDisabled">
          <button mat-button >{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
<div class="wrapper" *ngIf="agrupacontratoscups && !monopunto">
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayoutGap="20px">
      <!-- <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-sm="column" fxLayoutGap="20px"> -->
        <mat-card fxFlex>
        <mat-card-header>
          <mat-card-title>{{'HISTORICO' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Muestra el historico de contratos' | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align:center">
          <!-- Position Column -->
          <ng-container matColumnDef="CodigoContrato">
            <mat-header-cell *matHeaderCellDef>{{'Contrato' | translate}}  </mat-header-cell>
            <mat-cell *matCellDef="let element"> <a mat-button color="primary" [routerLink]="[ '/informacion', 'perfil', 'contrato', element.IdContrato ]"
              matTooltip="Ver perfil de Contrato">
              {{element.CodigoContrato}}
            </a> </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ContratoSituacion">
             <mat-header-cell *matHeaderCellDef>{{'Situación' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span matTooltip="{{ element.ContratoSituacion }}"
                class="{{ element.ContratoSituacion | contratoSituacionIcon }}"></span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Cups">
            <mat-header-cell *matHeaderCellDef> CUPS </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Cups}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaAlta">
            <mat-header-cell *matHeaderCellDef>{{'Fecha Alta' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.FechaAlta| date: 'dd/MM/yyyy'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaVto">
            <mat-header-cell *matHeaderCellDef>{{'Fecha Vencimiento' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.FechaVto | date: 'dd/MM/yyyy'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="FechaBaja">
            <mat-header-cell *matHeaderCellDef>{{'Fecha Baja' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.FechaBaja | date: 'dd/MM/yyyy'}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card>
    </div>
  </div>
</div>

<!-- <app-footer></app-footer> -->
