<app-header></app-header>
<div class="pageContent">
  <mat-card class="cardsContents listadoContratosAutoconsumo">
    <h2>Seleccionar punto de suministro</h2>

    <article>
      <mat-select placeholder="Contrato" (selectionChange)="filtraListadoContratos($event.value)"
        [(ngModel)]="filtraContratos" [disabled]="ultimosContratos == undefined || ultimosContratos.length == 1">
        <mat-option title="{{Contrato.DireccionSuministro}}" [value]="Contrato.DireccionSuministro"
          *ngFor="let Contrato of ultimosContratos track by DireccionSuministro">{{ Contrato.DireccionSuministro }}
        </mat-option>
      </mat-select>
    </article>
  </mat-card>

  <mat-card class="cardsContents cardEnvioLecturas">
    <h2>Enviar lecturas</h2>

    <article>
      <section>
        <b>P1: </b>
        <input type="text" placeholder="Escribir...">
      </section>
      <section>
        <b>P2: </b>
        <input type="text" placeholder="Escribir...">
      </section>
      <section>
        <b>P3: </b>
        <input type="text" placeholder="Escribir...">
      </section>
    </article>

    <div class="buttonFooterCards">
      <button class="btn btnStyle_2">Enviar</button>
    </div>
  </mat-card>

  <mat-card class="cardsContents cardUltimasLecturas">
    <article>
      <h2>Últimas lecturas</h2>

      <table>
        <tr *ngFor="let number of [1,2,3]; let i = index">
          <td><b>{{number | date:'yyyy/MM/dd'}}</b></td>
          <td><b>P{{i + 1}}:</b> {{number * (i + 2)}}</td>
          <td><b>P{{i + 1}}:</b> {{number * (i + 6)}}</td>
          <td><b>P{{i + 1}}:</b> {{number * (i + 4)}}</td>
        </tr>
      </table>
    </article>
  </mat-card>

  <mat-card class="cardsContents cardExplicacion">
      <h2>Explicación autolectura</h2>

      <article>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </article>
  </mat-card>
</div>