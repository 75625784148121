<div mat-dialog-content fxLayout="column" class="dialog">
    
    <mat-card class="cardsContents cardDatosPersonales">
        <h2>Datos puntos de suministro</h2>
        
        <table>
          <tr>
            <td>CUPS:</td>
            <td><input type="text" id="cups"></td>
          </tr>
          <tr>
            <td>Dirección punto de suministro:</td>
            <td><input type="text" id="direccion"></td>
          </tr>
          <tr>
            <td>Tarifa de acceso:</td>
            <td><input type="text" id="tarifa"></td>
          </tr>
          <tr>
            <td>Potencia contratada:</td>
            <td><input type="text" id="potencia"></td>
          </tr>
          <tr>
            <td>Distribuidora:</td>
            <td><input type="text" id="distribuidora"></td>
          </tr>
        </table>
  
        <div>
          <button class="btn btnStyle_2">Enviar cambios <i class="fa-solid fa-paper-plane"></i></button>
        </div>
        
      </mat-card>
</div>