<app-header></app-header>
<div class="pageContent">
  <h3>Contrato</h3>

  <mat-card class="tablaListadoContratos">
    <!-- <h3 style="margin-bottom: 10px;">Contratos activos: <b>{{ultimosContratos.length}}</b></h3> -->

    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{{dataSource2[0].CodigoContrato}}</div>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].ContratoSituacion">
                  {{dataSource2[0].ContratoSituacion}}
                  <i>-</i>
                  <span>{{dataSource2[0].Tarifa}}</span>
                </td>
                <td>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].NombreCliente">{{dataSource2[0].Tarifa}}</td>
                <td title="dataSource2[0].NombreCliente"><span>{{dataSource2[0].NombreCliente}}</span></td>
                <td title="{{dataSource2[0].DireccionSuministro}}"><span>{{dataSource2[0].DireccionSuministro}}</span></td>
                <td>
                  <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                </td>
                <td>
                  <div>
                    <i class="fal fa-angle-down"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel-header>
        <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

          <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">

          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
              <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource2[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource2[0].CodigoContrato">
                <td>
                  <div >{{contrato.CodigoContrato}}</div>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  {{contrato.ContratoSituacion}}
                  <i>-</i>
                  <span>{{contrato.Tarifa}}</span>
                </td>
                <td>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
  <br>
  <h3>Facturas</h3>

  <mat-card class="cardsContents">
    <table class="tablaContratos" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matSortActive="FechaFactura" matSortDirection="desc">
      <!-- <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="tablaContratosBody">
          <mat-checkbox color="primary"
            [disabled]="OcultarFacturasConecta2DeAntesDel31x11x19 && getMilisegundosDeFecha(row.FechaFactura) < fechaLimite.getTime()"
            (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container> -->

      <!-- Factura -->
      <ng-container matColumnDef="Factura">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Factura' | translate }}</span>
          <!-- <input class="form-field" matInput [formControl]="NumeroFacturaFilter"
            placeholder="{{'Filtrar' | translate}}" /> -->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <ng-container>
            {{element.Factura  + " - " + element.NumeroFactura}}
          </ng-container>
        </td>
      </ng-container>

      <!-- Fecha Factura -->
      <ng-container matColumnDef="FechaFactura">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Fecha Factura' | translate }}</span>
          <!-- <input class="filter-input" matInput [formControl]="FechaFacturaFilter"
            placeholder="{{'Filtrar' | translate}}" />-->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">{{element.FechaFactura | date: 'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <!-- Producto -->
      <ng-container matColumnDef="ProductoFactura">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Producto' | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">{{element.TextoTarifaGrupo}}</td>
      </ng-container>

      <!-- Periodo de facturación -->
      <ng-container matColumnDef="PeriodoFacturacion">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Periodo de facturación' | translate }}</span>
          <!-- <input class="filter-input" matInput [formControl]="FechaDesdeFilter"
             placeholder="{{'Filtrar' | translate}}" /> -->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <container-element [ngSwitch]="element.Periodicidad">
            <some-element *ngSwitchCase="1">Mensual</some-element>
            <some-element *ngSwitchCase="2">Bimestral</some-element>
            <some-element *ngSwitchCase="3">Trimestral</some-element>
            <!-- <some-element *ngSwitchCase="4">Cuatrimestral</some-element> -->
          </container-element>
        </td>
      </ng-container>

      <!-- Tipo -->
      <!-- <ng-container matColumnDef="TipoFactura">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Tipo' | translate}}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <span>{{element.Serie}}</span>
        </td>
      </ng-container> -->

      <!-- Recarga -->
      <!-- <ng-container matColumnDef="RecargaFactura">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Recarga' | translate}}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">{{element.Recarga | currency:'EUR':'symbol':'':'es'}}</td>
      </ng-container> -->


      <!-- Importe total -->
      <ng-container matColumnDef="ImporteTotal">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Importe' | translate}}</span>
          <!-- <input class="filter-input" matInput [formControl]="ImporteTotalFilter"
            placeholder="{{'Filtrar' | translate}}" /> -->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <span>{{element.Importe | currency:'EUR':'symbol':'':'es'}}</span>
        </td>
      </ng-container>

      <!-- Saldo -->
      <ng-container matColumnDef="Saldo">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera" [ngStyle]="{ display: hideSaldo ? 'none' : '' }">
          <span mat-sort-header>{{'Saldo' | translate}}</span>
          <!-- <input class="filter-input" matInput placeholder="{{'Filtrar' | translate}}" /> -->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody" [ngStyle]="{ display: hideSaldo ? 'none' : '' }">
          {{element.Saldo | currency:'EUR':'symbol':'':'es'}}
        </td>
      </ng-container>
      

      <!-- Estado -->
      <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Estado' | translate}}</span>
          <!-- <input class="filter-input" matInput [formControl]="EstadoFilter" placeholder="Filtrar" /> -->
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <div style="display: flex;align-items: center;gap: 10px;">
            <div class="faInfo" [matTooltip]="isToolTipText(element)" *ngIf="isToolTipState(element)" matTooltipPosition="left">           
              <i class="fas fa-info"></i>
            </div>
            <container-element [ngSwitch]="element.IdCodigoSituacionCobro">
              <!-- <some-element *ngSwitchCase="'Pagado'" style="color: #25da22;">{{element.Estado_Fac}}</some-element>
              <some-element *ngSwitchCase="'Pendiente'" style="color: #da9022;">{{element.Estado_Fac}}</some-element>
              <some-element *ngSwitchCase="'No Pagado'" style="color: #f41717;">{{element.Estado_Fac}}</some-element> -->
              <some-element *ngSwitchCase="1" style="color: #da9022;">Pendiente</some-element>
              <some-element *ngSwitchCase="2" style="color: #25da22;">Cobrado</some-element>
              <some-element *ngSwitchCase="3" style="color: #f41717;">Impagado</some-element>
              <some-element *ngSwitchCase="4" style="color: #25da22;">Remesado</some-element>
<!--               
              <some-element *ngSwitchCase="5" style="color: #da9022;">Carta aviso</some-element>
              <some-element *ngSwitchCase="6" style="color: #f41717;">Carta corte</some-element>
              <some-element *ngSwitchCase="7" style="color: #f41717;">Cortado</some-element>

              <some-element *ngSwitchCase="10" style="color: #da9022;">Carte corte 1</some-element>
              <some-element *ngSwitchCase="8" style="color: #25da22;">Cobrado norma 57</some-element>
              <some-element *ngSwitchCase="9" style="color: #f41717;">Cancelado por abono</some-element> -->
              
            </container-element>
            <img src="assets/img/botonTPV.png" width="35px" style="cursor: pointer;" (click)="redsysdialog(element)" [hidden]="element.Serie == 'REGPREPAGO' || element.Estado || element.IdCodigoSituacionCobro == 4">
            <!-- [hidden]="dataSource2[0].IsPrecarga || element.Estado" -->
          </div>
        </td>
      </ng-container>

      <!-- Descargar -->
      <ng-container matColumnDef="Descargar">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Descargar' | translate}}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">

          <div *ngIf="element.IdContratoDocumento;">
            <img src="assets/img/iconoDescargaFolder.png" width="30px" style="cursor: pointer;"  (click)="descargaFactura(element)" matTooltip="Descargar factura" *ngIf="getMilisegundosDeFecha(element.FechaFactura) > fechaLimite.getTime()">
            <img src="assets/img/iconoNoDescargaFolder.png" width="30px" style="cursor: pointer;" matTooltip="Factura no disponible, contacta con nosotros" *ngIf="getMilisegundosDeFecha(element.FechaFactura) < fechaLimite.getTime()">
          </div>

          <div *ngIf="!element.IdContratoDocumento;">
            <img src="assets/img/iconoNoDescargaFolder.png" width="30px" matTooltip="Factura no disponible, contacta con nosotros">
          </div>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="TPV" *ngIf="!isProductoPrecarga">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
          <span mat-sort-header>{{'Acciones' | translate}}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="tablaContratosBody">
          <button *ngIf="!element.Estado" mat-stroked-button matTooltip="Pagar ahora" (click)="redsysdialog(element)">Pagar <i class="fas fa-chevron-circle-right"></i></button>
          <button *ngIf="element.Estado" mat-stroked-button matTooltip="Pago completado" disabled>Pagado</button>
        </td>
      </ng-container> -->

      <!-- Desplegable -->
      <ng-container matColumnDef="Dropdown">
        <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">Ver más</th>
        <td mat-cell *matCellDef="let element" style="width: 70px; text-align: center; padding: 0 !important;"
          class="tablaContratosBody">
          <h2 id="desplegableRow" (click)="expandedElement = expandedElement === element ? null : element"
            matTooltip="Informacón del contrato">
            <i class="fal fa-angle-up" *ngIf="element == expandedElement"></i>
            <i class="fal fa-angle-down" *ngIf="element != expandedElement"></i>
          </h2>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="contratosDropdown">
          <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <mat-card class="matCardContratos">&nbsp;

              <table>
                <tr>
                  <td>Fecha Emisión</td>
                  <td>{{element.FechaFactura | date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr>
                  <td>Periodo de facturación</td>
                  <td>{{element.Periodicidad}}</td>
                </tr>
                <!-- <tr *ngIf="element.Factura">
                  <td>Tipo</td>
                  <td>{{element.Factura}}</td>
                </tr> -->
                <tr>
                  <td>Importe</td>
                  <td *ngIf="element.IdFacturaVentaCabecera">
                    {{element.Importe | currency:'EUR':'symbol':'':'es'}}
                  </td>
                  <td *ngIf="!element.IdFacturaVentaCabecera">
                    {{element.Recarga | currency:'EUR':'symbol':'':'es'}}
                  </td>
                </tr>
                <tr>
                  <td>Saldo</td>
                  <td>{{element.Recarga - element.Importe | currency:'EUR':'symbol':'':'es'}}</td>
                </tr>
              </table>
            </mat-card>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div fxLayoutAlign="center" *ngIf="textoTablaFacturasVacia" style="margin: 3em 0;">
      <span> {{'No hay datos disponibles.' | translate}}</span>
    </div>

    <!-- <button *ngIf="btnDescargaFacturasMasivaVisible" matTooltip="Descargar facturas" mat-button color="primary"
      class="mt-20 primary" [ngClass]="primary" (click)="descargarSeleccionados();">
      <i class="far fa-file-archive fa-2x"></i> {{'Comprimido' | translate}}
    </button>
    <mat-progress-spinner *ngIf="loadingSpinnerDescarga" [diameter]="25" style="margin-left: 20px; margin-top: 20px;"
      color="primary" mode="indeterminate">
    </mat-progress-spinner>
    <button *ngIf="btnDescargaFacturasExcelVisible" mat-button color="primary" matTooltip="Descargar facturas en excel"
      (click)="descargarSeleccionadosExcel();"><i class="fas fa-file-excel fa-2x"></i> Excel</button>
    <button *ngIf="MostrarBotonFacturasImpagadas" mat-button color="primary"
      [disabled]="!facturas || facturas.length < 1" matTooltip="Mostrar sólo facturas impagadas"
      (click)="mostrarSoloFacturasImpagadas()"><i class="fas fa-file-exclamation fa-2x"></i>
      <span *ngIf="facturasImpagadas"> {{'Mostrar todas' | translate}}</span>
      <span *ngIf="!facturasImpagadas"> {{'Mostrar impagadas' | translate}}</span>
    </button> -->
    <mat-progress-spinner *ngIf="loadingSpinnerDescargaExcel" [diameter]="25"
      style="margin-left: 20px; margin-top: 20px;" color="primary" mode="indeterminate">
    </mat-progress-spinner>

    <div id="footerTableFacturas" *ngIf="isProductoPrecarga">
      <p>*Si quieres reclamar tu saldo positivo, escríbenos un email a <a style="text-decoration: underline;color: #d73838;font-weight: bold" href="mailto:clientes@wekiwi.es">clientes@wekiwi.es</a> y tu saldo será abonado en tu cuenta bancaria</p>
      <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>

</div>
<!-- <app-footer></app-footer> -->
