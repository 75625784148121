import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { LoginService } from '../../../services/login/login.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { Contrato } from '../../../interfaces/contrato';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { Factura } from '../../../interfaces/factura';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { TpvDialogComponent } from '../../../components/dialogs/tpv-dialog/tpv-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { dateFormat } from 'highcharts';
import { FormularioPagoRedsysComponent } from '../../usuario/formularios/formulario-pago-redsys/formulario-pago-redsys.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),

  ]
})
export class FacturasComponent implements OnInit {
  dataSource2: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];
  contratoSeleccionado: Contrato;
  chartOptions: any = { series: [{ data: [] }] };
  facturas: Factura[];
  mostrarSpinner = true;
  textoModal: string;
  arraySeleccionados: any = [];
  companyName: any;
  isLoading = true;
  loadingSpinnerDescarga = false;
  btnDescargaFacturasMasivaVisible = true;
  btnDescargaFacturasExcelVisible = true;
  loadingSpinnerDescargaExcel = false;
  arrayFacturasSeleccionadas: any = [];
  textoTablaFacturasVacia: boolean = false;
  MostrarDeuda = environment.MostrarDeuda;
  HabilitarTPV = environment.HabilitarTPV;
  PrefijoNumeracionFacturas = environment.PrefijoNumeracionFacturas;
  OcultarFacturasConecta2DeAntesDel31x11x19: boolean;
  MostrarFacturaExcel = environment.MostrarFacturaExcel;
  SimplificacionSerieFactura = environment.SimplificacionSerieFactura;
  NombreEmpresa = environment.companyName;
  internalApiUrl: string = '';
  hideSaldo:boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource<Factura>();
  dataSourceAux = new MatTableDataSource<Factura>();
  selection = new SelectionModel<Factura>(true, []);
  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  selectFilter = new FormControl();
  EntornoFilter = new FormControl();
  NumeroFacturaFilter = new FormControl();
  FechaFacturaFilter = new FormControl();
  ImporteTotalFilter = new FormControl();
  EstadoFilter = new FormControl();
  DescargarFilter = new FormControl();
  PagarFilter = new FormControl();
  FechaHastaFilter = new FormControl();
  FechaVencimientoFilter = new FormControl();
  FechaLimitePagoFilter = new FormControl();
  FechaDesdeFilter = new FormControl();
  ProductoFilter = new FormControl();

  MostrarAdministradorListadoContratoEntorno: Boolean;
  expandedElement: any;

  filteredValues = {
    Entorno: '',
    NumeroFactura: '',
    FechaFactura: '',
    Periocidad: '',
    Tarifa: '',
    FechaHasta: '',
    FechaDesde: '',
    FechaVencimiento: '',
    FechaLimitePago: '',
    ImporteTotal: '',
    Estado: '',
    Descargar: '',
    Pagar: ''
  };

  // TODO TPV
  tarjetaNumeracion: string;
  tarjetaNombre: string;
  tarjetaMes: string;
  tarjetaAno: string;
  tarjetaCcv: string;
  dialogRef: any;
  tokenvalidado: any;

  fechaLimite: Date;
  fechaTest: Date;
  filtroTabla: any = '';
  isProductoPrecarga: any = false;

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _contratosService: ContratosService,
    private _facturasService: FacturasService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute
  ) {
    this.OcultarFacturasConecta2DeAntesDel31x11x19 = environment.OcultarFacturasConecta2DeAntesDel31x11x19;
    this.fechaLimite = new Date("2019-11-30T12:00:00-06:30");
    this.fechaTest = new Date("2020-11-30T00:00:00");
    this.MostrarAdministradorListadoContratoEntorno = environment.MostrarAdministradorListadoContratoEntorno;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 1350) {
      if (this.displayedColumns.includes('PeriodoFacturacion')) {
        this.displayedColumns = [];
        this.displayedColumns.push('Factura');
        this.displayedColumns.push('FechaFactura');
        this.displayedColumns.push('Descargar');
        this.displayedColumns.push('Estado');
        // if(!this.displayedColumns.includes('TPV') && !this.isProductoPrecarga) {
        //   this.displayedColumns.push('TPV');
        // }
        this.displayedColumns.push('Dropdown');
      }
      
    } else {
      this.expandedElement = [];
      if (!this.displayedColumns.includes('PeriodoFacturacion')) {
        this.displayedColumns = [];
        this.displayedColumns.push('Factura');
        this.displayedColumns.push('FechaFactura');
        // this.displayedColumns.push('PeriodoFacturacion');
        //this.displayedColumns.push('TipoFactura');
        this.displayedColumns.push('ImporteTotal');
        this.displayedColumns.push('Saldo');
        this.displayedColumns.push('Descargar');
        this.displayedColumns.push('Estado');
        // if(!this.displayedColumns.includes('TPV') && !this.isProductoPrecarga) {
        //   this.displayedColumns.push('TPV');
        // }
      }
    }
  }

  redsysdialog(factura) {
    this.dialogRef = this.dialog.open(FormularioPagoRedsysComponent, {
      //posibilidad de pasar path de la API desde aqui
      data: {
        'factura': factura,
        'companyName': this.NombreEmpresa,
        'token': this.almnService.getValue('token'),
        'internalApiUrl': this.internalApiUrl,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }
  ngOnInit() {
    if (window.innerWidth <= 1350) {
      this.displayedColumns.push('Factura');
      this.displayedColumns.push('FechaFactura');
      this.displayedColumns.push('Estado');
      this.displayedColumns.push('Descargar');
      this.displayedColumns.push('Dropdown');
    } else {
      this.displayedColumns = [];
      this.displayedColumns.push('Factura');
      this.displayedColumns.push('FechaFactura');
      // this.displayedColumns.push('PeriodoFacturacion');
      //this.displayedColumns.push('TipoFactura');
      this.displayedColumns.push('ImporteTotal');
      this.displayedColumns.push('Saldo');
      this.displayedColumns.push('Descargar');
      this.displayedColumns.push('Estado');
    }

    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;
      if (respuesta.__zone_symbol__value) {
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);
  }

  comprobarDatosContratos() {

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
    } else {
      this.cargarListadoContratos();
    }
  }

  cargarListadoContratos() {
    this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
      async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data))
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    // Listado contratos
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);

    
  }
  filtraListadoContratos(contrato, ajuste) {
    // if (ajuste) { sessionStorage.clear() }
    if (ajuste) { this.almnService.clear('soft'); }
    var ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    this.isProductoPrecarga = ultimoContratoActivo.IsPrecarga;

    // console.log(ultimoContratoActivo.HadRecarga);
    

    this.hideSaldo = !ultimoContratoActivo.HadRecarga;

    
    
    // if(!this.isProductoPrecarga) {
    //   if(!this.displayedColumns.includes('TPV')) {
    //     this.displayedColumns.push('TPV');
    //   }
    // } else {
    //   const key = this.displayedColumns.findIndex(x=> x == 'TPV')
    //   if(key != -1) {
    //     this.displayedColumns.splice(key, 1);
    //   }
    // }

    this.dataSource2[0] = ultimoContratoActivo;
    this.buscaFacturas(ultimoContratoActivo);
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(ultimoContratoActivo)));
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  isNumber(value) {
    return !Number.isNaN(Number(value));
  }
  customFilter() {
    this.EntornoFilter.valueChanges.subscribe(EntornoFilterValue => {
      this.filteredValues['Entorno'] = EntornoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NumeroFacturaFilter.valueChanges.subscribe(
      NumeroFacturaFilterValue => {
        this.filteredValues['NumeroFactura'] = NumeroFacturaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      }
    );
    this.FechaFacturaFilter.valueChanges.subscribe(FechaFacturaFilterValue => {
      this.filteredValues['FechaFactura'] = FechaFacturaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaDesdeFilter.valueChanges.subscribe(FechaDesdeFilterValue => {
      this.filteredValues['PeriodoFacturacion'] = FechaDesdeFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.ProductoFilter.valueChanges.subscribe(ProductoFilterValue => {
      this.filteredValues['ProductoFactura'] = ProductoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaHastaFilter.valueChanges.subscribe(FechaHastaFilterValue => {
      this.filteredValues['PeriodoFacturacion'] = FechaHastaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaVencimientoFilter.valueChanges.subscribe(FechaVencimientoFilterValue => {
      this.filteredValues['FechaVencimiento'] = FechaVencimientoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaLimitePagoFilter.valueChanges.subscribe(FechaLimitePagoFilterValue => {
      this.filteredValues['FechaLimitePago'] = FechaLimitePagoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.ImporteTotalFilter.valueChanges.subscribe(ImporteTotalFilterValue => {
      this.filteredValues['ImporteTotal'] = ImporteTotalFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.EstadoFilter.valueChanges.subscribe(EstadoFilterValue => {
      this.filteredValues['Estado'] = EstadoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DescargarFilter.valueChanges.subscribe(DescargarFilterValue => {
      this.filteredValues['Descargar'] = DescargarFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.PagarFilter.valueChanges.subscribe(PagarFilterValue => {
      this.filteredValues['Pagar'] = PagarFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: Factura, filter: string): boolean {
      const searchString = JSON.parse(filter);
      return (
        data.Entorno.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Entorno.toLowerCase()) !== -1 &&
        data.NumeroFactura.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.NumeroFactura.toLowerCase()) !== -1 &&
        data.FechaFactura.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaFactura.toLowerCase()) !== -1 &&
        data.ImporteTotal.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.ImporteTotal.toLowerCase()) !== -1 &&
        data.Periocidad.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Periocidad.toLowerCase()) !== -1 &&
        data.Tarifa.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Tarifa.toLowerCase()) !== -1 &&
        data.FechaDesde.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaDesde.toLowerCase()) !== -1 &&
        data.FechaHasta.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaHasta.toLowerCase()) !== -1 &&
        data.FechaVencimiento.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaVencimiento.toLowerCase()) !== -1 &&
        data.FechaLimitePago.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaLimitePago.toLowerCase()) !== -1
        // data..toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Estado.toLowerCase()) !== -1 &&
        // data.Descarga.toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Descarga.toLowerCase()) !== -1 &&
        // data.Pagar.toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Pagar.toLowerCase()) !== -1
      );
    };
    return myFilterPredicate;
  }

  buscaFacturas(aux) {
  var auxTime = true;
  this.isLoading = true;

   this._facturasService.getListadoFacturasOld(aux.IdContrato).subscribe(
    data => {
      this.isLoading = false;
      this.mostrarSpinner = false;
      this.facturas = this._funcionesService.decodificarToken(data) || [];
      // console.log(this.facturas);
      
      this.facturas.forEach(element => {
        element.Importe = element.ImporteTotal;
        element['Factura'] = element.Serie;
        if(!element.IdFacturaVentaCabecera) {
          element['IdFacturaVentaCabecera'] = 0 as any;
        }
      });

      auxTime = false;
      this.textoTablaFacturasVacia = (this.facturas.length > 0) ? false : true;
      this.dataSource = new MatTableDataSource<Factura>(this.facturas);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      // console.log(this.dataSource);
    },
    err => {
      console.log(err);
      this.isLoading = false;
      this.mostrarSpinner = false;
      this._funcionesService.controlStatusCallBacks(err, 'facturas');
  });

  }
  descargarSeleccionados() {
    this.btnDescargaFacturasMasivaVisible = false;
    this.loadingSpinnerDescarga = true;
    this.arrayFacturasSeleccionadas = [];
    for (const i of this.selection.selected) {
      if (i.IdContratoDocumento != '') {
        this.arrayFacturasSeleccionadas.push(i.IdContratoDocumento);
      }
    }
    // console.log(this.arrayFacturasSeleccionadas);
    this._facturasService.getDescargaFacturasZip(this.arrayFacturasSeleccionadas).subscribe(
      data => {
        this.loadingSpinnerDescarga = false;
        this.btnDescargaFacturasMasivaVisible = true;
        const zip = new Blob([data], { type: 'application/zip' });
        const zipURL = URL.createObjectURL(zip);
        window.open(zipURL);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'zip facturas');
      });
  }

  descargarSeleccionadosExcel() {
    this.btnDescargaFacturasExcelVisible = false;
    this.loadingSpinnerDescargaExcel = true;
    let array: any = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      array = array.concat(this.selection.selected[i].IdFacturaVentaCabecera);
    }
    this._facturasService.getDesgloseFacturasExcel(array, true).subscribe(
      data => {
        if (data == "error") {
          this.router.navigate(['/salir']);
        }
        this.loadingSpinnerDescargaExcel = false;
        this.btnDescargaFacturasExcelVisible = true;
        const excel = new Blob([data], { type: 'application/vnd.ms-excel' });
        const urlExcel = window.URL.createObjectURL(excel);
        window.open(urlExcel);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'facturas excel');
      });
  }

  getMilisegundosDeFecha(value) {
    return Date.parse(value)
  }

  descargaFactura(datos: any) {
    // console.log(datos);
    this._facturasService
      .getDescargaFactura(datos.IdContratoDocumento).subscribe(
        data => {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // comprobamos el navegador en funcion de ello se abre el Blob diferente para IE y para los demas
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   window.navigator.msSaveOrOpenBlob(file, 'file.pdf');
          // } else {
          window.open(fileURL);
          // }

        }, (err) => {
          this._funcionesService.controlStatusCallBacks(err, 'pdf facturas');
        });
  }

  descargaPrecios(datos: any) {
    this._facturasService
      .getDescargaPrecios(datos.IdDocumentoDescargable)
      .subscribe(data => {
        // const file = new Blob([data], { type: 'application/vnd.ms-excel' });
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);

        // comprobamos el navegador en funcion de ello se abre el Blob diferente para IE y para los demas
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file, 'file.xlsx');
        // } else {
        window.open(fileURL);
        // }

      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => {
        if (!this.OcultarFacturasConecta2DeAntesDel31x11x19
          || this.getMilisegundosDeFecha(row.FechaFactura) > this.fechaLimite.getTime()) {
          this.selection.select(row);
        } else {
          // console.log('No entra');
        }
      });
  }

  // llamada TPV REDSYS
  hacerPagoFactura(importeTotal, fechaFactura, numeroFactura): void {
    // console.log(importeTotal);
    // console.log(fechaFactura);
    // console.log(numeroFactura);
    this.dialogRef = this.dialog.open(TpvDialogComponent, {
      width: '430px',
      height: '520px',
      data: { tarjetaNumeracion: this.tarjetaNumeracion, tarjetaNombre: this.tarjetaNombre, importeTotal: importeTotal, fechaFactura: fechaFactura, numeroFactura: numeroFactura }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // console.log(result);
      if (result !== undefined) {


        this._facturasService
          .postPagoTpvRedsys(result.tarjetaNombre, result.tarjetaNumeracion, result.tarjetaMes, result.tarjetaAno, result.tarjetaCcv, importeTotal, numeroFactura)
          .subscribe(async data => {
            this.isLoading = false;
          },
            err => {
              this.dialog.open(LoginDialogComponent, {
                width: '400px',
                data: {
                  texto: 'Ha ocurrido un problema en proceso del pago.',
                  titulo: 'Error en el pago',
                  estado: false
                }
              });
              this.isLoading = false;
            }
          );
      } else {
        console.log('Formulario cancelado');
      }
    });
  }

  isToolTipState(element:any){
    return ['1', '2', '3', '4'].includes(element.IdCodigoSituacionCobro);
  }

  isToolTipText(element:any){
    switch(element.IdCodigoSituacionCobro){
      case '1':
        return "Pendiente de que Wekiwi envíe el importe de la factura al banco."
      case '2':
        return "Se ha pagado la factura con éxito."
      case '3':
        return "El importe de la factura ha venido devuelto por el banco. Si quieres realizar el pago contacta con clientes@wekiwi.es"
      case '4':
        return "Una factura marcada como 'remesada' indica que hemos transferido el importe de la factura al banco, aunque esto no implica necesariamente que la factura esté pagada en este momento."
    }
  }
}
