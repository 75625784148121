<app-header></app-header>
<div class="wrapper">
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px">

    <!-- Listado CUPS para consultar CCH -->
    <mat-checkbox (click)="selectAll(checkAll.checked)" class="myCheck" #checkAll *ngIf="muestracheckmarcarCups">{{'Marcar / Desmarcar todos' | translate}}</mat-checkbox>
    <mat-form-field fxFlex="25" *ngIf="longitud > 1">
      <mat-select placeholder="CUPS" class="filter-select" [formControl]="selectedCups"
        (selectionChange)="BusquedaNuevoCups()" multiple>
        <input class="myInput" #myInput matInput focused="'true'" type="text" (keyup)="search($event.target.value)"
          autocomplete="off" placeholder="Buscar Cups">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let Cups of cupsFiltered" [value]="Cups" disabled = {{bloqueocheckCCH}}>{{Cups.Cups.CodigoCups}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="12" *ngIf="longitud === 1">
      <input placeholder="CUPS" matInput type="text" [(ngModel)]="cupsMonopunto" disabled>
    </mat-form-field>
    <mat-form-field fxFlex="12" *ngIf="longitud > 1 && CchAgregables">
      <mat-select class="filter-select" [(value)]="tipoGraficoSelected" (selectionChange)="BusquedaNuevoCups()">
        <mat-option value="Comparativa">{{'Comparativa' | translate}}</mat-option>
        <mat-option value="Agregable">{{'Agregable' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Calendario Fecha Inicio -->
    <mat-form-field>
      <input matInput [matDatepicker]="CalFecInicio" [min]="FecInicioAux" [max]="FecFin" placeholder="{{'Fecha Inicio' | translate}}"
        [value]='FecInicio' [(ngModel)]="FecInicio" (dateChange)='CalculoSeleccionFechasInicio(FecInicio)' disabled>
      <mat-datepicker-toggle matSuffix [for]="CalFecInicio"></mat-datepicker-toggle>
      <mat-datepicker #CalFecInicio disabled="false"></mat-datepicker>
    </mat-form-field>
    <!-- Calendario Fecha Fin -->
    <mat-form-field>
      <input matInput [matDatepicker]="CalFecFin" [min]="FecInicio" [max]="FecFinAux" placeholder="{{'Fecha Fin' | translate}}"
        [value]='FecFin' [(ngModel)]="FecFin" (dateChange)='CalculoSeleccionFechasFin(FecFin)' disabled>
      <mat-datepicker-toggle matSuffix [for]="CalFecFin"></mat-datepicker-toggle>
      <mat-datepicker #CalFecFin disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
  <!-- GRAFICO CCH -->
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px">
    <div fxFlex="80" fxFlex.xs="100" fxLayoutGap="20px">
      <mat-card>
        <div class="cchloader-container" [hidden]="!this.mostrar">
          <p>{{'Procesando su petición, por favor espere' | translate}}</p>
          <div class="cchloader"></div>
        </div>
        <highcharts-chart [Highcharts]="Highcharts" class="grafico" (load)="saveInstance($event.context)"
          [(options)]="chartOptions" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"></highcharts-chart>
      </mat-card>
    </div>
  </div>
<!-- GRAFICO FACT -->
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="20px" *ngIf="DobleGraficoConsumosPorHora">
    <div fxFlex="80" fxFlex.xs="100" fxLayoutGap="20px">
      <mat-card>
        <div class="cchloader-container" [hidden]="!this.mostrar2">
          <p>{{'Procesando su petición, por favor espere' | translate}}</p>
          <div class="cchloader"></div>
        </div>
        <highcharts-chart [Highcharts]="Highcharts2" class="grafico" (load)="saveInstance($event.context)"
          [(options)]="chartOptions2" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"></highcharts-chart>
      </mat-card>
    </div>
  </div>



</div>

<!-- <app-footer></app-footer> -->
