import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  // document.querySelector('body').addEventListener("click", function(event){
  //   console.log(event);
  //   event.target['style'].background = document.getElementById('Colorrr')['value'];
  // });
