<app-header></app-header>

<div class="pageContent">
  <div>
    <!-- <h3 id="contratosActivosResumen">Contratos activos: <b>{{ultimosContratos.length}}</b></h3> -->

    <mat-card class="tablaListadoContratos">
      <!-- <h3 style="margin-bottom: 10px;">Contratos activos: <b>{{ultimosContratos.length}}</b></h3> -->

      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>{{dataSource[0].CodigoContrato}}</div>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].ContratoSituacion">
                    {{dataSource[0].ContratoSituacion}} 
                    <i>-</i>
                    <span>{{dataSource[0].Tarifa}}</span>
                  </td>
                  <td>
                    <span title="dataSource[0].Entorno">
                      <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="dataSource[0].NombreCliente">{{dataSource[0].Tarifa}}</td>
                  <td title="dataSource[0].NombreCliente"><span>{{dataSource[0].NombreCliente}}</span></td>
                  <td title="{{dataSource[0].DireccionSuministro}}"><span>{{dataSource[0].DireccionSuministro}}</span></td>
                  <td>
                    <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                  </td>
                  <td>
                    <div>
                      <i class="fal fa-angle-down"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel-header>
          <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

            <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">

            <table>
              <thead>
                <tr>
                  <th>{{'Contrato' | translate}} Nº</th>
                  <th>{{'Situación' | translate}}</th>
                  <th>{{'Tipo' | translate}}</th>
                  <th>{{'Tarifa' | translate}}</th>
                  <th>{{'Cliente' | translate}}</th>
                  <th>{{'Dirección' | translate}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
                <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource[0].CodigoContrato"> 
                  <td>
                    <div >{{contrato.CodigoContrato}}</div>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td>
                    {{contrato.ContratoSituacion}}
                    <i>-</i>
                    <span>{{contrato.Tarifa}}</span>
                  </td>
                  <td>
                    <span title="contrato.Entorno">
                      <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                      <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                    </span>
                  </td>
                  <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                  <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                  <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
                </tr>
              </tbody>
            </table>
          </div>
           
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <div class="container1">

    <mat-card class="cardsContents estimacionConsumo">
      <h2 class="tituloCards">Estimación consumo: <span style="font-weight: 400;">{{estimacionConsumo.mes}}
          {{estimacionConsumo.anio}}</span></h2>

      <!-- <highcharts-chart [Highcharts]="Highcharts" class="grafico" (load)="saveInstance($event.context)"
      [(options)]="charEstimacionConsumo" style="width: 100%; display: block; overflow: hidden;"></highcharts-chart> -->

      <article>
        <div *ngIf="isProductoPrecarga">
          <h1>{{estimacionConsumo.recargaActual}}<small style="font-size: 28px;">€</small></h1>
          <p>Recargados</p>
        </div>

        <div *ngIf="!isProductoPrecarga && 'fijo' == this.TarifaGrupo">
          <h1>{{estimacionConsumo.precioConsumo}}<small style="font-size: 28px;">€</small></h1>
          <p>Consumidos</p>
        </div>

        <div *ngIf="!isProductoPrecarga && 'indexada' == this.TarifaGrupo">
          <h1>{{estimacionConsumo.consumoTotal}}<small style="font-size: 25px;">kWh</small></h1>
          <p>Consumidos</p>
        </div>


        <section>
          <circle-progress id="Firstgrafico" *ngIf="isProductoPrecarga" [percent]="estimacionConsumo.porcentajeRecarga" [radius]="65" [outerStrokeWidth]="4"
            [innerStrokeWidth]="0" [outerStrokeColor]="'#6f49ce'" [showTitle]="false" [maxPercent]="4000" [showUnits]="false"
            [showSubtitle]="false" [responsive]="true" [space]="0">
          </circle-progress>

          <circle-progress *ngIf="isProductoPrecarga" [percent]="estimacionConsumo.porcentajeConsumo" [radius]="65" [outerStrokeWidth]="4"
            [innerStrokeWidth]="0" [outerStrokeColor]="'#fff523'" [showTitle]="false" [maxPercent]="4000" [showUnits]="false"
            [showSubtitle]="false" [responsive]="true" [space]="0">
          </circle-progress>

          <div *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo" id="innerCircle">
            {{estimacionConsumo.porcentajeDiffConsumo}}%
          </div>
          <div *ngIf="isProductoPrecarga && !estimacionConsumo.porcentajeDiffConsumo" id="innerCircle" style="font-size: 13px;">
            No data found
          </div>

          <circle-progress *ngIf="!isProductoPrecarga" [percent]="estimacionConsumo.porcentajeConsumo" [radius]="65" [backgroundColor]="'#fff523'"
            [backgroundStrokeWidth]="4" [backgroundPadding]="-10" [maxPercent]="4000" [outerStrokeWidth]="4" [showTitle]="false" [showUnits]="false"
            [outerStrokeColor]="'#fff523'" [innerStrokeWidth]="0" [showSubtitle]="false" [responsive]=true>
          </circle-progress>

        </section>
      </article>
      
      <div class="labelsHistorico">
        <div *ngIf="isProductoPrecarga">
          <span style="background-color:#6f49ce;"></span> Recarga
        </div>
        <div>
          <span style="background-color:#fff523;"></span> Consumo
        </div>
      </div>

      <!-- <div class="blurProductoPrecarga" *ngIf="!isProductoPrecarga">
        <div id="blurBackGround"></div>

        <div id="textBlur">
          <span style="color: #fff523">I</span> <span style="color: #6f49ce;">N</span> <span style="color: #fff523">F</span> <span style="color: #6f49ce;">Ó</span> <span style="color: #fff523">R</span> <span style="color: #6f49ce;">M</span> <span style="color: #fff523">A</span> <span style="color: #6f49ce;">T</span> <span style="color: #fff523">E</span> <span style="color: #6f49ce;">&nbsp;</span> <span style="color: #fff523;">&nbsp;</span> <span style="color: #6f49ce;">A</span> <span style="color: #fff523">Q</span> <span style="color: #6f49ce;">U</span> <span style="color: #fff523">Í</span>
        </div>
      </div> -->
    </mat-card>

    <mat-card class="cardsContents tusDescuentos">
      <h2 fxLayout="row" fxLayoutAlign="space-between center" class="tituloCards">
        <span>Tus descuentos</span>
        <span style="font-weight: 400;">{{estimacionConsumo.mes}} {{estimacionConsumo.anio}}</span>
      </h2>
      <section>
        <table class="tablaDescuentos">
          <!--Esta controlado solo para que vengan 3 datos-->
          <tr>
            <td *ngIf="isProductoPrecarga">Descuento Recarga</td>
            <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[2] ? tusDescuentosTarifaGrupo[2].CargoDescuentoEnergia || 0 : 0}}%</td>
            <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Tarifa</td>
            <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
            <td>
              <span *ngIf="isProductoPrecarga">Activo</span>
              <span *ngIf="!isProductoPrecarga">Desactivado</span>
            </td>
            <td>
              <img *ngIf="isProductoPrecarga" src="assets/img/checkDescuentos.png" width="35px">
              <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
            </td>
            <td><input type="button" class="btn btnStyle_2" value="Saber más" (click)="verDialogInfo('tarifa')"></td>
          </tr>
          <tr [ngSwitch]="descuentoOnline">
            <td>Descuento Online</td>
            <td>{{tusDescuentosTarifaGrupo[0] ? tusDescuentosTarifaGrupo[0].CargoDescuentoEnergia || 0 : 0}}%</td>
            <td><span>{{descuentoOnline}}</span></td>
            <td [ngSwitch]="descuentoOnline">
              <img src="assets/img/checkDescuentos.png" width="35px" *ngSwitchCase="'Activo'">
              <img src="assets/img/crossDescuentos.png" width="35px" *ngSwitchCase="'Perdido'">
              <img src="assets/img/interrogationDescuentos.png" width="35px" *ngSwitchCase="'Peligro'">
            </td>
            <td><input type="button" class="btn btnStyle_2" value="Saber más" (click)="verDialogInfo('online')"></td>
          </tr>
          <tr>
            <td *ngIf="isProductoPrecarga">Descuento Acierta</td>
            <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[1] ? tusDescuentosTarifaGrupo[1].CargoDescuentoEnergia || 0 : 0}}%</td>
            <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Acierta</td>
            <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
            <td>
              <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90">Activo</span>
              <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110">En peligro</span>
              <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110">Perdido</span>
              <span *ngIf="!isProductoPrecarga">Desactivado</span>
            </td>
            <td>
              <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90" src="assets/img/checkDescuentos.png" width="35px">
              <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110" src="assets/img/crossDescuentos.png" width="35px">
              <img *ngIf="isProductoPrecarga  && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110" src="assets/img/interrogationDescuentos.png" width="35px">
              <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
            </td>
            <td><input type="button" class="btn btnStyle_2" value="Saber más" (click)="verDialogInfo('acierta')"></td>
          </tr>
        </table>
      </section>
    </mat-card>
  </div>

  <div *ngIf="mostrarAvisos">
    <mat-card class="cardsContents cardAvisos" fxFlex="100">
      <h2 class="tituloCards">Avisos</h2>

      <div class="cardAvisosChild">
        <div fxLayout="row" id="cardAvisosChild1">
          <img src="assets/img/iconoAvisos.png">
          <span>
            Según la estimación de consumo de este mes podrás perder tu descuento <b>"Acierta".</b><br>
            Cambia tu prepago mensual y mantén tu descuento en los próximos meses.
          </span>
        </div>

        <div id="cardAvisosChild2">
          <input type="button" href="#" class="btn btnStyle_1" value="Prepago">
        </div>
      </div>

      <div class="cardAvisosChild">
        <div fxLayout="row" id="cardAvisosChild1">
          <img src="assets/img/iconoAvisos.png">
          <span>
            Este mes has contactado 1 vez con el equipo de atención al cliente telefónico perdidiendo tu descuento
          </span>
        </div>

        <div id="cardAvisosChild2">
          <input type="button" href="#" class="btn btnStyle_1" value="Contacto">
        </div>
      </div>

      <div class="cardAvisosChild">
        <div fxLayout="row" id="cardAvisosChild1">
          <img src="assets/img/iconoAvisos.png">
          <span>
            Tu recarga ahora mismo es mensual. Prueba a cambiar a un plant <b>bimensual</b> obteniendo mayor descuetno
            en tu factura.
          </span>
        </div>

        <div id="cardAvisosChild2">
          <input type="button" href="#" class="btn btnStyle_1" value="Prepago">
        </div>
      </div>
    </mat-card>
  </div>

  <div>
    <mat-card class="cardsContents historicoConsumo">
      <h2 class="tituloCards">Histórico de consumo:

        <mat-select placeholder="Año" [(ngModel)]="fechaGrafico"
          (selectionChange)="consumoMasPrecio(fechaGrafico, 'historico')">
          <mat-option *ngFor="let year of listadoYears" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </h2>

      <highcharts-chart [Highcharts]="Highcharts" class="grafico" (load)="saveInstance($event.context)"
        [(update)]="updateFlag" [oneToOne]="oneToOneFlag" [(options)]="charHistoricoConsumo"
        style="width: 100%; display: block; overflow: hidden;"></highcharts-chart>
    </mat-card>
  </div>

  <div id="container2">
    <mat-card class="cardsContents cardConsejo" *ngIf="mostrarConsejos">
      <h2 class="tituloCards">Consejos</h2>

      <div>
        <table>
          <!--Esta controlado solo para que vengan 3 datos-->
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>En el último año has perdido 8 veces el descuento "Acierta". Te recomendamos aumentar tu recarga y
                obtener un x% de desceunto en los proximos meses.</span></td>
          </tr>
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>Los meses de <b>verano</b> consumes de media X kwh menos que los de invierno. Con Wekiwi puedes
                elegir tu recarga <b>verano e invierno</b> de manera independiente.<br>Te enseñamos cómo hacerlo</span>
            </td>
          </tr>
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>En el último año has <b>contactado</b> 2 veces de <b>forma telefónica</b> perdiendo el descuento
                online. Te recomendamos comunicarte a través de nuestra sección <b>"Contrato"</b> y obtener en tu
                próxima factura un x% de descuento.</span></td>
          </tr>
        </table>
      </div>
    </mat-card>

    <mat-card class="cardsContents cardPrevisionGasto">
      <h2 class="tituloCards">Previsión gasto: <b>{{previsionGasto.mes}} {{previsionGasto.anio}}</b></h2>

      <div>
        <h1>Tu previsión del mes que viene es:</h1>
        <p>{{previsionGasto.prevision}}€</p>
        <span>*Datos basados en tu histórco de consumos, la previsión del tiempo y tu estilo de vida.</span>
      </div>
    </mat-card>
  </div>

  <!-- <div>
    <mat-card class="cardsContents cardNews">
      <h2 class="tituloCards">News</h2>

      <div id="cardNews1">
        <p>Precios de la energía</p>
        <span>
          <b>El punto de la situación sobre la tendencia de los precios de la energía y que hacer.</b><br>
          Crisis energética y las causas de la subida de precios la crisis de los precios de la energía es de todos
          conocida, hemos observado subidas nunca antes vistas de la electricidad, el gas, la gasolina...
        </span>
      </div>

      <div id="cardNews2">
        <input type="button" href="#" class="btn btnStyle_2" value="Ver más">
      </div>
    </mat-card>
  </div> -->


  <div>
    <mat-card class="cardsContents footerCard" fxLayout="column">
      <h2 class="tituloCards">Social actions</h2>

      <div id="cardContents1">

        <section>
          <img src="assets/img/iconoArbolArbol.png" width="50px">
          <div class="childFooterCards">
            <h2>
              Adopta un arbol
              <a href="https://wekiwi.es/te-ayudamos/" target="_blank">
                <svg class="info_circle_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497 497">
                  <rect x="110.5" y="78.5" width="282" height="343" />
                  <path
                    d="M256,8C119,8,8,119.08,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm0,110a42,42,0,1,1-42,42A42,42,0,0,1,256,118Zm56,254a12,12,0,0,1-12,12H212a12,12,0,0,1-12-12V348a12,12,0,0,1,12-12h12V272H212a12,12,0,0,1-12-12V236a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12V336h12a12,12,0,0,1,12,12Z"
                    transform="translate(-7.5 -7.5)" />
                </svg>
              </a>
            </h2>
            <p *ngIf="dataSource[0].IsTreedom">
              Gracias por ser parte del cambio <br>
              Dinero destinado: <b>{{dataSource[0].ImporteTreedom}}€ al mes</b>
            </p>
            <div *ngIf="!dataSource[0].IsTreedom">
              <p>
                Adopta tu arbol, por un mundo más verde
              </p>
              <input type="button" [routerLink]="['/transacciones/cambios']" class="btn btnStyle_2" value="Contratar este producto">
            </div>
          </div>
        </section>

        <section>
          <i class="fal fa-recycle"></i>
          <div class="childFooterCards">
            <h2>
              Recogida de plástico
              <a href="https://wekiwi.es/te-ayudamos/" target="_blank">
                <svg class="info_circle_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497 497">
                  <rect x="110.5" y="78.5" width="282" height="343" />
                  <path
                    d="M256,8C119,8,8,119.08,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm0,110a42,42,0,1,1-42,42A42,42,0,0,1,256,118Zm56,254a12,12,0,0,1-12,12H212a12,12,0,0,1-12-12V348a12,12,0,0,1,12-12h12V272H212a12,12,0,0,1-12-12V236a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12V336h12a12,12,0,0,1,12,12Z"
                    transform="translate(-7.5 -7.5)" />
                </svg>
              </a>
            </h2>
            <p *ngIf="dataSource[0].IsPlasticBank">
              Gracias por ayudarnos a ver un planeta más limpio <br>
              Dinero destinado: <b>{{dataSource[0].ImportePlasticBank}}€ al mes</b>
            </p>
            <div *ngIf="!dataSource[0].IsPlasticBank">
              <p>
                Aún estás a tiempo de cambiar el planeta
              </p>
              <input type="button" [routerLink]="['/transacciones/cambios']" class="btn btnStyle_2" value="Contratar este producto">
            </div>
          </div>
        </section>
      </div>

      <!-- <section fxFlex="100">
        <i class="fal fa-globe-americas"></i>
        <div class="childFooterCards">
          <h2>
            Energía verde
            <a href="https://wekiwi.es/te-ayudamos/" target="_blank">
              <svg class="info_circle_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497 497">
                <rect x="110.5" y="78.5" width="282" height="343" />
                <path
                  d="M256,8C119,8,8,119.08,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm0,110a42,42,0,1,1-42,42A42,42,0,0,1,256,118Zm56,254a12,12,0,0,1-12,12H212a12,12,0,0,1-12-12V348a12,12,0,0,1,12-12h12V272H212a12,12,0,0,1-12-12V236a12,12,0,0,1,12-12h64a12,12,0,0,1,12,12V336h12a12,12,0,0,1,12,12Z"
                  transform="translate(-7.5 -7.5)" />
              </svg>
            </a>
          </h2>
          <p *ngIf="dataSource[0].IsEnergiaVerde">
            Gracias por ayudarnos a hacer un planeta más verde.
          </p>
          <div *ngIf="!dataSource[0].IsEnergiaVerde">
            <p>
              Aún estás a tiempo de cambiar el planeta
            </p>
            <input type="button" [routerLink]="['/transacciones/cambios']" class="btn btnStyle_2" value="Contratar este producto">
          </div>
        </div>
      </section> -->
    </mat-card>
  </div>

</div>

<!-- <app-footer></app-footer> -->