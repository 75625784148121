<div>
  <footer>
    <span class="mat-caption " *ngIf="name!=''"> <img src="assets/img/empresa.png" pngalt="Avatar">{{ name }}</span>
    <span class="mat-caption" *ngIf="address!=''"><img src="assets/img/mapa.png" pngalt="Avatar">{{ address }}</span>
    <span class="mat-caption" *ngIf="phone!=''"> <img src="assets/img/telefono.png" pngalt="Avatar">{{ phone }}</span>
    <span class="mat-caption" *ngIf="email!=''"> <img src="assets/img/correo.png" pngalt="Avatar">{{ email }}</span>
    <span class="mat-caption" *ngIf="urlAvisoLegal!=''"><a href="{{ urlAvisoLegal }}" target="_blank"
        title="Consulte nuestro Aviso Legal, Política de Privacidad y Protección de Datos">{{'Aviso legal' |
        translate}}</a></span>
    <span class="mat-caption" *ngIf="urlCookies!=''"><a href="{{ urlCookies }}" target="_blank"
        title="Política de Cookies">{{'Política de' | translate}} Cookies</a></span>
    <span class="mat-caption" *ngIf="urlPagininaInicial!=''"><a href="{{ urlPagininaInicial }}" target="_blank"
        title="Pagina Inicial"> {{'Volver a la página de la comercializadora' | translate}}</a></span>
    <span class="mat-caption" *ngIf="urlUso!=''"><a href="{{ urlUso }}" target="_blank"
        title="Política de Uso">{{'Condiciones de uso' | translate}}</a></span>
    <span class="mat-caption" *ngIf="urlPoliticaPrivacidad!=''"><a href="{{ urlPoliticaPrivacidad}}"
        target="_blank" title="Política de Privacidad">{{'Política de de privacidad' | translate}}</a></span>
  </footer>
</div>