import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contratosFilter'
})
export class ContratosFilterPipe implements PipeTransform {
  transform(value: any, filterString: any, objAux: any) {
  
    return objAux.filter(v => v.CodigoContrato.includes(filterString) || this.normalizarTextos(v.ContratoSituacion).includes(this.normalizarTextos(filterString))
    ||  this.normalizarTextos(v.Entorno).includes(this.normalizarTextos(filterString)) ||  this.normalizarTextos(v.Tarifa).includes(this.normalizarTextos(filterString)) 
    ||  this.normalizarTextos(v.NombreCliente).includes(this.normalizarTextos(filterString)) ||  this.normalizarTextos(v.DireccionSuministro).includes(this.normalizarTextos(filterString)));
  }

  normalizarTextos(texto) {
    texto = texto.toUpperCase();
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); //Normaliza la cadena y elimina todos los acentos
  }
}


