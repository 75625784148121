import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Global } from '../../../interfaces/config/global';
import { environment } from '../../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})

export class GlobalService {

  constructor(private almnService:AlmacenamientoService) {}

  url: any;
  urlOV = environment.urlApi;
  urlCCH = environment.urlCch;
  cryptoKey= environment.cryptoKey;
  cryptoIV= environment.cryptoIV;
  user = environment.user;
  password = environment.password;

  getUrlApi(): string { return (this.almnService.getValue('urlApi')) ? this.almnService.getValue('urlApi') : this.urlOV; }

  getUsuarioApi(): Global { return { Usuario: this.user, Password: this.password }; }

  getUrlApiCch() { return (this.almnService.getValue('urlCCH')) ? this.almnService.getValue('urlCCH') : this.urlCCH; }

  getHttpHeaders(): object {
    const options = { headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: this.almnService.getValue('token')}) };
    // const options = { headers: new HttpHeaders({'Content-Type': 'application/json', Authorization: 'a'}) };
    return options;
  }

  getHttpHeaders2(): object {
    const options = { headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}) };
    return options;
  }
  pruebaasdasdas(): object {
    const options = { 
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': 'eyJGZWNoYUV4cGlyYWNpb24iOiIyMDIzLTEyLTMwVDE0OjM5OjI1Ljk0NTg5NCswMTowMCIsIkZlY2hhQ3JlYWNpb24iOiIyMDIyLTEyLTMwVDEzOjM5OjI1Ljk0MjgxNDErMDE6MDAiLCJVc3VhcmlvREIiOiJNREkyTmpFNE1EWlIiLCJQYXNzd29yZERCIjoiTm5KVU5EazNNalE9In0=', responseType: 'text'}) };
    return options;
  }

  getCryptoKey(): string {
    return (this.almnService.getValue('cryptoKey')) ? this.almnService.getValue('cryptoKey') : this.cryptoKey;
  }

  getCryptoIV(): string {
    return (this.almnService.getValue('cryptoIV')) ? this.almnService.getValue('cryptoIV') : this.cryptoIV;
  }
}
