import { Component, OnInit} from '@angular/core';
import { Contrato } from '../../../interfaces/contrato';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { LoginService } from '../../../services/login/login.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
    selector: 'app-impacto',
    templateUrl: './impacto.component.html',
    styleUrls: ['./impacto.component.scss']
  })
  
  export class ImpactoComponent  implements OnInit {


    contratoSeleccionado: any;
    ultimoContratoActivo: Contrato;
    ultimosContratos: Contrato[] = [];
    ultimosContratosAUX: Contrato[] = [];
    dataSource2: Contrato[] = [{
      CodigoContrato: '',
      Entorno: '',
      TarifaGrupo: '',
      NombreCliente: '',
      DireccionSuministro: ''
    }] ;
    dataSource3: any;
    filtroTabla: any = '';

    constructor(
     private _funcionesService: FuncionesService,
      private _loginService: LoginService,
      private almnService:AlmacenamientoService,
      private _contratosService: ContratosService,
      private router: Router
    ) {
    }

    ngOnInit() {
      var refToThis = this;
      setInterval(function(){
        var respuesta = refToThis._funcionesService.comporbarContratos() as any;
  
        if (respuesta.__zone_symbol__value){
          console.log(respuesta);
          refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
        }
    }, 90000)
    
      setTimeout(() => { this.comprobarDatosContratos(); }, 800);
    }

    comprobarDatosContratos() {
      console.log('IMPACTO CONTROLLER');

      if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
        this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")))
      } else {
        this.cargarListadoContratos();
      }      
    }

    filtraListadoContratos(contrato){
      this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { console.log(); return cont.IdContrato === contrato })[0];
      this.dataSource2[0] = this.ultimoContratoActivo;
      this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));
      // TODO lanzar funcion recuperacion datos toda la pagina
    }

    irDetallesContrato() {
      this.almnService.setValue('mostrarContrato', 'true');
      this.router.navigate(['/informacion/contratos']);
    }

    listarContratos(auxContratos) {
      // Listado contratos
      this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
      this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
      // Recuperacion contrato Activo si está en sesión
      // this.dataSource3 = new MatTableDataSource<Contrato>(this.ultimosContratos);
  
      var aux = this.almnService.getValue("ContratoVisible");
      this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
      this.filtraListadoContratos(this.contratoSeleccionado.IdContrato);
    }

    cargarListadoContratos() {
      this._contratosService
        .getListadoContratos(this.almnService.getValue('idCliente'))
        .subscribe(async data => {
          this.almnService.setValue("listadoContratos", JSON.stringify(data))
          this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
          this.listarContratos(data);
        }, (err) => {
          this._funcionesService.controlStatusCallBacks(err, 'contratos');
        }
      );
    }
  

  }
