import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { LoginService } from '../../../services/login/login.service';
import { ResumenService } from '../../../services/resumen/resumen.service';
import { Contrato } from '../../../interfaces/contrato';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DetallesDialogComponent } from '../../dialogs/detalles-dialog/detalles-dialog';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})

export class ResumenComponent implements OnInit {
  //Variables Cliente 
  TipoCliente: string;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;

  //Variables Contratos
  dataSource: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];
  contratoSeleccionado: any;
  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  Entorno: any;
  isProductoPrecarga: any = true;

  //Variables Gráficos
  Highcharts = Highcharts;
  options: any;
  oneToOneFlag: boolean;
  updateFlag: boolean;
  
  datosConsumoHistorico: any = [];
  datosImporteHistorico: any = [];
  datosRecargaHistorico: any = [];
  datosConsumoKilovatios: any = [];
  charHistoricoConsumo: any = {};
  fechaGrafico: any = new Date().getFullYear();

  //Variables Generales
  tokenvalidado: any;
  NombreEmpresa = environment.companyName;
  listadoYears: any = [];
  fechaHoy: any = moment().format('YYYY-MM-DD HH:mm:ss').toString(); // Hoy
  fechaUnAño: any = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString(); // Un año
  listadoDeMeses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  previsionGasto: any = {
    anio: 11 == new Date().getMonth() ? new Date().getFullYear() + 1 : new Date().getFullYear(),
    mes: this.listadoDeMeses[new Date().getMonth() + 1],
    prevision: 0
  }

  estimacionConsumo: any = {
    recargaActual: 0,
    periodicidadActual: '',
    porcentajeConsumo: 0,
    porcentajeRecarga: 0,
    porcentajeDiffConsumo: 0,
    precioConsumo: 0,
    consumoTotal: 0,
    anio: new Date().getUTCFullYear(),
    mes: this.listadoDeMeses[new Date().getMonth()]
  };

  consumoActual: any = {
    consumoUltimoMes: 0,
    consumoMedioTotal: 0,
    consumoMedioVerano: 0,
    consumoMedioInvierno: 0
  }

  tusDescuentosTarifaGrupo: any = [];
  descuentoOnline: any;
  filtroTabla: any = '';
  mostrarAvisos: Boolean = environment.mostrarAvisos;
  mostrarConsejos: Boolean = environment.mostrarConsejos;
  
  constructor(
    private router: Router,
    private _facturasService: FacturasService,
    public dialog: MatDialog,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    private almnService:AlmacenamientoService,
    private _contratosService: ContratosService,
    private _resumenService: ResumenService,
    private _GraficosService: GraficosService
  ) {  }

  // @HostListener('window:load', ['$event'])
  // onLoad(event) {
  //  sessionStorage.clear();
  //  setTimeout(() => {
  //   this.cargarListadoContratos();
  //  }, 4);
  // }

  isNumber(value) {
    return !Number.isNaN(Number(value));
  }

  saveInstance: any;

  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;
      if (respuesta.__zone_symbol__value) {
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);

    this.updateFlag = this.oneToOneFlag = false;
  }

  comprobarDatosContratos() {

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
    } else {
      this.cargarListadoContratos();
    }      
  }    

  cargarListadoContratos() {
    this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
      async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data))
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
       this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    // Listado contratos
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    // console.log( this.ultimosContratos);
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    // if(ajuste){sessionStorage.clear()}
    if(ajuste){this.almnService.clear('soft')}
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    // this.buscarCups(this.ultimoContratoActivo); //
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;

    this.estimacionConsumo.recargaActual = this.ultimoContratoActivo.RecargaActual;
    this.estimacionConsumo.periodicidadActual = this.ultimoContratoActivo.Periodicidad;
    this.dataSource[0] = this.ultimoContratoActivo;
    if(this.isProductoPrecarga) {
      this.TarifaGrupo = 'recarga';
    } else if(this.dataSource[0]['TarifaGrupo'].toUpperCase().includes('FIJO')) {
      this.TarifaGrupo = 'fijo';
    } else {
      this.TarifaGrupo = 'indexada';
    }
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));
    this.fechaGrafico = new Date().getFullYear();

    this._contratosService.getDescuentosOnline(this.ultimoContratoActivo, new Date()).subscribe(
      data=> {
        this.descuentoOnline = this._funcionesService.decodificarToken(data);
      }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'descuentos');
    })

    this.listadoYears = [];
    for (let year = new Date().getFullYear(); year >= new Date(this.dataSource[0].FechaAlta).getFullYear(); year--) {
      this.listadoYears.push(year);
    }
    this.listadoYears.reverse();

    if(this.almnService.getValue("historico")) {
      var historico = JSON.parse(atob(this.almnService.getValue('historico')));
      this.datosImporteHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoImporte));
      this.datosRecargaHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoRecarga));
      this.datosConsumoHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoConsumo));
      this.datosConsumoKilovatios = this._funcionesService.decodificarToken(JSON.parse(historico.historicoKilovatios));
      this.fechaGrafico = historico.anioGrafico;

      
      this.cargarGraficos();
    } else {
      this.consumoMasPrecio(this.fechaGrafico, 'historico');
    }

    if(this.almnService.getValue("recargaEnHistorico")) {
      var recargaEnHistorico = JSON.parse(atob(this.almnService.getValue('recargaEnHistorico')));
      this.consumoActual = this._funcionesService.decodificarToken(JSON.parse(recargaEnHistorico.consumoActual));
    } else if(this.fechaGrafico != new Date().getFullYear()) { 
      this.consumoMasPrecio(new Date().getFullYear(), 'recargaEnHistorico');
    }

    this.cargarDescuentos();
    // TODO lanzar funcion recuperacián datos toda la página
  }

  cargarEstimacion(precios) {
    this._contratosService.getCalculoEstimacion(this.dataSource[0].CodigoContrato, this.dataSource[0]['EntornoBD']).subscribe(
      async data => {
        var consumos = this._funcionesService.decodificarToken(data);
        // console.log(consumos);

        var fecha = new Date(consumos.FechaFin).getTime() - new Date(consumos.FechaInicio).getTime();
        fecha = Math.ceil(fecha / (1000 * 3600 * 24));

        var listadoNomPotencias = ['P1','P2','P3','P4','P5','P6'];
        var calculoPrecioPotencia = 0;
        var calculoPrecioEnergia = 0;
        var descuentoTotal = 0;

        if(this.tusDescuentosTarifaGrupo.length > 0) {
          this.tusDescuentosTarifaGrupo.forEach(element => {
            descuentoTotal += Math.abs(element.CargoDescuentoEnergia);
          });
          
          descuentoTotal = (100 - descuentoTotal) / 100;          
        }

        if(fecha > 0){
          precios.forEach((element, key) => {
            calculoPrecioPotencia += (this.dataSource[0][listadoNomPotencias[key]] * element.PrecioP) * fecha;
            calculoPrecioEnergia += (descuentoTotal * element.PrecioE) * consumos.Consumos[key + 1];
          });
        }
      
        var calculoFinal = Math.round(calculoPrecioPotencia + calculoPrecioEnergia);
        
        if(this.isProductoPrecarga) {
          const valorMax = calculoFinal > this.dataSource[0].RecargaActual ? calculoFinal + (calculoFinal * 0.1) : this.dataSource[0].RecargaActual + (this.dataSource[0].RecargaActual * 0.1);

          this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
          this.estimacionConsumo.porcentajeRecarga = Math.round((parseInt(this.dataSource[0].RecargaActual as any) * 100) / valorMax);
          this.estimacionConsumo.porcentajeDiffConsumo = this.dataSource[0].RecargaActual ? parseInt(calculoFinal as any) * 100 / (parseInt(this.dataSource[0].RecargaActual as any)) : 'No data found';

          
          this.estimacionConsumo.porcentajeDiffConsumo = Math.trunc(this.estimacionConsumo.porcentajeDiffConsumo);
          // this.estimacionConsumo.porcentajeDiffConsumo = 120;
          
          // console.log(calculoFinal);
          // console.log(this.estimacionConsumo);
        } else {
          if('fijo' == this.TarifaGrupo) {
            this.estimacionConsumo.precioConsumo = calculoFinal;
          }

          if('indexada' == this.TarifaGrupo) {
            consumos.Consumos.forEach(element => {
              this.estimacionConsumo.consumoTotal += element;
            });
          }

          const valorMax = calculoFinal + (calculoFinal * 0.1);
          this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'los consumos');
      });
  }

  cargarPrevision(precios) {
    if('indexada' != this.TarifaGrupo) {
      this._contratosService.getCalculoPrevision(this.dataSource[0].CodigoContrato, this.dataSource[0]['EntornoBD']).subscribe(
        async data => {
          var consumos = this._funcionesService.decodificarToken(data);
          // console.log(consumos);
  
          var fecha = new Date(consumos.FechaFin).getTime() - new Date(consumos.FechaInicio).getTime();
          fecha = Math.ceil(fecha / (1000 * 3600 * 24));
  
          var listadoNomPotencias = ['P1','P2','P3','P4','P5','P6'];
          var calculoPrecioPotencia = 0;
          var calculoPrecioEnergia = 0;
          var descuentoTotal = 0;

          if(this.tusDescuentosTarifaGrupo.length > 0) {
            this.tusDescuentosTarifaGrupo.forEach(element => {
              descuentoTotal += Math.abs(element.CargoDescuentoEnergia);
            });

            descuentoTotal = (100 - descuentoTotal) / 100;
          }

          if(fecha > 0){
            precios.forEach((element, key) => {
              calculoPrecioPotencia += (this.dataSource[0][listadoNomPotencias[key]] * element.PrecioP) * fecha;
              calculoPrecioEnergia += (descuentoTotal * element.PrecioE) * consumos.Consumos[key + 1];
            });
          }

          this.previsionGasto.prevision = Math.trunc(calculoPrecioPotencia + calculoPrecioEnergia);

          // console.log("PREVISION-> ", this.previsionGasto);
        }, (err) => {
          this._funcionesService.controlStatusCallBacks(err, 'los consumos');
        });
    } else {
      this.previsionGasto.prevision = 0;
    }
  }

  cargarDescuentos() {
    const numPeriodicidad = this.dataSource[0].IsPrecarga ? 'MENSUAL' == (this.dataSource[0].Periodicidad || "").toUpperCase() ? 1 : 'BIMENSUAL' == (this.dataSource[0].Periodicidad || "").toUpperCase() ? 2 : 3 : 0;

    this._contratosService.getDescuentosTarifaGrupo(this.dataSource[0].IdTarifaGrupo, numPeriodicidad).subscribe(
      async data => {
        var descuentos = this._funcionesService.decodificarToken(data);
        // console.log(descuentos)
        descuentos = this._contratosService.recalcularDescuentos(descuentos);
        if(descuentos.length != 0) {
          this.tusDescuentosTarifaGrupo = descuentos.sort((a,b) => (a.TipoCargoDescuento < b.TipoCargoDescuento) ? 1 : ((b.TipoCargoDescuento < a.TipoCargoDescuento) ? -1 : 0));
        } else {
          this.tusDescuentosTarifaGrupo = []
        }
        
        this._contratosService.getPreciosContrato(this.dataSource[0].CodigoContrato, this.dataSource[0]['EntornoBD']).subscribe(
          async data => {
            var precios = this._funcionesService.decodificarToken(data);
            // console.log("Precios-> ", precios);
            this.cargarEstimacion(precios);
            this.cargarPrevision(precios);
        }, (err) => {
          
          this._funcionesService.controlStatusCallBacks(err, 'la estimación');
        })
        
    }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'tus descuentos');
    })
  }

  consumoYrecarga(importe, recarga, consumo) {

    recarga.forEach((element, key) => {
      var fecha = new Date(element['Fecha']).getMonth();

      if(new Date(element['Fecha']).getMonth() == new Date().getMonth()) {

        element.Fecha =  new Date(element.Fecha);
        // this.recargaActual.consumo = this.datosImporteHistorico[new Date(element.Fecha).getMonth()]
        
        // switch(new Date().getMonth()) {
        //   case element.Fecha.getMonth():
        //     this.recargaActual.diasCurso = new Date().getDate();
        //   break
        //   case (element.Fecha.getMonth() + 1):
        //     this.recargaActual.diasCurso = 30 + element.Fecha.getDate();
        //   break
        //   case (element.Fecha.getMonth() + 2):
        //     this.recargaActual.diasCurso = 60 + element.Fecha.getDate();
        //   break
        // }
      }
    });
    
    var totalImp = 0, veranoImp = 0, inviernoImp = 0;
    var totalCon = 0, veranoCon = 0, inviernCon = 0;

    this.datosImporteHistorico.forEach((element, key) => {
      totalImp += element;

      if(key == 5 || key == 6 || key == 7 || key == 8) { //Calculo importe medio
        veranoImp += element;
      }      

      if(key == 11 || key == 0 || key == 1 || key == 2) {
        inviernoImp += element;
      } 
    });

    this.datosConsumoHistorico.forEach((element, key) => { //Calculo consumo medio
      totalCon += element;

      if(key == 5 || key == 6 || key == 7 || key == 8) {
        veranoCon += element;
      }      

      if(key == 11 || key == 0 || key == 1 || key == 2) {
        inviernCon += element;
      } 
    });

    this.consumoActual.consumoUltimoMes = this.datosImporteHistorico[new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1].toFixed(2) + "€-" + this.datosConsumoHistorico[new Date().getMonth()] + 'kWh';
    this.consumoActual.consumoMedioTotal = (totalImp / this.datosImporteHistorico.length).toFixed(2) + '€-' + (totalCon / this.datosConsumoHistorico.length).toFixed(0) + 'kWh';
    this.consumoActual.consumoMedioVerano = (veranoImp / 4).toFixed(2) + '€-' + (veranoCon / 4) + 'kWh';
    this.consumoActual.consumoMedioInvierno = (inviernoImp / 4).toFixed(2) + '€-' + (inviernCon / 4) + 'kWh';

    var listado2 = {
      'consumoActual': this._funcionesService.codificarDatos(this.consumoActual),
    }

    this.almnService.setValue('recargaEnHistorico', btoa(JSON.stringify(listado2)))
  }

  consumoMasPrecio(fechaAnio, texto) {
    var anioSeleccionado = moment(new Date('01/01/' + fechaAnio)).format('YYYY-MM-DD HH:mm:ss').toString();
    var contrato = JSON.parse(atob(this.almnService.getValue("ContratoVisible")));
    var importe = [], consumo = [], recarga = [];
    var contadorCalls = 0;

    this._facturasService.getImportes(parseInt(contrato.IdCups),fechaAnio + '-01-01', fechaAnio + '-12-31', contrato.IdContrato, contrato.IdCliente).subscribe(
      async datosImporte => {
        importe = this._funcionesService.decodificarToken(datosImporte);

        if(contadorCalls == 2) {
          if(texto == 'historico') {

            this.calculoDatosGrafico(importe, recarga, consumo);
            // console.log("IMPORTE", importe, recarga, consumo);
            if(!this.almnService.getValue("recargaEnHistorico")){this.consumoYrecarga(importe, recarga, consumo)}
          } else {
            this.consumoYrecarga(importe, recarga, consumo)
          }
        } else {
          contadorCalls++;
        }
    }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'importe');
    })
    
    this._facturasService.getConsumos(parseInt(contrato.IdCups),fechaAnio + '-01-01', fechaAnio + '-12-31', contrato.IdContrato, contrato.IdCliente).subscribe(
      async datosConsumo => {
        
        consumo = this._funcionesService.decodificarToken(datosConsumo);
        if(contadorCalls == 2) {
          if(texto == 'historico') {
            this.calculoDatosGrafico(importe, recarga, consumo);
            // console.log("HISTORICO", importe, recarga, consumo);
            if(!this.almnService.getValue("recargaEnHistorico")){this.consumoYrecarga(importe, recarga, consumo)}
          } else {
            this.consumoYrecarga(importe, recarga, consumo)
          }
        } else {
          contadorCalls++;
        }
    }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'consumo');
    })

    this._facturasService.getRecarga(contrato.Entorno == 'Luz' ? 'E1' : 'E2', anioSeleccionado ,contrato.IdContrato, contrato.IdCliente).subscribe(
        async datosRecarga => {
        recarga = this._funcionesService.decodificarToken(datosRecarga);
            
        if(contadorCalls == 2) {
          if(texto == 'historico') {
            
            this.calculoDatosGrafico(importe, recarga, consumo);
            // console.log("RECARGA", importe, recarga, consumo);
            if(!this.almnService.getValue("recargaEnHistorico")){this.consumoYrecarga(importe, recarga, consumo)}
          } else {
            this.consumoYrecarga(importe, recarga, consumo)
          }
        } else {
          contadorCalls++;
        }
    }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'recarga');
    })
  }

  calculoDatosGrafico(importe, recarga, consumo){
    this.datosConsumoHistorico = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.datosImporteHistorico = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.datosRecargaHistorico = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.datosConsumoKilovatios = [0,0,0,0,0,0,0,0,0,0,0,0];

    // console.log("importe:", importe);
    // console.log("recarga:", recarga);
    // console.log("consumo:", consumo);

    if(consumo) {
      consumo.forEach((element, key) => {
        this.datosConsumoHistorico[new Date(element['FechaFactura']).getMonth()] = element.ConsumoActivaTotal;
      });
    }

    if(importe) {
      importe.forEach((element, key) => {
        this.datosImporteHistorico[new Date(element['FechaFactura']).getMonth()] = element.Importe;
      });
    }

    if(recarga) {
      recarga.forEach((element, key) => {
        var fecha = new Date(element['Fecha']).getMonth();
        this.datosRecargaHistorico[fecha] = element.Cuota;
        this.datosConsumoKilovatios[fecha] = element.ValorMaximo;
      });
    }

    var listado = {
      'historicoImporte': this._funcionesService.codificarDatos(this.datosImporteHistorico),
      'historicoRecarga': this._funcionesService.codificarDatos(this.datosRecargaHistorico),
      'historicoConsumo': this._funcionesService.codificarDatos(this.datosConsumoHistorico),
      'historicoKilovatios': this._funcionesService.codificarDatos(this.datosConsumoKilovatios), 
      'anioGrafico': this.fechaGrafico,
    }
    
    this.almnService.setValue('historico', btoa(JSON.stringify(listado)))

    this.cargarGraficos();
  }

  cargarGraficos() {
    // this.options = JSON.parse(this._GraficosService.graficosOption);
    var preservThis = this;
    if(this.dataSource[0].IsPrecarga){
      const valorMasAlto = this.datosConsumoHistorico.concat(this.datosConsumoKilovatios);
      this.charHistoricoConsumo = {
        lang: this.options,
        chart: {type: 'column'},
        saveInstance(chartInstance): void { this.chart = chartInstance; },
        legend: {align: 'left', verticalAlign: 'bottom',layout: 'horizontal', x: 0, y: 0,itemMarginTop: 10, itemMarginBottom: 0, },
        navigation: {buttonOptions: {enabled:false}},
        title: {text: ''},
        xAxis: {categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],crosshair: false},
        yAxis: {min: 0,max: Math.max(...valorMasAlto),tickAmount: 4,title: {text: 'kWh'}},
        tooltip: {useHTML: true,shared: true,backgroundColor: '#ffffff00',borderWidth: 0,shadow: false,style: {padding: 0},
          formatter: function (x) {
            if(1 == this.points.length) {
              var htmlLabel = '';
              switch (this.points[0].color) {
                case '#fff523':
                  htmlLabel = '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
                    '<tr><td style="font-size:15px;padding: 9px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;' +
                    'border-radius: 50%; margin-right: 10px;"></div> Consumo </td><td style="font-size:1.07rem; padding: 6px;">' + 
                    '<ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr></table>'
                  break;
                case '#6f49ce':
                  htmlLabel = '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
                    '<tr><td style="font-size:15px;padding: 9px; display: flex; align-items: center;"><div style="background-color:#6f49ce;width: 20px; height: 20px;' +
                    'border-radius: 50%;margin-right: 10px;"></div> Recarga </td><td style="font-size:1.07rem; padding: 6px;">' + 
                    preservThis.datosRecargaHistorico[this.points[0].point.index] + '€</td></tr></table>'
                  break;
              }
              return htmlLabel;
            } else {
              return '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
              '<tr><td style="font-size:15px;padding:6px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;' +
              'border-radius: 50%; margin-right: 10px;"></div> Consumo </td><td style="font-size:1.07rem; padding:6px;"><ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr>' + 
              '<tr><td style="font-size:15px;padding:9px; display: flex; align-items: center;"><div style="background-color:#6f49ce;width: 20px; height: 20px;' +
              'border-radius: 50%;margin-right: 10px;"></div> Recarga </td><td style="font-size:1.07rem; padding:6px;">' + preservThis.datosRecargaHistorico[this.points[0].point.index] + '€</td></tr>' + 
              '</table>'
            }
          }
        },
        plotOptions: {column: { pointPadding: 0,borderWidth: 0,borderRadius: 20}},
        series: [{
          name: 'Consumo',
          data: this.datosConsumoHistorico,
          color: '#fff523'
        }, {
          name: 'Recarga',
          data: this.datosConsumoKilovatios,
          color: '#6f49ce'
        }]
      }
    } else {
      this.charHistoricoConsumo = {
        lang: this.options,
        chart: {type: 'column'},
        saveInstance(chartInstance): void { this.chart = chartInstance; },
        legend: {align: 'left', verticalAlign: 'bottom',layout: 'horizontal', x: 0, y: 0,itemMarginTop: 10, itemMarginBottom: 0, },
        navigation: {buttonOptions: {enabled:false}},
        title: {text: ''},
        xAxis: {categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],crosshair: false},
        yAxis: {min: 0,max: this.getMaxYAxis(Math.max(...this.datosConsumoHistorico)),tickAmount: 4,title: {text: 'kWh'}},
        tooltip: {useHTML: true,shared: true,backgroundColor: '#ffffff00',borderWidth: 0,shadow: false,style: {padding: 0},
          formatter: function (x) {
              return '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
              '<tr><td style="font-size:15px; padding: 6px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;border-radius: 50%; margin-right: 10px;">' + 
              '</div> Consumo </td><td style="font-size:1.07rem; padding: 6px;"><ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr></table>'
            }
        },
        plotOptions: {column: { pointPadding: 0,borderWidth: 0,borderRadius: 20}},
        series: [{
          name: 'Consumo',
          data: this.datosConsumoHistorico,
          color: '#fff523'
        }]
      }
    }

    this.oneToOneFlag = this.updateFlag = true;
  }

  getMaxYAxis(value) {
    return value;
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  buscarCups(contrato) {
    this._contratosService.getDatosCups(contrato.IdCups).subscribe(
      async datosCupsMultipunto => {
        var cups = this._funcionesService.decodificarToken(datosCupsMultipunto);
        console.log(cups);
      }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'cups');
     });
  }

  verDialogInfo(type) {
    let titulo = '';
    let texto = '';
    switch (type) {
      case 'tarifa':
        texto = "Descuento Recarga: Disfruta de un descuento de forma indefinida por contratar la tarifa de recarga ¡Se te aplicará directamente a todas tus facturas! Así es, hacer las cosas al revés y rebelarte hacia el cambio tiene sus ventajas.";
        titulo = "Descuento Recarga";
        break;

      case 'online':
        texto = "Si nos contactas SOLAMENTE por email o por el chat del área privada, recibirás este descuento. ¿Por qué? De esta forma podremos ser más rápidos y productivos, beneficiándote así de una asistencia más veloz. Esto no quiere decir que no estemos disponibles por teléfono. Siempre que lo necesitas, podrás llamarnos y te atenderemos con una sonrisa.";
        titulo = "Descuento Online";
        break;

      case 'acierta':
        texto = "Ser consciente de nuestro gasto y tener conciencia con el medio ambiente también se premia en Wekiwi. Si tienes la tarifa Ricardo y tu recarga se corresponde o es inferior a la factura final, recibirás un descuento en dicha factura. Recuerda que con Wekiwi, tú tienes el control de tu gasto desde el inicio.";
        titulo = "Descuento Acierta";
        break;
    }

    this.dialog.open(DetallesDialogComponent, {
      width: '400px',
      data: {
        texto: texto,
        titulo: titulo,
        isPrecarga: this.isProductoPrecarga
      }
    });
  }
}
