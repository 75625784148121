<div class="pageContent">
  <div class="progress-bar"></div>
  <button class="back-to-top hidden" (click)="goToTop()" title="Volver al inicio">
    <svg xmlns="http://www.w3.org/2000/svg" class="back-to-top-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M7 11l5-5m0 0l5 5m-5-5v12" />
    </svg>
  </button>
  <div class="progress-bar"></div>
  <div class="menuTop">
    <h2>Bienvenid@, {{nombreCliente}} <img src="assets/img/stars.png" width="30px"></h2>
    <h2><b>Aquí puedes ver todo lo relacionado con tu cuenta</b></h2>
  </div>
</div>