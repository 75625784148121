import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { environment } from 'src/environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';


@Injectable({
  providedIn: 'root'
})

export class MailService {

  token: string;
  urlApi: string;
  bodyApiToken: any;
  _URL_: string;
  urlPhp:string


  constructor(
    private http: HttpClient,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService) {
    this._URL_ = environment._URL_;
    this.urlPhp = environment.urlPhpControllers;
    this.urlApi = this._globalService.getUrlApi();
    this.token = this.almnService.getValue('token');
  }

  getEnvioEmail(body): Observable<any> {

    // Para el response en json 
    // const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'text'};

    // Para response en texto plano
    const options: Object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };

    return this.http.post<any>( this._URL_ + '/libs/envio.php', body, options);
    // return this.http.post<any>('http://192.168.2.17/desarrollo/test/envio.php', body, this._globalService.getHttpHeaders());
  }

  sendMail(destinatario, obj, interno): Observable<any> {
    const data = {
      email: destinatario,   
      Identidad: obj.Identidad,
      nombre: this.almnService.getValue('nombreCliente'),
      codContrato: obj.codContrato,
      codCups: obj.codCups,
      mensaje: obj.mensaje,
      interno: interno,
      ventana: obj.ventana
    }
    var url = window.location.href.includes('localhost') ? 'http://93.92.233.239/wekiwi/ovtest/libs/mailController.php' : 'libs/mailController.php';
    // var url ='http://93.92.233.239/wekiwi/ovtest/libs/mailController.php';
    return this.http.post(url, data, { responseType: 'text'}) 
  }
}


