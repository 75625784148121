export const environment = {
  production: true,
  messageLogin: false,
  isTcurvas: true,
  user: 'MQ==',
  password: 'MQ==',
  urlApi: 'https://apiovwekiwi.sigeenergia.com/APIOV/',
  urlCch: 'https://apiovwekiwi.sigeenergia.com/APIOVTM/',
  // urlApi: 'https://apiovwekiwitest.portalswitching.com/APIOV/',
  // urlCch: 'https://apiovwekiwitest.portalswitching.com/APIOVTM/',
  cryptoKey: 'TVPNPMN48e6MVeWcvsSmrZJAMbXabRPz',
  cryptoIV: 'W9DurFjh85EZHhLt',
  companyName: 'Wekiwi S.L',
  companyAddress: 'C/ María de Molina 41, 28006 de Madrid',
  companyEmail: 'clientes@wekiwi.es',
  companyPhone: '900525748',
  urlAvisoLegal:'',
  urlCookies: 'https://wekiwi.es/politicas-cookies/',
  urlUso: 'https://wekiwi.es/politica-de-uso/',
  urlPoliticaPrivacidad: "",
  UserAccess: 'DNI/CIF/NIF/EMAIL',
  TextoInicioSesion: 'INICIO DE SESIÓN',
  HabilitarTPV: false,
  iconoresumennuevalectura: 'fab fa-searchengin fa-4x',
  iconoresumendatospago: 'far fa-credit-card-blank fa-4x',
  iconoresumenconsulta: 'far fa-exclamation-square fa-4x',
  alinearmenusuperior: 'izquierda', // derecha izquierda o centro
  MostrarConsumosPorHora: false,
  idiomas: false,
  email: 'clientes@wekiwi.es',// email al que llegaran todos los formularios de la oficina virtual | clientes@elekluz.com
  comparativa: true, //Gráfico de resumen comparativa importes facturas
  historico: false, //Gráfico de resumen histórico importes facturas
  DisabledBotonModificarDatos:true,//Botón modificardatos
  DisabledBotonRealizarConsulta:false,//Botón realizar consulta
  DisabledBotonNuevaLectura:true, //Botón realizar nueva lectura
  MostrarTarifaGrupo:true,
  MostrarPerfilFacturacion:true,
  MostrarFacturaExcel:false,
  RegistrarAceptacionDeUso: false,
  VersionCondicionesUso: "V1",
  MostrarPreciosContrato: false,
  MostrarPerfilContratoSoloUnaPotenciaDHA: false,
  TiempoEsperaGraficos: '500',
  agrupacontratoscups: false,
  MostrarDescargaMasivaFacturasPorCups: true,
  internalApiUrl: '',
  mesesgraficocch: 6,
  maxCUPSgraficoCCH: 3,
  cchWitchCups: false,
  CambiaTituloCChGraph: false,
  DialogFacturasPendientePago: true,
  MostrarBotonFacturasImpagadas: true,
  MostrarBotonModificiacionDatosPerfil: true,
  MostrarDeuda: true,
  PrefijoNumeracionFacturas: '',
PrefijoNumeracionContratos: '',
  GraficoConecta2: false,
  MostrarReactivaComparativaConsumos : true,
  OcultaTipoContacto1: '',
  UltimasFacturasResumen: null,
  DobleGraficoConsumosPorHora: false,
  mostrarFechaVencimientoFactura: true,
  mostrarLimitePagoFactura: false,
  _URL_ : "http://188.210.216.60/wekiwi/ov/",
  messageRecuperarPass: false,
  MostrarAdministradorListadoContratoFechaAlta: false,
  MostrarAdministradorListadoContratoFechaVto: false,
  MostrarAdministradorListadoContratoFechaBaja: false,
  MostrarAdministradorListadoContratoEntorno: false,
  MostrarListadoContratoDireccionSuministro:true,
  MostrarListadoContratoDenominacionCliente: true,
  MostrarConsumosPorHoraClientes: false,
  MostrarPerfilContratoColectivo: false,
  UnirPerfilDatosEnvioYContacto: true,
  MostrarDatosContratoEnPerfil: true,
  marca: '',
  ApiCallContratosListaElekluz: true, // Trae el listado de contratos campos extra, tarifa, titular ...
  MostrarDescargaMasivaFacturasBotonExcel: false,
  MostrarDescargaMasivaFacturasFiltroCif: true, // oculta o muestra el input para filtrar por el importe
  MostrarDescargaMasivaFacturasFiltroImporte: true, // oculta o muestra el input para filtrar por el importe
  MostrarDescargaMasivaFacturasFiltroTarifa: true, // oculta o muestra el input para filtrar por la tarifa
  MostrarResumenGraficosDeMultipuntoAAdministradorFincas: false,
  defaultLang:'es',
  OcultarFacturasConecta2DeAntesDel31x11x19: false,
  MostrarListadoContratoBotonGraficos: true,
  CambiarTitularElekluz:true,
  BotonElekluz: false,
  OcultarContactosEleia: false,
  SimplificacionSerieFactura: false,
  urlPagininaInicial: "",
  MensajeCCHConExplicacion: true,
  peticionAPIs: [],
  multiapi: false,
  EleiaNombreAdministradorFincas: false,
  MostrarRefCatastral: false,
  MostrarCierreSesion: true,
  DesarrolloCatgas: false,
  logindirectoenB64:true,
  MostrarCambioPass:true,
  DesarrolloBonarea: false,
  eneluzEspec: false,
  TextoLegal: "",
  urlPhpControllers: "http://188.210.216.60/wekiwi/ov/libs/",
  mostrarAvisos: false,
  mostrarConsejos: false
};
