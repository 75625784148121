import { Component, OnInit } from '@angular/core';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { NgModel, FormControl } from '@angular/forms';
import { Contrato } from '../../../interfaces/contrato';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import * as moment from 'moment';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { FacturasService } from 'src/app/services/facturas/facturas.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})

export class HistoricoComponent implements OnInit {

  DatosCupsMultipunto: any;
  CupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  CodigoContrato: any;
  cupsFiltered: string[];
  arrayCups: any[] = [];
  isProductoPrecarga: any = true;

  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  monopunto: boolean;
  contratoSeleccionado: Contrato;
  dataSource2: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];

  charHistoricoConsumo: any = {};
  options: any;
  oneToOneFlag: boolean;
  updateFlag: boolean;
  saveInstance: any;

  Highcharts = Highcharts;
  anioFiltradoConsumo: any = new Date().getFullYear();;
  anioFiltradoDescuento: any;
  listadoYears: any[] = [];

  selectedCups = new FormControl();
  datosConsumoHistorico: any = [];
  datosImporteHistorico: any = [];
  datosRecargaHistorico: any = [];
  datosConsumoKilovatios: any = [];
  recargaActual: any = {
    recarga: 0,
    periodo: '',
    numPeriocidad: 0,
    diasCurso: 0,
    consumo: 0
  };

  consumoActual: any = {
    consumoUltimoMes: 0,
    consumoMedioTotal: 0,
    consumoMedioVerano: 0,
    consumoMedioInvierno: 0
  }

  tusDescuentosTarifaGrupo: any = [];

  arrayDeMeses: any = ['', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  tusDescuentosHistorico: any = [{
    acierta: ['Acierta'],
  }, {
    online: ['Online'],
  }, {
    tarifa: ['Tarifa'],
  }, {
    mes: [''],
  }, {
    ahorro: ['Ahorro']
  }];
  listadoDeMeses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  estimacionConsumo: any = {
    recargaActual: 0,
    periodicidadActual: '',
    porcentajeConsumo: 0,
    porcentajeRecarga: 0,
    porcentajeDiffConsumo: 0,
    precioConsumo: 0,
    consumoTotal: 0,
    anio: new Date().getUTCFullYear(),
    mes: this.listadoDeMeses[new Date().getMonth()]
  };
  listadoDescuentoContrato: any = [];
  isLoadingHistoricoConsumo: Boolean = false;
  descuentoOnline: any;
  filtroTabla: any = '';
  mostrarAvisos: Boolean = environment.mostrarAvisos;
  mostrarConsejos: Boolean = environment.mostrarConsejos;

  constructor(
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
    private _GraficosService: GraficosService,
    private almnService:AlmacenamientoService,
    private _facturasService: FacturasService,
    private router: Router
  ) {
  }

  ngOnInit() {

    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        console.log(respuesta);
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);

    this.updateFlag = this.oneToOneFlag = false;
  }

  comprobarDatosContratos() {

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
    } else {
      this.cargarListadoContratos();
    }
  }

  cargarListadoContratos() {
    this._contratosService.getListadoContratos(this.almnService.getValue('idCliente')).subscribe(
      async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data));
        this.almnService.setValue('refrescoContratos', new Date().getTime().toString());
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      }
    );
  }

  listarContratos(auxContratos) {
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    // Recuperacion contrato Activo si está en sesión
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    // if (ajuste) { sessionStorage.clear() }
    if (ajuste) { this.almnService.clear('soft') }
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;
    this.estimacionConsumo.recargaActual = this.ultimoContratoActivo.RecargaActual;
    this.estimacionConsumo.periodicidadActual = this.ultimoContratoActivo.Periodicidad;
    this.recargaActual.periodicidad = this.ultimoContratoActivo.Periodicidad;
    // TODO
    this.recargaActual.numPeriocidad = 'MENSUAL' == (this.ultimoContratoActivo.Periodicidad || "").toUpperCase() ? 1 : 'BIMENSUAL' == (this.ultimoContratoActivo.Periodicidad || "").toUpperCase() ? 2 : 3;
    console.log(this.recargaActual.numPeriocidad);
    
    this.dataSource2[0] = this.ultimoContratoActivo;
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));

    this._contratosService.getDescuentosOnline(this.ultimoContratoActivo, new Date()).subscribe(
      data => {
        this.descuentoOnline = this._funcionesService.decodificarToken(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'descuentos');
      })
    
      this.listadoYears = [];
    for (let year = new Date().getFullYear(); year >= new Date(this.dataSource2[0].FechaAlta).getFullYear(); year--) {
      this.listadoYears.push(year);
    }
    this.listadoYears.reverse();

    if (this.almnService.getValue("historico")) {
      var historico = JSON.parse(atob(this.almnService.getValue('historico')));
      this.datosImporteHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoImporte));
      this.datosRecargaHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoRecarga));
      this.datosConsumoHistorico = this._funcionesService.decodificarToken(JSON.parse(historico.historicoConsumo));
      this.datosConsumoKilovatios = this._funcionesService.decodificarToken(JSON.parse(historico.historicoKilovatios));
      this.anioFiltradoConsumo = historico.anioGrafico;
      this.consumoMasPrecio(this.anioFiltradoConsumo, 'historico');//Añado esta linea porque no estaba rellenando el grafico 'Recarga actual' No se si es del todo correcto
      this.cargarGraficos();
    } else {
      this.consumoMasPrecio(this.anioFiltradoConsumo, 'historico');
    }

    if (this.almnService.getValue("recargaEnHistorico")) {
      var recargaEnHistorico = JSON.parse(atob(this.almnService.getValue('recargaEnHistorico')));
      this.consumoActual = this._funcionesService.decodificarToken(JSON.parse(recargaEnHistorico.consumoActual));
    } else {
      this.consumoMasPrecio(new Date().getFullYear(), 'recargaEnHistorico');
    }

    this.cargarDescuentos();

    this.tusDescuentosHistorico = [{ acierta: ['Acierta'], }, { online: ['Online'], }, { tarifa: ['Tarifa'], }, { mes: [''], }, { ahorro: ['Ahorro'] }];

    if (this.almnService.getValue("tusDescuentosHistorico")) {
      var descuentosHistorico = JSON.parse(atob(this.almnService.getValue('tusDescuentosHistorico')));
      this.tusDescuentosHistorico = this._funcionesService.decodificarToken(JSON.parse(descuentosHistorico));
    } else {
      this.cargarHistoricoDescuentos();
    }
    // TODO lanzar funcion recuperacián datos toda la página
  }

  cargarEstimacion(precios) {
    this._contratosService.getCalculoEstimacion(this.dataSource2[0].CodigoContrato, this.dataSource2[0]['EntornoBD']).subscribe(
      async data => {
        var consumos = this._funcionesService.decodificarToken(data);

        var fecha = new Date(consumos.FechaFin).getTime() - new Date(consumos.FechaInicio).getTime();
        fecha = Math.ceil(fecha / (1000 * 3600 * 24));

        var listadoNomPotencias = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
        var calculoPrecioPotencia = 0;
        var calculoPrecioEnergia = 0;

        if (fecha > 0) {
          precios.forEach((element, key) => {
            calculoPrecioPotencia += (this.dataSource2[0][listadoNomPotencias[key]] * element.PrecioP) * fecha;
            calculoPrecioEnergia += consumos.Consumos[key + 1] * element.PrecioE;
          });
        }


        this.tusDescuentosTarifaGrupo.forEach(element => {
          calculoPrecioEnergia = calculoPrecioEnergia * (100 - Math.abs(element.CargoDescuentoEnergia)) / 100;
        });

        var calculoFinal = Math.round(calculoPrecioPotencia + calculoPrecioEnergia);

        if (this.isProductoPrecarga) {
          const valorMax = calculoFinal > this.dataSource2[0].RecargaActual ? calculoFinal + (calculoFinal * 0.1) : this.dataSource2[0].RecargaActual + (this.dataSource2[0].RecargaActual * 0.1);

          this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
          this.estimacionConsumo.porcentajeRecarga = Math.round((parseInt(this.dataSource2[0].RecargaActual as any) * 100) / valorMax);
          this.estimacionConsumo.porcentajeDiffConsumo = parseInt(calculoFinal as any) * 100 / (parseInt(this.dataSource2[0].RecargaActual as any));
          this.estimacionConsumo.porcentajeDiffConsumo = Math.trunc(this.estimacionConsumo.porcentajeDiffConsumo);
          // this.estimacionConsumo.porcentajeDiffConsumo = 120;

          // console.log(calculoPrecioEnergia);
          // console.log(this.estimacionConsumo);
        } else {
          if ('fijo' == this.TarifaGrupo) {
            this.estimacionConsumo.precioConsumo = calculoFinal;

            const valorMax = calculoFinal + (calculoFinal * 0.1);
            this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
          }

          if ('indexada' == this.TarifaGrupo) {
            consumos.Consumos.forEach(element => {
              this.estimacionConsumo.consumoTotal += element;
            });

            this.estimacionConsumo.valorMax = calculoFinal + (calculoFinal * 0.1);
            this.estimacionConsumo.consumo = Math.round((calculoFinal * 100) / this.estimacionConsumo.valorMax);
          }
        }
        // console.log(this.estimacionConsumo);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'los consumos');
      });
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  cargarDescuentos() {
    const numPeriodicidad = this.dataSource2[0].IsPrecarga ? 'MENSUAL' == (this.dataSource2[0].Periodicidad || "").toUpperCase() ? 1 : 'BIMENSUAL' == (this.dataSource2[0].Periodicidad || "").toUpperCase() ? 2 : 3 : 0;

    this._contratosService.getDescuentosTarifaGrupo(this.dataSource2[0].IdTarifaGrupo, numPeriodicidad).subscribe(
      async data => {
        var descuentos = this._funcionesService.decodificarToken(data);
        descuentos = this._contratosService.recalcularDescuentos(descuentos);
        if (descuentos.length != 0) {
          this.tusDescuentosTarifaGrupo = descuentos.sort((a, b) => (a.TipoCargoDescuento < b.TipoCargoDescuento) ? 1 : ((b.TipoCargoDescuento < a.TipoCargoDescuento) ? -1 : 0));
        } else {
          this.tusDescuentosTarifaGrupo = []
        }

        this._contratosService.getPreciosContrato(this.dataSource2[0].CodigoContrato, this.dataSource2[0]['EntornoBD']).subscribe(
          async data => {
            var precios = this._funcionesService.decodificarToken(data);
            // console.log(precios)
            this.cargarEstimacion(precios);
          }, (err) => {
            this._funcionesService.controlStatusCallBacks(err, 'la estimación');
          })

      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'tus descuentos');
      })
  }

  cargarHistoricoDescuentos() {
    this.isLoadingHistoricoConsumo = true;
    this._contratosService.getDescuentosHistorico(this.dataSource2[0].IdContrato).subscribe(
      async data => {
        this.isLoadingHistoricoConsumo = false;
        var historicoDto = this._funcionesService.decodificarToken(data);
        // console.log("HISTORICO DESCUENTOS: ", historicoDto)
        if (historicoDto.length > 1) {
          var listadoanio = historicoDto.length / 12 as any;
          this.listadoDescuentoContrato = [];

          for (var i = 0; i < Math.ceil(listadoanio); i++) {
            this.listadoDescuentoContrato.push(i + 1 + 'º');
          }

          this.anioFiltradoDescuento = this.listadoDescuentoContrato[Math.ceil(listadoanio) - 1];
          historicoDto.forEach(element => {
            
            var dtotarifa = 0
            if (element.Periodicidad == 1) {
              dtotarifa = 8;
            } else if (element.Periodicidad == 2) {
              dtotarifa = 11;
            } else if (element.Periodicidad == 3) {
              dtotarifa = 12;
            }
            this.tusDescuentosHistorico[0].acierta.push(element.Cargos[1].IsDescuentoUtilizado + '-' + Math.abs(element.Cargos[1].Importe));
            this.tusDescuentosHistorico[1].online.push(element.Cargos[2].IsDescuentoUtilizado + '-' + Math.abs(element.Cargos[2].Importe));
            this.tusDescuentosHistorico[2].tarifa.push(element.Recibo != null ? true + '-' + dtotarifa : false + "-" + 0);
            // this.tusDescuentosHistorico[2].tarifa.push(element.Cargos[2].IsDescuentoUtilizado + '-' + Math.abs(element.Cargos[2].Importe));
            this.tusDescuentosHistorico[3].mes.push(this.arrayDeMeses[new Date(element.Mes).getMonth() + 1] + ' - ' + new Date(element.Mes).getFullYear().toString().substring(2, 4));
            this.tusDescuentosHistorico[4].ahorro.push(Math.abs(element.Cargos[0].Importe + element.Cargos[1].Importe + element.Cargos[2].Importe));
          });
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'el historico de descuentos');
      })
  }

  consumoMasPrecio(fechaAnio, texto) {
    var anioSeleccionado = moment(new Date('01/01/' + fechaAnio)).format('YYYY-MM-DD HH:mm:ss').toString();
    var contrato = JSON.parse(atob(this.almnService.getValue("ContratoVisible")));
    var importe = [], consumo = [], recarga = [];
    var contadorCalls = 0;

    this._facturasService.getImportes(parseInt(contrato.IdCups),fechaAnio + '-01-01', fechaAnio + '-12-31', contrato.IdContrato, contrato.IdCliente).subscribe(
      async datosImporte => {
        importe = this._funcionesService.decodificarToken(datosImporte);

        if (contadorCalls == 2) {
          this.calculoDatosGrafico(importe, recarga, consumo);
        } else {
          contadorCalls++;
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'importe');
      })

    this._facturasService.getConsumos(parseInt(contrato.IdCups),fechaAnio + '-01-01', fechaAnio + '-12-31', contrato.IdContrato, contrato.IdCliente).subscribe(
      async datosConsumo => {
        consumo = this._funcionesService.decodificarToken(datosConsumo);

        if (contadorCalls == 2) {
          this.calculoDatosGrafico(importe, recarga, consumo);
        } else {
          contadorCalls++;
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'consumo');
      })

    this._facturasService.getRecarga(contrato.Entorno == 'Luz' ? 'E1' : 'E2', anioSeleccionado, contrato.IdContrato, contrato.IdCliente).subscribe(
      async datosRecarga => {
        recarga = this._funcionesService.decodificarToken(datosRecarga);

        if (contadorCalls == 2) {
          this.calculoDatosGrafico(importe, recarga, consumo);
        } else {
          contadorCalls++;
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'recarga');
      })
  }

  calculoDatosGrafico(importe, recarga, consumo) {
    // console.log(importe, recarga, consumo);
    this.datosConsumoHistorico = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.datosImporteHistorico = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.datosRecargaHistorico = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.datosConsumoKilovatios = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (consumo) {
      consumo.forEach((element, key) => {
        this.datosConsumoHistorico[new Date(element['FechaFactura']).getMonth()] = element.ConsumoActivaTotal;
      });
    }

    if (importe) {
      importe.forEach((element, key) => {
        this.datosImporteHistorico[new Date(element['FechaFactura']).getMonth()] = element.Importe;
      });
    }

    if (recarga) {
      recarga.forEach((element, key) => {
        var fecha = new Date(element['Fecha']).getMonth();
        this.datosRecargaHistorico[fecha] = element.Cuota;
        this.datosConsumoKilovatios[fecha] = element.ValorMaximo;
      });
    }

    var listado = {
      'historicoImporte': this._funcionesService.codificarDatos(this.datosImporteHistorico),
      'historicoRecarga': this._funcionesService.codificarDatos(this.datosRecargaHistorico),
      'historicoConsumo': this._funcionesService.codificarDatos(this.datosConsumoHistorico),
      'historicoKilovatios': this._funcionesService.codificarDatos(this.datosConsumoKilovatios),
      'anioGrafico': this.anioFiltradoConsumo
    }

    this.almnService.setValue('historico', btoa(JSON.stringify(listado)))

    this.cargarGraficos();
    this.consumoYrecarga(importe, recarga, consumo);
  }

  cargarGraficos() {
    this.options = JSON.parse(this._GraficosService.graficosOption);
    var preservThis = this;
    if (this.dataSource2[0].IsPrecarga) {
      const valorMasAlto = this.datosConsumoHistorico.concat(this.datosConsumoKilovatios);
      this.charHistoricoConsumo = {
        lang: this.options,
        chart: { type: 'column' },
        saveInstance(chartInstance): void { this.chart = chartInstance; },
        legend: { align: 'left', verticalAlign: 'bottom', layout: 'horizontal', x: 0, y: 0, itemMarginTop: 10, itemMarginBottom: 0, },
        navigation: { buttonOptions: { enabled: false } },
        title: { text: '' },
        xAxis: { categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'], crosshair: false },
        yAxis: { min: 0, max: Math.max(...valorMasAlto), tickAmount: 6, title: { text: 'kWh' } },
        tooltip: {useHTML: true,shared: true,backgroundColor: '#ffffff00',borderWidth: 0,shadow: false,style: {padding: 0},
          formatter: function (x) {
            if(1 == this.points.length) {
              var htmlLabel = '';
              switch (this.points[0].color) {
                case '#fff523':
                  htmlLabel = '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
                    '<tr><td style="font-size:15px;padding: 9px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;' +
                    'border-radius: 50%; margin-right: 10px;"></div> Consumo </td><td style="font-size:1.07rem; padding: 6px;">' + 
                    '<ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr></table>'
                  break;
                case '#6f49ce':
                  htmlLabel = '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
                    '<tr><td style="font-size:15px;padding: 9px; display: flex; align-items: center;"><div style="background-color:#6f49ce;width: 20px; height: 20px;' +
                    'border-radius: 50%;margin-right: 10px;"></div> Recarga </td><td style="font-size:1.07rem; padding: 6px;">' + 
                    preservThis.datosRecargaHistorico[this.points[0].point.index] + '€</td></tr></table>'
                  break;
              }
              return htmlLabel;
            } else {
              return '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
              '<tr><td style="font-size:15px;padding:6px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;' +
              'border-radius: 50%; margin-right: 10px;"></div> Consumo </td><td style="font-size:1.07rem; padding:6px;"><ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr>' + 
              '<tr><td style="font-size:15px;padding:9px; display: flex; align-items: center;"><div style="background-color:#6f49ce;width: 20px; height: 20px;' +
              'border-radius: 50%;margin-right: 10px;"></div> Recarga </td><td style="font-size:1.07rem; padding:6px;">' + preservThis.datosRecargaHistorico[this.points[0].point.index] + '€</td></tr>' + 
              '</table>'
            }
          }
        },
        plotOptions: { column: { pointPadding: 0, borderWidth: 0, borderRadius: 20 } },
        series: [{
          name: 'Consumo',
          data: this.datosConsumoHistorico,
          color: '#fff523'
        }, {
          name: 'Recarga',
          data: this.datosConsumoKilovatios,
          color: '#6f49ce'
        }]
      }
    } else {
      this.charHistoricoConsumo = {
        lang: this.options,
        chart: { type: 'column' },
        saveInstance(chartInstance): void { this.chart = chartInstance; },
        legend: { align: 'left', verticalAlign: 'bottom', layout: 'horizontal', x: 0, y: 0, itemMarginTop: 10, itemMarginBottom: 0, },
        navigation: { buttonOptions: { enabled: false } },
        title: { text: '' },
        xAxis: { categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'], crosshair: false },
        yAxis: { min: 0, max: Math.max(...this.datosConsumoHistorico), tickAmount: 6, title: { text: 'kWh' } },
        tooltip: {useHTML: true,shared: true,backgroundColor: '#ffffff00',borderWidth: 0,shadow: false,style: {padding: 0},
        formatter: function (x) {
          return '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
          '<tr><td style="font-size:15px; padding: 6px; display: flex; align-items: center;"><div style="background-color:#fff523;width: 20px; height: 20px;border-radius: 50%; margin-right: 10px;">' + 
          '</div> Consumo </td><td style="font-size:1.07rem; padding: 6px;"><ul style="margin: 0;list-style: none;padding: 0;"><li>'+ preservThis.datosImporteHistorico[this.points[0].point.index] +'€</li><li style="padding: 4px 0;">'+ preservThis.datosConsumoHistorico[this.points[0].point.index] +'kWh</li></ul></td><tr></table>'
          }
        },
        plotOptions: { column: { pointPadding: 0, borderWidth: 0, borderRadius: 20 } },
        series: [{
          name: 'Consumo',
          data: this.datosConsumoHistorico,
          color: '#fff523'
        }]
      }
    }

    this.oneToOneFlag = this.updateFlag = true;
  }

  consumoYrecarga(importe, recarga, consumo) {
    var arrayPeriodicidad = [];
    var valorBime = 1;
    var valorTrim = 1;

    recarga.forEach((element, key) => {
      if (new Date(element['Fecha']).getMonth() == new Date().getMonth()) {
        element['Fecha'] = new Date(element['Fecha']);
        this.recargaActual.recarga = element.Cuota;
        this.recargaActual.consumo = this.datosConsumoHistorico[element.Fecha.getMonth()];
      }

      switch (element.IdPeriodicidadPrecarga) {
        case 1:
          arrayPeriodicidad.push({
            periodo: 1,
            mes: new Date(element.Fecha).getMonth()
          });
          break;
        case 2:
          arrayPeriodicidad.push({
            periodo: valorBime,
            mes: new Date(element.Fecha).getMonth()
          });

          if(valorBime == 2) {
            valorBime = 1;
          } else {
            valorBime++;
          }
          break;
        case 3:
          arrayPeriodicidad.push({
            periodo: valorTrim,
            mes: new Date(element.Fecha).getMonth()
          });
          if(valorTrim == 3) {
            valorTrim = 1;
          } else {
            valorTrim++;
          }
          break;
      }
    });



    this.recargaActual.periodo = arrayPeriodicidad.filter(x=> x.mes == new Date().getMonth()).map(y => y.periodo)[0];
    this.recargaActual.diasCurso = (100 * this.recargaActual.periodo) / this.recargaActual.numPeriocidad;


    var totalImp = 0, veranoImp = 0, inviernoImp = 0;
    var totalCon = 0, veranoCon = 0, inviernCon = 0;

    this.datosImporteHistorico.forEach((element, key) => {
      totalImp += element;

      if (key == 5 || key == 6 || key == 7 || key == 8) { //Calculo importe medio
        veranoImp += element;
      }

      if (key == 11 || key == 0 || key == 1 || key == 2) {
        inviernoImp += element;
      }
    });

    this.datosConsumoHistorico.forEach((element, key) => { //Calculo consumo medio
      totalCon += element;

      if (key == 5 || key == 6 || key == 7 || key == 8) {
        veranoCon += element;
      }

      if (key == 11 || key == 0 || key == 1 || key == 2) {
        inviernCon += element;
      }
    });
    // console.log(this.datosImporteHistorico)
    // console.log(this.datosConsumoHistorico)
    const mesAnterior = new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1;
    this.consumoActual.consumoUltimoMes = this.datosImporteHistorico[mesAnterior].toFixed(2) + "€-" + this.datosConsumoHistorico[mesAnterior] + 'kWh';
    this.consumoActual.consumoMedioTotal = (totalImp / this.datosImporteHistorico.length).toFixed(2) + '€-' + (totalCon / this.datosConsumoHistorico.length).toFixed(0) + 'kWh';
    this.consumoActual.consumoMedioVerano = (veranoImp / 4).toFixed(2) + '€-' + (veranoCon / 4) + 'kWh';
    this.consumoActual.consumoMedioInvierno = (inviernoImp / 4).toFixed(2) + '€-' + (inviernCon / 4) + 'kWh';

    var listado2 = {
      'consumoActual': this._funcionesService.codificarDatos(this.consumoActual),
      'recargaActual': this._funcionesService.codificarDatos(this.recargaActual)
    }
    console.log(this.recargaActual);
    
    this.almnService.setValue('recargaEnHistorico', btoa(JSON.stringify(listado2)))
  }

  search(query: string) {
    query = query.toLowerCase();
    const result = this.select(query);
    this.cupsFiltered = result;
  }

  select(query: string): string[] {
    const result: string[] = [];
    for (const a of this.arrayCups) {
      if (a.CodigoCups.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }
}