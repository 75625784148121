<app-header></app-header>

<div class="pageContent">
  <mat-card class="tablaListadoContratos">
    <!-- <h3>Contratos activos 2</h3> -->

    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{{dataSource2[0].CodigoContrato}}</div>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].ContratoSituacion">
                  {{dataSource2[0].ContratoSituacion}}
                  <i>-</i>
                  <span>{{dataSource2[0].Tarifa}}</span>
                </td>
                <td>
                  <span title="dataSource2[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource2[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource2[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource2[0].NombreCliente">{{dataSource2[0].Tarifa}}</td>
                <td title="dataSource2[0].NombreCliente"><span>{{dataSource2[0].NombreCliente}}</span></td>
                <td title="{{dataSource2[0].DireccionSuministro}}"><span>{{dataSource2[0].DireccionSuministro}}</span></td>
                <td>
                  <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                </td>
                <td>
                  <div>
                    <i class="fal fa-angle-down"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel-header>
        <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

          <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
              <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource2[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource2[0].CodigoContrato"> 
                <td>
                  <div >{{contrato.CodigoContrato}}</div>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  {{contrato.ContratoSituacion}}
                  <i>-</i>
                  <span>{{contrato.Tarifa}}</span>
                </td>
                <td>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
              </tr>
            </tbody>
          </table>
        </div>
         
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

  <div class="container1">
    <mat-card class="cardsContents cardConsumo">
      <h2 class="tituloCards">Consumo</h2>

      <table>
        <tr>
          <td>Consumo último mes: </td>
          <td><b>{{consumoActual.consumoUltimoMes}}</b></td>
        </tr>
        <tr>
          <td>Consumo medio total: </td>
          <td><b>{{consumoActual.consumoMedioTotal}}</b></td>
        </tr>
        <tr>
          <td>Consumo medio verano: </td>
          <td><b>{{consumoActual.consumoMedioVerano}}</b></td>
        </tr>
        <tr>
          <td>Consumo medio invierno: </td>
          <td><b>{{consumoActual.consumoMedioInvierno}}</b></td>
        </tr>
      </table>

    </mat-card>
    <mat-card class="cardsContents cardRecargaActual" *ngIf="isProductoPrecarga">
      <h2 class="tituloCards" style="font-weight: 500;">
        <b>Recarga actual</b>
        <span *ngIf="recargaActual.periodicidad">: {{recargaActual.periodicidad}}</span>
      </h2>
      <article>
        <section id="recuadroRecarga">
          <circle-progress
            [percent]= recargaActual.diasCurso
            [radius]="48"
            [outerStrokeWidth]="2"
            [outerStrokeColor]="'#fff523'"
            [innerStrokeColor]="'#6f49ce'"
            [backgroundColor]= "'#6f49ce'"
            [backgroundPadding]= "-8"
            [showSubtitle]="false"
            [showTitle]="false"
            [showUnits]="false"
            [responsive]="true"
            [space]="5">
          </circle-progress>
          <div>
            <span>Has recargado:</span>
            <h1 style="font-weight: 500;">{{recargaActual.recarga * recargaActual.numPeriocidad}}€</h1>
            <span>{{recargaActual.recarga}}€ por mes</span>
          </div>
        </section>
  
        <section id="footerRecarga">
          <span style="font-size: 14px; font-weight: 600;">*Estás en el mes {{recargaActual.periodo}} de tu recarga</span>
          <button class="btn btnStyle_2" style="padding: 0 20px;" [routerLink]="['/transacciones/cambios']">Modificar <i class="fas fa-pen"></i></button>
        </section>
      </article>

      <div class="blurProductoPrecarga" *ngIf="!isProductoPrecarga">
        <div id="blurBackGround"></div>

        <div id="textBlur">
          <span style="color: #fff523">I</span> <span style="color: #6f49ce;">N</span> <span style="color: #fff523">F</span> <span style="color: #6f49ce;">Ó</span> <span style="color: #fff523">R</span> <span style="color: #6f49ce;">M</span> <span style="color: #fff523">A</span> <span style="color: #6f49ce;">T</span> <span style="color: #fff523">E</span> <span style="color: #6f49ce;">&nbsp;</span> <span style="color: #fff523;">&nbsp;</span> <span style="color: #6f49ce;">A</span> <span style="color: #fff523">Q</span> <span style="color: #6f49ce;">U</span> <span style="color: #fff523">Í</span>
        </div>
      </div>

    </mat-card>

    <mat-card class="cardsContents cardDescuentosMes">
      <h2 class="tituloCards">Descuento último mes</h2>
      
      <table class="tablaDescuentos">
        <!--Esta controlado solo para que vengan 3 datos-->
        <tr>
          <td *ngIf="isProductoPrecarga">Descuento Tarifa</td>
          <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[2] ? tusDescuentosTarifaGrupo[2].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Tarifa</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
          <td>
            <span *ngIf="isProductoPrecarga">Activo</span>
            <span *ngIf="!isProductoPrecarga">Desactivado</span>
          </td>
          <td>
            <img *ngIf="isProductoPrecarga" src="assets/img/checkDescuentos.png" width="35px">
            <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
          </td>
        </tr>
        <tr>
          <td>Descuento Online</td>
          <td>{{tusDescuentosTarifaGrupo[0] ? tusDescuentosTarifaGrupo[0].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td><span>{{descuentoOnline}}</span></td>
          <td [ngSwitch]="descuentoOnline">
            <img src="assets/img/checkDescuentos.png" width="35px" *ngSwitchCase="'Activo'">
            <img src="assets/img/crossDescuentos.png" width="35px" *ngSwitchCase="'Perdido'">
            <img src="assets/img/interrogationDescuentos.png" width="35px" *ngSwitchCase="'Peligro'">
          </td>
        </tr>
        <tr>
          <td *ngIf="isProductoPrecarga">Descuento Acierta</td>
          <td *ngIf="isProductoPrecarga">{{tusDescuentosTarifaGrupo[1] ? tusDescuentosTarifaGrupo[1].CargoDescuentoEnergia || 0 : 0}}%</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">Descuento Acierta</td>
          <td *ngIf="!isProductoPrecarga" style="color: #a9a9a9;">0%</td>
          <td>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90">Activo</span>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110">En peligro</span>
            <span *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110">Perdido</span>
            <span *ngIf="!isProductoPrecarga">Desactivado</span>
          </td>
          <td>
            <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo < 90" src="assets/img/checkDescuentos.png" width="35px">
            <img *ngIf="isProductoPrecarga && estimacionConsumo.porcentajeDiffConsumo > 110" src="assets/img/crossDescuentos.png" width="35px">
            <img *ngIf="isProductoPrecarga  && estimacionConsumo.porcentajeDiffConsumo > 90 && estimacionConsumo.porcentajeDiffConsumo < 110" src="assets/img/interrogationDescuentos.png" width="35px">
            <i *ngIf="!isProductoPrecarga" class="fas fa-power-off"></i>
          </td>
        </tr>
      </table>
    </mat-card>
  </div>

  <div>
    <mat-card class="cardsContents historicoConsumoMenual">
      <div class="tituloHistoricoConsumoMenual">
        <h2 class="tituloCards">Histórico de consumo general</h2>
        <mat-select placeholder="Año" (selectionChange)="consumoMasPrecio(anioFiltradoConsumo, 'historico')"
          [(ngModel)]="anioFiltradoConsumo">
          <mat-option [value]="anio" *ngFor="let anio of listadoYears">Año: {{ anio }}</mat-option>
        </mat-select>
      </div>

      <div>
        <highcharts-chart [Highcharts]="Highcharts" class="grafico" (load)="saveInstance($event.context)" [(update)]="updateFlag" [oneToOne]="oneToOneFlag"
        [(options)]="charHistoricoConsumo" style="width: 100%; display: block; overflow: hidden;"></highcharts-chart>
          
      </div>
    </mat-card>
  </div>

  <div>
    <mat-card class="cardsContents cardHistoricoDescuento">
      <div class="tituloHistoricoDescuento">
        <h2 class="tituloCards">Histórico descuentos y ahorro</h2>
        <mat-select placeholder="Año" [(ngModel)]="anioFiltradoDescuento">
          <mat-option [value]="year" *ngFor="let year of listadoDescuentoContrato">Año contrato: {{ year }}</mat-option>
        </mat-select>
      </div>

      <article class="tableHistoricoDescuento">
        <table>
          <tr *ngFor="let historico of tusDescuentosHistorico">
            <td *ngFor="let aci of historico.acierta">
              <span *ngIf="aci == 'Acierta'">{{aci}}</span>
              <div *ngIf="aci != 'Acierta'" style="display: flex;justify-content: center;align-items: end;">
                <img src="assets/img/checkDescuentos.png" width="35px" *ngIf="aci.split('-')[0] === 'true'">
                <img src="assets/img/crossDescuentos.png" width="35px" *ngIf="aci.split('-')[0] === 'false'">
                <span style="position: absolute;margin-bottom: -20px;">{{aci.split('-')[1]}}%</span>
              </div>
            </td>
            <td *ngFor="let onl of historico.online">
              <span *ngIf="onl == 'Online'">{{onl}}</span>
              <div *ngIf="onl != 'Online'" style="display: flex;justify-content: center;align-items: end;">
                <img src="assets/img/checkDescuentos.png" width="35px" *ngIf="onl.split('-')[0] === 'true'">
                <img src="assets/img/crossDescuentos.png" width="35px" *ngIf="onl.split('-')[0] === 'false'">
                <span style="position: absolute;margin-bottom: -20px;">{{onl.split('-')[1]}}%</span>
              </div>
            </td>
            <td *ngFor="let tar of historico.tarifa; let i = index">
              <span *ngIf="tar == 'Tarifa'">{{tar}}</span>
              <div *ngIf="tar != 'Tarifa'" style="display: flex;justify-content: center;align-items: end;">
                <img src="assets/img/checkDescuentos.png" width="35px" *ngIf="tar.split('-')[0] === 'true'">
                <img src="assets/img/crossDescuentos.png" width="35px" *ngIf="tar.split('-')[0] === 'false'">
                <span style="position: absolute;margin-bottom: -20px;">{{tar.split('-')[1]}}%</span>
              </div>
            </td>
            <td *ngFor="let mes of historico.mes">{{mes}}</td>
            <td *ngFor="let aho of historico.ahorro; let i = index">
              {{aho}} <span *ngIf="i != 0">%</span>
            </td>
          </tr>
        </table>
      </article>

      <div class="buttonFooterCards" style="justify-content: space-between; align-items: center;">
        <span>
          <b>Este porcentaje de ahorro corresponde al descuento sobre el precio de la energía</b>
        </span>
        <a href="https://wekiwi.es/te-ayudamos/" target="_blank">
          <button class="btn btnStyle_2">Más info</button>
        </a>
      </div>

      <div class="blurProductoPrecarga" *ngIf="isLoadingHistoricoConsumo">
        <div id="blurBackGround"></div>
        
        <div>
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </mat-card>

    <mat-card class="cardsContents cardConsejo" *ngIf="mostrarConsejos">
      <h2 class="tituloCards">Consejos</h2>

      <div>
        <table>
          <!--Esta controlado solo para que vengan 3 datos-->
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>Los meses de verano consumes de media X kwh menos que los de invierno. Con Wekiwi puedes elegir tu 
              recarga verano e invierno de manera independiente. Te enseñamos como hacerlo.</span></td>
          </tr>
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>En el último año has perdido 8 veces el descuento "Acierto". Te recomendamos aumentar tu recarga y obtener un X% de descuento en los próximos meses.</span>
            </td>
          </tr>
          <tr>
            <td><i class="fal fa-lightbulb"></i></td>
            <td><span>En el último año has contactado 2 veces de froma telefónica perdiendo el descuento online. 
              Te recomendamos acomunicarte a tarvés de nuestra sección "Contacto" y obtener en tu próxima factura x% de descuento</span></td>
          </tr>
        </table>
      </div>

      <div class="buttonFooterCards">
        <button class="btn btnStyle_2" disabled>Más consejos</button>
      </div>
    </mat-card>
  </div>

</div>