import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { LoginService } from '../../../services/login/login.service';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { ResumenService } from '../../../services/resumen/resumen.service';
import { GraficosService } from './../../../services/config/graficos/graficos.service';
import { environment } from '../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.scss']
})
export class GraficosComponent implements OnInit {
  TipoCliente: string;
  Highcharts = Highcharts;
  options: any;
  tokenvalidado: any;


  graficoUltimasFacturasAnoMonopunto: any;
  graficoConsumosUltimoAnoMonopunto: any;
  graficoImportesFacturasMonopunto: any;
  graficoConsumosMonopunto: any;
  graficoComparativaImportesMonopunto: any;
  graficoComparativaConsumosMonopunto: any;
  graficoComparativaConsumosMultipunto: any;
  graficoComparativaImportesMultipunto: any;
  graficoFacturasMultipunto: any;
  graficoConsumosMultipunto: any;
  graficoEstadosContratosAdminFincas: any;
  graficoNumeroContratos: any;


  // ARRAYS GRAFICOS MONOPUNTO
  arrayResultadoImportesMonopunto: any;
  entornoImportesMonopunto: any = '';

  arrayResultadoCompImportesMonopunto: any;
  arrayResultadoCompConsumosMonopunto: any;
  arrayResultadoCompConsumosMultipunto: any;
  arrayResultadoCompImportesMultipunto: any;

  arrayGraficoHistImportesMonopunto: any = [];

  MostrarTarifaGrupo:boolean;
  arrayGraficoCompImportesAnioActualMonopunto: any = [];
  arrayGraficoCompConsumosActivaAnioActualMonopunto: any = [];
  arrayGraficoCompConsumosReactivaAnioActualMonopunto: any = [];
  arrayGraficoCompImportesAnioAnteriorMonopunto: any = [];
  arrayGraficoCompConsumosActivaAnioAnteriorMonopunto: any = [];
  arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto: any = [];
  arrayGraficoCompFechasImportesMonopunto: any = [];
  arrayGraficoCompFechasConsumosMonopunto: any = [];

  arrayResultadoConsumosMonopunto: any;
  arrayGraficoActivasConsumosMonopunto: any = [];
  arrayGraficoReactivasConsumosMonopunto: any = [];
  arrayFechasConsumosMonopunto: any = [];

  // ARRAYS GRAFICOS MULTIPUNTO
  arrayGraficoHistImportesMultipunto: any = [];
  arrayFechasFacturasMultipunto: any = [];
  arrayImporteLinea1Multipunto: any = [];
  arrayResultadoFacturasMultipunto: any;
  arrayResultadoConsumosMultipunto: any;
  arrayFechasConsumosMultipunto: any = [];
  arrayDesgloseConsumosActivaMultipunto: any = [];
  arrayDesgloseConsumosReactivaMultipunto: any = [];

  MostrarResumenGraficosDeMultipuntoAAdministradorFincas: boolean;

  // ARRAYS GRAFICOS ADMINISTRADOR FINCAS
  arrayResultadoEstadosContratosFincas: any = [];
  arraySituaciones: any = [];
  arrayContadorContratos: any = [];
  array: any = [];


  pendientespago: any = 0;

  facturasImpagadas: boolean;
  MostrarBotonFacturasImpagadas: any;

  fechaHoy: string;
  fechaInicio: string;
  fechaInicioGraficoMultipunto: string;
  fechaInicioComporativaMonopunto: string;
  iconoresumendatospago: string;
  iconoresumennuevalectura: string;
  iconoresumenconsulta: string;

  usuarioMonopunto:boolean;
  adminInfo: any;
  numClientes = 0;
  numContratosLuz = 0;
  numContratosGas = 0;
  numDeuda = 0;

  datosMultipunto: any;

  tarifaMonopunto: any;
  tarifaGrupoMonopunto: any;
  perfilFacturacionMonopunto: any;
  direccionMonopunto: any;
  cupsMonopunto: any;
  entornoMonopunto: any;
  dialogRef: any;
  companyName: any;
  UltimasFacturasResumen: any;

  arrayConceptos: any = [];
  arrayImportesMultipunto: any = [];
  arrayImportesMonopunto: any = [];
  CupsMultipunto: any;
  TamanoListadoCupsCliente: any;
  arrayCups: any[] = [];
  cupsFiltered: string[];

  DatosCupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;

  datosCupsMonopunto: any;

  spinnerLoadingUltimasFacturasMonopunto = true;
  textoTablaUltimasFacturasVaciaMonopunto = false;

  spinnerLoadingUltimasFacturasMultipunto = true;
  textoTablaUltimasFacturasVacia = false;

  spinnerUltimasFacturasAdminFincas = true;
  textoUltimasFacturasAdminFincas = false;

  spinnerUltimasContratosAdminFincas = true;
  textoUltimasContratosAdminFincas = false;
  mostrarFormularioAlta: boolean;
  luzygas: boolean;
  luz:boolean;
  gas:boolean;
  historico:boolean;
  comparativa:boolean;
  MostrarPerfilFacturacion:boolean;
  DisabledBotonFacturaExcel:boolean;
  DisabledBotonRealizarConsulta:boolean;
  DisabledBotonModificarDatos:boolean;
  DisabledBotonNuevaLectura:boolean;
  TiempoEsperaGraficos:any;
  DialogFacturasPendientePago:any;
  numContratosSinPagar:any;
  internalApiUrl:string = '';
  MostrarDeuda = environment.MostrarDeuda;
  MostrarReactivaComparativaConsumos = environment.MostrarReactivaComparativaConsumos;
  PrefijoNumeracionFacturas = environment.PrefijoNumeracionFacturas;
  GraficoConecta2 = environment.GraficoConecta2;

  HabilitarTPV = environment.HabilitarTPV;


  selectedCups = new FormControl();

  @ViewChild('sort') sort: MatSort;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('sort3') sort3: MatSort;
  @ViewChild('sort4') sort4: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginator3') paginator3: MatPaginator;
  @ViewChild('paginator4') paginator4: MatPaginator;


  columnasUltimasFacturasMesMP: string[] = [];


  columnasUltimasFacturasMesMonopunto: string[] = [];

  idCups: any;


  constructor(
    private router: Router,
    public dialog: MatDialog,
    private _funcionesService: FuncionesService,
    private _facturasService: FacturasService,
    private _loginService: LoginService,
    private almnService:AlmacenamientoService,
    private _contratosService: ContratosService,
    private _resumenService: ResumenService,
    private _GraficosService: GraficosService,
    route: ActivatedRoute
  ) {
    this.TipoCliente = this.almnService.getValue('TipoCliente');
    this.fechaHoy = moment().format('YYYY-MM-DD HH:mm:ss').toString(); // Hoy
    this.fechaInicio = moment().subtract('3', 'month').format('YYYY-MM-DD HH:mm:ss').toString();
    this.fechaInicioGraficoMultipunto = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString();
    this.fechaInicioComporativaMonopunto = moment().subtract(1, 'years').format('YYYY-MM-DD HH:mm:ss').toString();
    this.iconoresumendatospago = environment.iconoresumendatospago;
    this.iconoresumennuevalectura = environment.iconoresumennuevalectura;
    this.iconoresumenconsulta = environment.iconoresumenconsulta;
    this.MostrarPerfilFacturacion=environment.MostrarPerfilFacturacion;
    this.MostrarTarifaGrupo=environment.MostrarTarifaGrupo;
    this.DisabledBotonModificarDatos=environment.DisabledBotonModificarDatos;
    this.DisabledBotonRealizarConsulta=environment.DisabledBotonRealizarConsulta;
    this.DisabledBotonNuevaLectura=environment.DisabledBotonNuevaLectura;
    this.TiempoEsperaGraficos=environment.TiempoEsperaGraficos;
    this.DialogFacturasPendientePago=environment.DialogFacturasPendientePago;
    if(this.almnService.getValue("companyName")){
      this.companyName = this.almnService.getValue("companyName");
    }else{
      this.companyName=environment.companyName;
    }
    this.UltimasFacturasResumen=environment.UltimasFacturasResumen;
    this.internalApiUrl=environment.internalApiUrl;
    // this.internalApiUrl=''; // TODO: CAMBIAR POR LA LINEA ANTERIOR

    this.mostrarFormularioAlta = true;
    this.MostrarResumenGraficosDeMultipuntoAAdministradorFincas = environment.MostrarResumenGraficosDeMultipuntoAAdministradorFincas;
    this.idCups = route.snapshot.paramMap.get("idcups");
    console.log(route.snapshot.paramMap);
   }

  ngOnInit() {

    this.BusquedaNuevoCups(this.idCups);

  }

  ValidacionGraficaConsumo(consumos) {
    let aux = false;
    for (let j = 0; j < consumos.length; j++) {
      if ( Number (consumos[j]) > 0) {
        aux = true;
      }
    }
    return aux;
  }


  
  BusquedaNuevoCups(idCups) {
    this.almnService.setValue('actualCupsFilter', idCups);
    this.almnService.setValue('actualCupsFilterLanguage', this.almnService.getValue('idioma'));
    this._contratosService.getDatosCups(idCups).subscribe(
      async datosCupsMultipunto => {
        this.DatosCupsMultipunto = this._funcionesService.decodificarToken(datosCupsMultipunto);
        this.Distribuidora = this.DatosCupsMultipunto.Distribuidora;
        this.Entorno = this.DatosCupsMultipunto.EntornoTexto;
        this.Tarifa = this.DatosCupsMultipunto.Tarifa;
        this.TarifaGrupo = this.DatosCupsMultipunto.TarifaGrupo;
        this.PerfilFacturacion = this.DatosCupsMultipunto.PerfilFacturacion;
        this.CodigoPostal = this.DatosCupsMultipunto.CodPostal;
        this.Provincia = this.DatosCupsMultipunto.Provincia;
        this.Ciudad = this.DatosCupsMultipunto.Ciudad;
        this.Direccion = this.DatosCupsMultipunto.Calle + ' ' + this.DatosCupsMultipunto.Puerta + ' ' +
        this.DatosCupsMultipunto.Piso + ' ' + this.DatosCupsMultipunto.Aclarador;
        this.Nombre = this.DatosCupsMultipunto.Nombre + ' ' + this.DatosCupsMultipunto.Apellido1 + ' ' + this.DatosCupsMultipunto.Apellido2;
        this.RazonSocial = this.DatosCupsMultipunto.RazonSocial;
      }
    );

    // Gráfico - MULTIPUNTO IMPORTES CONCEPTOS
    setTimeout(() => { this._facturasService.getImportes(idCups, this.fechaInicioGraficoMultipunto, this.fechaHoy, '', '').subscribe(
      async datosFacturasMultipunto => {

        let arrayDesgloseConceptoImpuestosMultipunto: any = [];
        let arrayDesgloseConceptoDerechosMultipunto: any = [];
        let arrayDesgloseConceptoAlquilerMultipunto: any = [];
        let arrayDesgloseConceptoDescuentosCargosMultipunto: any = [];
        let arrayDesgloseConceptoEnergiaMultipunto: any = [];
        let arrayDesgloseConceptoExcesoMultipunto: any = [];
        let arrayDesgloseConceptoGasMultipunto: any = [];
        let arrayDesgloseConceptoImpuestoElectricoMultipunto: any = [];
        let arrayDesgloseConceptoPotenciaMultipunto: any = [];
        let arrayDesgloseConceptoProductosMultipunto: any = [];
        let arrayDesgloseConceptoReactivaMultipunto: any = [];
        let arrayDesgloseConceptoVariosMultipunto: any = [];
        this.arrayFechasFacturasMultipunto = [];

        this.arrayResultadoFacturasMultipunto = this._funcionesService.decodificarToken(datosFacturasMultipunto);

        for (const i of this.arrayResultadoFacturasMultipunto) {
          if (this.almnService.getValue('idioma') !== null) {
            let idiomaLocale = this.almnService.getValue('idioma');
            if (idiomaLocale === 'cat') {
              idiomaLocale = 'ca';
            }
            this.arrayFechasFacturasMultipunto = this.arrayFechasFacturasMultipunto.concat(
              moment(i.FechaFactura).locale(idiomaLocale).format('MMM YY')
            );
          } else {
            this.arrayFechasFacturasMultipunto = this.arrayFechasFacturasMultipunto.concat(
              moment(i.FechaFactura).locale('es').format('MMM YY')
            );
          }

          this.arrayImportesMultipunto = this.arrayImportesMultipunto.concat(i.Importe);

          arrayDesgloseConceptoAlquilerMultipunto = arrayDesgloseConceptoAlquilerMultipunto.concat(i.ImporteAlquiler);
          arrayDesgloseConceptoDerechosMultipunto = arrayDesgloseConceptoDerechosMultipunto.concat(i.ImporteDerechos);
          arrayDesgloseConceptoDescuentosCargosMultipunto = arrayDesgloseConceptoDescuentosCargosMultipunto.concat(i.ImporteDescuentosCargos);
          arrayDesgloseConceptoEnergiaMultipunto = arrayDesgloseConceptoEnergiaMultipunto.concat(i.ImporteEnergia);
          arrayDesgloseConceptoExcesoMultipunto = arrayDesgloseConceptoExcesoMultipunto.concat(i.ImporteExceso);
          arrayDesgloseConceptoGasMultipunto = arrayDesgloseConceptoGasMultipunto.concat(i.ImporteGas);
          arrayDesgloseConceptoImpuestoElectricoMultipunto = arrayDesgloseConceptoImpuestoElectricoMultipunto.concat(i.ImporteImpuestoElectrico);
          arrayDesgloseConceptoPotenciaMultipunto = arrayDesgloseConceptoPotenciaMultipunto.concat(i.ImportePotencia);
          arrayDesgloseConceptoProductosMultipunto = arrayDesgloseConceptoProductosMultipunto.concat(i.ImporteProductos);
          arrayDesgloseConceptoReactivaMultipunto = arrayDesgloseConceptoReactivaMultipunto.concat(i.ImporteReactiva);
          arrayDesgloseConceptoVariosMultipunto = arrayDesgloseConceptoVariosMultipunto.concat(i.ImporteVarios);
          arrayDesgloseConceptoImpuestosMultipunto = arrayDesgloseConceptoImpuestosMultipunto.concat(i.ImporteImpuesto);

        }

        let series = [];
        if (this.DatosCupsMultipunto.EntornoTexto == 'Luz') {
          series = [
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Excesos'), data: arrayDesgloseConceptoExcesoMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Impuesto Electrico'), data: arrayDesgloseConceptoImpuestoElectricoMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Potencia'), data: arrayDesgloseConceptoPotenciaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Reactiva'), data: arrayDesgloseConceptoReactivaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImpuestosMultipunto }
          ];

        } else {
          series = [
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Alquiler'), data: arrayDesgloseConceptoAlquilerMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Derechos'), data: arrayDesgloseConceptoDerechosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Descuentos Cargos'), data: arrayDesgloseConceptoDescuentosCargosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Energia'), data: arrayDesgloseConceptoEnergiaMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Gas'), data: arrayDesgloseConceptoGasMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Productos'), data: arrayDesgloseConceptoProductosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Varios'), data: arrayDesgloseConceptoVariosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Impuesto'), data: arrayDesgloseConceptoImpuestosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Importe Consumo'), data: arrayDesgloseConceptoImpuestosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Importe Peaje'), data: arrayDesgloseConceptoImpuestosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Término Fijo'), data: arrayDesgloseConceptoImpuestosMultipunto },
            { name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Impuesto Hidrocarburo'), data: arrayDesgloseConceptoImpuestosMultipunto }
          ];
        }
        this.options = JSON.parse(
          this._GraficosService.graficosOption
        );
        this.graficoFacturasMultipunto = {
          lang: this.options,
          chart: { type: 'column' },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  'printChart',
                  'separator',
                  'downloadPNG',
                  'downloadJPEG',
                  'downloadPDF',
                  'downloadSVG',
                  'separator',
                  'downloadCSV',
                  'downloadXLS'
                ]
              }
            }

          },
          title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Detalle Facturas') },
          subtitle: {
            text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Se muestran las facturas detalladas.')
          },
          xAxis: { categories: this.arrayFechasFacturasMultipunto },
          yAxis: {
            allowDecimals: false,
            min: 0,
            title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Desglose Conceptos €') }
          },
          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            shared: true
          },
          plotOptions: {
            column: {
              stacking: 'sum'
            },
            series: {
              pointWidth: 30,
              // point: {
              //   events: {
              //     click: function () {
              //       console.log(this); // get the series for the point
              //     }
              //   }
              // }
            }
          },
          series: series,
          credits: { enabled: false }
        };
      }
    ); }, this.TiempoEsperaGraficos);

    // Gráfico - MULTIPUNTO CONSUMOS
    setTimeout(() => { this._facturasService.getConsumos(idCups, this.fechaInicioGraficoMultipunto, this.fechaHoy, '', '').
      subscribe(
        async datosConsumosMultipunto => {

          let arrayDesgloseActivaP1Multipunto: any = [];
          let arrayDesgloseActivaP2Multipunto: any = [];
          let arrayDesgloseActivaP3Multipunto: any = [];
          let arrayDesgloseActivaP4Multipunto: any = [];
          let arrayDesgloseActivaP5Multipunto: any = [];
          let arrayDesgloseActivaP6Multipunto: any = [];
          let arrayDesgloseReactivaP1Multipunto: any = [];
          let arrayDesgloseReactivaP2Multipunto: any = [];
          let arrayDesgloseReactivaP3Multipunto: any = [];
          let arrayDesgloseReactivaP4Multipunto: any = [];
          let arrayDesgloseReactivaP5Multipunto: any = [];
          let arrayDesgloseReactivaP6Multipunto: any = [];
          this.arrayFechasConsumosMultipunto = [];

          this.arrayResultadoConsumosMultipunto = this._funcionesService.decodificarToken(datosConsumosMultipunto);
          for (let i = 0; i < this.arrayResultadoConsumosMultipunto.length; i++) {
            if (this.almnService.getValue('idioma') !== null) {
              let idiomaLocale = this.almnService.getValue('idioma');
              if (idiomaLocale === 'cat') {
                idiomaLocale = 'ca';
              }
              this.arrayFechasConsumosMultipunto = this.arrayFechasConsumosMultipunto.concat(
                  moment(this.arrayResultadoConsumosMultipunto[i].FechaFactura).locale(idiomaLocale).format('MMM YY')
                );
            } else {
              this.arrayFechasConsumosMultipunto = this.arrayFechasConsumosMultipunto.concat(
                moment(this.arrayResultadoConsumosMultipunto[i].FechaFactura).locale('es').format('MMM YY')
              );
            }

            // this.arrayDesgloseConsumosActivaMultipunto = this.arrayDesgloseConsumosActivaMultipunto.concat(this.arrayResultadoConsumosMultipunto[i].ConsumoActiva);
            // this.arrayDesgloseConsumosReactivaMultipunto = this.arrayDesgloseConsumosReactivaMultipunto.concat(this.arrayResultadoConsumosMultipunto[i].ConsumoReactiva);
            arrayDesgloseActivaP1Multipunto = arrayDesgloseActivaP1Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP1).concat('kW');
            arrayDesgloseActivaP2Multipunto = arrayDesgloseActivaP2Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP2).concat('kW');
            arrayDesgloseActivaP3Multipunto = arrayDesgloseActivaP3Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP3).concat('kW');
            arrayDesgloseActivaP4Multipunto = arrayDesgloseActivaP4Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP4).concat('kW');
            arrayDesgloseActivaP5Multipunto = arrayDesgloseActivaP5Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP5).concat('kW');
            arrayDesgloseActivaP6Multipunto = arrayDesgloseActivaP6Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ActivaP6).concat('kW');
            arrayDesgloseReactivaP1Multipunto = arrayDesgloseReactivaP1Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP1).concat('kVArh');
            arrayDesgloseReactivaP2Multipunto = arrayDesgloseReactivaP2Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP2).concat('kVArh');
            arrayDesgloseReactivaP3Multipunto = arrayDesgloseReactivaP3Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP3).concat('kVArh');
            arrayDesgloseReactivaP4Multipunto = arrayDesgloseReactivaP4Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP4).concat('kVArh');
            arrayDesgloseReactivaP5Multipunto = arrayDesgloseReactivaP5Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP5).concat('kVArh');
            arrayDesgloseReactivaP6Multipunto = arrayDesgloseReactivaP6Multipunto.concat(this.arrayResultadoConsumosMultipunto[i].ReactivaP6).concat('kVArh');
          }

          // Fill Array de objetos(arrayObjActivaYReactiva) para mostrar en *grafico Consumos facturas* -> Monopunto. Mostrando datos y las labels solo de los que tengan información

          const potenciasActivas = [];
          const potenciasReactivas = [];
          const numeroPActivaYReactiva = [];
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP1Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP1Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP2Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP2Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP3Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP3Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP4Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP4Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP5Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP5Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseActivaP6Multipunto)) { potenciasActivas.push(arrayDesgloseActivaP6Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP1Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP1Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP2Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP2Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP3Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP3Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP4Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP4Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP5Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP5Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }
          if (this.ValidacionGraficaConsumo(arrayDesgloseReactivaP6Multipunto)) { potenciasReactivas.push(arrayDesgloseReactivaP6Multipunto); numeroPActivaYReactiva.push(1); } else { numeroPActivaYReactiva.push(0); }

          let arrayObjActivaYReactiva = new Array();
          let tota = 0;
          for (let i = 0; i < potenciasActivas.length; i++) {

            if (potenciasActivas[i]) {
              arrayObjActivaYReactiva[i] = new Object();
              if (numeroPActivaYReactiva[i] === 0) {
                arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Activa P') + (i + 2);
              } else {
                arrayObjActivaYReactiva[i].name = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Activa P') + (i + 1);
              }
              arrayObjActivaYReactiva[i].data  = potenciasActivas[i];
              arrayObjActivaYReactiva[i].stack = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Activa');
            }

            tota = potenciasActivas.length + i;

            if (potenciasReactivas[i]) {
              arrayObjActivaYReactiva[tota] = new Object();
              if (numeroPActivaYReactiva[tota] === 0) {
                arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Reactiva P') + (i + 2);
              } else {
                arrayObjActivaYReactiva[tota].name = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Reactiva P') + (i + 1);
              }
              arrayObjActivaYReactiva[tota].data  = potenciasReactivas[i];
              arrayObjActivaYReactiva[tota].stack = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Reactiva');
            }

          }
          // /Fill Array


          this.options = JSON.parse(this._GraficosService.graficosOption);

          let printConsumos = [];
          for (let i = 0; i < arrayObjActivaYReactiva.length; i++) {
            for (let j = 0; j < arrayObjActivaYReactiva[i]['data'].length; j++) {
              if ( Number (arrayObjActivaYReactiva[i]['data'][j]) >= 0) {
                printConsumos.push(arrayObjActivaYReactiva[i]['data'][j]);
              }
            }
            arrayObjActivaYReactiva[i]['data'] = printConsumos;
            printConsumos = [];
          }
          if ('Gas' === this.DatosCupsMultipunto.EntornoTexto ) {
            for (let i = 0; i < arrayObjActivaYReactiva.length; i++){
              if ( 'Activa P2' === arrayObjActivaYReactiva[i]['name'] ){
                arrayObjActivaYReactiva[i]['name'] = this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Energia');
                break;
              }
            }
          }
          if(this.GraficoConecta2){
            arrayObjActivaYReactiva = [
              { name: 'Activa P1 (kWh)', data: arrayDesgloseActivaP1Multipunto},
              { name: 'Activa P2 (kWh)', data: arrayDesgloseActivaP2Multipunto },
              { name: 'Activa P3 (kWh)', data: arrayDesgloseActivaP3Multipunto},
              { name: 'Activa P4 (kWh)', data: arrayDesgloseActivaP4Multipunto},
              { name: 'Activa P5 (kWh)', data: arrayDesgloseActivaP5Multipunto},
              { name: 'Activa P6 (kWh)', data: arrayDesgloseActivaP6Multipunto}
            ]
          }


          // console.log("arrayObjActivaYReactiva");
          // console.log(arrayObjActivaYReactiva);


          this.graficoConsumosMultipunto = {
            lang: this.options,
            chart: { type: 'column' },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    'printChart',
                    'separator',
                    'downloadPNG',
                    'downloadJPEG',
                    'downloadPDF',
                    'downloadSVG',
                    'separator',
                    'downloadCSV',
                    'downloadXLS'
                  ]
                }
              }

            },
            title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Desglose Consumos') },
            xAxis: { categories: this.arrayFechasConsumosMultipunto },
            yAxis: {
              allowDecimals: false,
              min: 0,
              title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Desglose Consumos') }
            },
            tooltip: {
              pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
              shared: true
            },
            plotOptions: {
              column: {
                stacking: 'normal'
              }
            },
            series: arrayObjActivaYReactiva
            //[
              // { name: 'Activa P1 (kWh)', data: arrayDesgloseActivaP1Multipunto},
              // { name: 'Activa P2 (kWh)', data: arrayDesgloseActivaP2Multipunto },
              // { name: 'Activa P3 (kWh)', data: arrayDesgloseActivaP3Multipunto},
              // { name: 'Activa P4 (kWh)', data: arrayDesgloseActivaP4Multipunto},
              // { name: 'Activa P5 (kWh)', data: arrayDesgloseActivaP5Multipunto},
              // { name: 'Activa P6 (kWh)', data: arrayDesgloseActivaP6Multipunto},
              // { name: 'Reactiva P1 (kVArh)', data: arrayDesgloseReactivaP1Multipunto},
              // { name: 'Reactiva P2 (kVArh)', data: arrayDesgloseReactivaP2Multipunto},
              // { name: 'Reactiva P3 (kVArh)', data: arrayDesgloseReactivaP3Multipunto},
              // { name: 'Reactiva P4 (kVArh)', data: arrayDesgloseReactivaP4Multipunto},
              // { name: 'Reactiva P5 (kVArh)', data: arrayDesgloseReactivaP5Multipunto },
              // { name: 'Reactiva P6 (kVArh)', data: arrayDesgloseReactivaP6Multipunto}
            //]
            ,
            credits: { enabled: false }
          };
        }
      ); }, this.TiempoEsperaGraficos);

    // GRAFICO COMPARATIVA CONSUMOS MULTIPUNTO
    setTimeout(() => { this._facturasService.getConsumosBianual(idCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
      async datosCompConsumosMultipunto => {

        // this.arrayResultadoCompConsumosMultipunto = [];
        this.arrayGraficoCompFechasConsumosMonopunto = [];
        this.arrayGraficoCompConsumosActivaAnioActualMonopunto = [];
        this.arrayGraficoCompConsumosReactivaAnioActualMonopunto = [];
        this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto = [];
        this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto = [];
        this.arrayResultadoCompConsumosMultipunto = this._funcionesService.decodificarToken(datosCompConsumosMultipunto);

        this.options = JSON.parse(this._GraficosService.graficosOption);



            // console.log("MULTIPUNTO CONSUMOS");
            // console.log(this.arrayResultadoCompConsumosMultipunto);

            // this.arrayResultadoCompConsumosMultipunto.forEach(function(consumo, key) {

            //   this.arrayGraficoCompConsumosActivaAnioActualMonopunto.push(consumo.ConsumoActivaTotal);
            //   this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.push(consumo.ConsumoReactivaTotal);
            //   this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.push(consumo.ConsumoActivaTotal);
            //   this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.push(consumo.ConsumoReactivaTotal);

            // });


            for (let i = 1; i < Object.keys(this.arrayResultadoCompConsumosMultipunto.Valores[0]).length; i++) {
              
              this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[0]["m"+i]);
              this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[1]["m"+i]);
              this.arrayGraficoCompConsumosActivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[2]["m"+i]);
              this.arrayGraficoCompConsumosReactivaAnioActualMonopunto.push(this.arrayResultadoCompConsumosMultipunto.Valores[3]["m"+i]);
              
            }

            // console.log(this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto);
            // console.log(this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto);
            // console.log(this.arrayGraficoCompConsumosActivaAnioActualMonopunto);
            // console.log(this.arrayGraficoCompConsumosReactivaAnioActualMonopunto);

            this.graficoComparativaConsumosMultipunto = {
              lang: this.options,
              chart: { type: 'area' },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      'printChart',
                      'separator',
                      'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      'downloadSVG',
                      'separator',
                      'downloadCSV',
                      'downloadXLS'
                    ]
                  }
                }

              },
              title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa Consumos Facturas') },
            subtitle: {
              text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa consumos de las facturas año actual y año anterior.')
              },
              xAxis: [{
                categories: this.options.months
              }
            ],
              yAxis: { title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Consumo en kWh') } },
              series: [
              {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Anterior Activa'),
                  states: { hover: { color: '#ff9696' } },
                  data: this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto,
                  color: "#ff9696"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Actual Activa'),
                  states: { hover: { color: '#dab8ed' } },
                  data: this.arrayGraficoCompConsumosActivaAnioActualMonopunto,
                  color: "#dab8ed"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Anterior Reactiva'),
                  states: { hover: { color: '#89ccd3' } },
                  data: this.arrayGraficoCompConsumosReactivaAnioAnteriorMonopunto,
                  color: "#89ccd3"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Actual Reactiva'),
                  states: { hover: { color: '#89d38e' } },
                  data: this.arrayGraficoCompConsumosReactivaAnioActualMonopunto,
                  color: "#89d38e"
                }
              ],
              plotOptions: {
                series: {
                  cursor: 'pointer',
                  // events: {
                  //   click: function (e) {
                  //     console.log('Descargar factura');
                  //   }
                  // }
                }
              },
              credits: { enabled: false }
            };

        // if(this.companyName==='Grupo Gasindur S.L.'){
        //   this.options = JSON.parse(this._GraficosService.graficosOption);
        //   this.graficoComparativaConsumosMultipunto = {
        //     lang: this.options,
        //     chart: { type: 'line' },
        //     exporting: {
        //       buttons: {
        //         contextButton: {
        //           menuItems: [
        //             'printChart',
        //             'separator',
        //             'downloadPNG',
        //             'downloadJPEG',
        //             'downloadPDF',
        //             'downloadSVG',
        //             'separator',
        //             'downloadCSV',
        //             'downloadXLS'
        //           ]
        //         }
        //       }

        //     },
        //     title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa Consumos Facturas') },
        //     subtitle: {
        //       text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa consumos de las facturas año actual y año anterior.')

        //     },
        //     xAxis: {
        //       categories:
        //         this.arrayGraficoCompFechasConsumosMonopunto
        //       ,
        //       crosshair: true
        //     },
        //     yAxis: { title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Consumo en kWh')  } },
        //     series: [
        //       {
        //         name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Actual Activa'),
        //         states: {
        //           hover: { color: '#BADA55' }
        //         },
        //         data: this.arrayGraficoCompConsumosActivaAnioActualMonopunto
        //       },
        //      {
        //         name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Anterior Activa'),
        //         states: {
        //           hover: { color: '#BADA55' }
        //         },
        //         data: this.arrayGraficoCompConsumosActivaAnioAnteriorMonopunto
        //       }
        //     ],
        //     plotOptions: {
        //       series: {
        //         cursor: 'pointer',
        //         events: {
        //           click: function (e) {
        //             console.log('Descargar factura');
        //           }
        //         },
        //         pointWidth: 30
        //       }
        //     },
        //     credits: { enabled: false }
        //   };
        // }else{


          // arraydatosGraficaComparativaConsumos.push(aux);
          
        // }

      }
    ); }, this.TiempoEsperaGraficos);


    // COMPARATIVA FACTURAS MULTIPUNTO
    setTimeout(() => {


        this._facturasService.getImportesBianual(idCups, this.fechaInicioComporativaMonopunto, this.fechaHoy).subscribe(
        async datosCompImportesMultipunto => {
        
            this.arrayGraficoHistImportesMonopunto = [];
            this.arrayGraficoCompFechasImportesMonopunto = [];
            this.arrayGraficoCompImportesAnioActualMonopunto = [];
            this.arrayGraficoCompImportesAnioAnteriorMonopunto = [];
            this.arrayResultadoCompImportesMultipunto = [];
            this.arrayResultadoCompImportesMultipunto = this._funcionesService.decodificarToken(datosCompImportesMultipunto);
            // console.log(this.arrayResultadoCompImportesMultipunto);

            this.options = JSON.parse(this._GraficosService.graficosOption);

            for (let i = 1; i < Object.keys(this.arrayResultadoCompImportesMultipunto.Valores[0]).length; i++) {
              
              this.arrayGraficoCompImportesAnioActualMonopunto.push(this.arrayResultadoCompImportesMultipunto.Valores[0]["m"+i]);
              this.arrayGraficoCompImportesAnioAnteriorMonopunto.push(this.arrayResultadoCompImportesMultipunto.Valores[1]["m"+i]);
            }
            
            // console.log( "MULTIPUNTO IMPORTES");
            // console.log( this.arrayGraficoCompImportesAnioActualMonopunto);
            // console.log( this.arrayGraficoCompImportesAnioAnteriorMonopunto);

            this.graficoComparativaImportesMultipunto = {
              lang: this.options,
              chart: { type: 'area' },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      'printChart',
                      'separator',
                      'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      'downloadSVG',
                      'separator',
                      'downloadCSV',
                      'downloadXLS'
                    ]
                  }
                }

              },
              title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa Importes Facturas') },
              subtitle: {
                text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Comparativa importes de las facturas año actual y año anterior.')
              },
              xAxis: [{
                categories: this.options.months
              }
            ],
              yAxis: { title: { text: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Importe en €') } },
              series: [
              {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Anterior'),
                  states: { hover: { color: '#BADA55' } },
                  data: this.arrayGraficoCompImportesAnioActualMonopunto,
                  color: "#89ccd3"
                },
                {
                  name: this._GraficosService.traduccionDeTextos(this.almnService.getValue('idioma'), 'Año Actual'),
                  states: { hover: { color: '#BADA55' } },
                  data: this.arrayGraficoCompImportesAnioAnteriorMonopunto,
                  color: "#89d38e"
                }
              ],
              plotOptions: {
                series: {
                  cursor: 'pointer',
                  // events: {
                  //   click: function (e) {
                  //     console.log('Descargar factura');
                  //   }
                  // }
                }
              },
              credits: { enabled: false }
            };

          });

    }, this.TiempoEsperaGraficos);


  }

  

  

}
