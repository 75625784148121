<div class="cabecera">
  <h1>Calculadora online</h1>
  <p>Rellena los campos para calcular tu tarifa</p>
</div>
<div class="contacto">
  <input class="input" type="text" placeholder="Teléfono" />
  <input class="input" type="text" placeholder="Email" />
</div>
<div fxLayoutAlign="center" class="contacto" fxLayout.lt-lg="column">
  <mat-card>
    <h3>Tipo de servicio</h3>
    <mat-card-content>
      <div class="contacto" fxFlex="33">
        <img src="assets/img/gas.png" />
        <p>Gas</p>
      </div>
      <div class="contacto" fxFlex="33">
        <img src="assets/img/luzygas.png" />
        <p>Gas y luz</p>
      </div>
      <div class="contacto" fxFlex="33">
        <img src="assets/img/luz.png" />
        <p>Luz</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="cuota">
  <h3>¿A cuál de estas opciones te aplicamos la cuota personalizada?</h3>
  <mat-card>
    <mat-card-content class="circulos">
      <div fxFlex="30"></div>
      <div class="elipse" fxFlex="10">
        <p>A tu vivienda habitual</p>
      </div>
      <div class="elipse" fxFlex="10">
        <p>A tu segunda residencia</p>
      </div>
      <div class="elipse" fxFlex="10">
        <p>A tu comunidad de vecinos</p>
      </div>
      <div class="elipse" fxFlex="10">
        <p>A tu negocio de empresa</p>
      </div>
      <div fxFlex="30"></div>
    </mat-card-content>
  </mat-card>
  <!-- <img src="assets/img/viviendas.png"> -->
</div>
<div class="suministro">
  <h3>Por favor, indícanos tu punto de suministro</h3>
  <mat-tab-group>
    <mat-tab label="Dirección">
      <br />
      <input class="input" type="text" placeholder="Código postal" />
      <input class="input" type="text" placeholder="Dirección completo" />
    </mat-tab>
    <mat-tab label="CUPS">
      <label>CUPS</label>
      <input class="input" type="text" placeholder="ESXXXXXXXXXXX" />
      <p>¿Tienes dudas?</p>
      <p>Déjanos tu teléfono o si lo prefieres llámanos al XXXXXXXXX</p>
    </mat-tab>
    <mat-tab label="Adjuntar tu factura">
      <br />
      <label style="float: left">Tu factura</label>
      <br />
      <div class="matab">
        <div class="adjuntar">
          Arrastra un documento o haz click aquí para subirlo sólo archivos .pdf
        </div>
        <div class="texto">
          Selecciona una de tus factura en fromato .pdf que podamos identificar
          tu punto de suministro. No almacenamos nunca esta factura ni su
          contenido
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Otro" style="text-align: left">
      <br />
      <input class="input" type="text" placeholder="Código postal" />
      <br />
      <label>Tipo de vivienda:</label>
      <mat-checkbox color="primary" class="check">Apartamento</mat-checkbox>
      <mat-checkbox color="primary" class="check">Unifamiliar</mat-checkbox>
      <br />
      <label>Uso de la vivienda:</label>
      <mat-checkbox color="primary" class="check">Principal</mat-checkbox>
      <mat-checkbox color="primary" class="check">Secundaria</mat-checkbox>
      <br />
      <input class="input" type="number" placeholder="M2 de la vivienda" />
      <br />
      <input class="input" type="number" placeholder="Nº de habitantes" />
      <br />
      <input
        class="input"
        type="text"
        placeholder="Tipo de calentador/calefacción"
      />
      <br />
      <button mat-raised-button class="btn" style="margin-bottom: 20px">
        Enviar
      </button>
      <br />
    </mat-tab>
  </mat-tab-group>
  <!-- <p>Dirección</p>
        <input class="input" type="text" placeholder="Código postal">
        <input class="input" type="text" placeholder="Dirección completo">
    <p>CUPS</p>
        <input class="input" type="text" placeholder="Código postal">
        <p>¿Tienes dudas?</p>
        <p>Déjanos tu teléfono</p>
    <p>Adjuntar una factura</p>
        <input class="input" type="text" placeholder="Código postal">
    <p>Otro</p>
        <input class="input" type="text" placeholder="Código postal"> -->
</div>
<div class="tarifa">
  <h3>Tenemos la tarifa ideal para ti</h3>
  <p>
    Morbi sit amet neque tempor, tempus mi sit amet, aliquet diam. Fusce eget
    nisi blandit, porta lectus a, fermentum ex.
  </p>
  <p>In convallis dignissim ornare.</p>
  <!-- <img src="assets/img/Tarifas.png"> -->
  <article>
    <div class="radioChilds">
      <input
        type="radio"
        id="indexada"
        name="tarifa"
        value="indexada"
        checked
      />
      <label for="indexada" class="div">
        <h4>Indexada</h4>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <h1>X€</h1>
      </label>
    </div>

    <div class="radioChilds">
      <input type="radio" id="recarga" name="tarifa" value="recarga" />
      <label for="recarga" class="div">
        <h4>Recarga</h4>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <h1>X€</h1>
      </label>
    </div>

    <div class="radioChilds">
      <input type="radio" id="fijo" name="tarifa" value="fijo" />
      <label for="fijo" class="div">
        <h4>Indexada</h4>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <p>Lorem ipsum dolor sit amet</p>
        <h1>X€</h1>
      </label>
    </div>
  </article>
</div>
<div class="todo">
  <h3>Todo sobre ti:</h3>
  <input class="input" type="text" placeholder="DNI/CIF/NIE" />
  <input class="input" type="text" placeholder="Nombre" />
  <input class="input" type="text" placeholder="Primer apellido" />
  <input class="input" type="text" placeholder="Segundo apellido" />
  <br />
  <input class="input" type="text" placeholder="Teléfono" />
  <input class="input" type="text" placeholder="Email" />
  <input class="input" type="text" placeholder="Configura tu email" />
</div>
<div class="facturacion">
  <h4>Facturación:</h4>
  <input type="text" placeholder="Cuenta bancaria (IBAN)" />
</div>
<div class="descuento">
  <h4>Facturación:</h4>
  <input
    class="input"
    type="text"
    placeholder="¿Tienes un código de descuento?"
  />
  <br />
  <mat-checkbox color="primary"
    >Acepto recibir comunicaciones comerciales</mat-checkbox
  >
  <br />
  <mat-checkbox color="primary">Acepto términos y condiciones</mat-checkbox>
</div>
<br />
<div class="calcular">
  <button mat-raised-button class="btn">CALCULAR</button>
</div>
