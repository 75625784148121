import { Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { FuncionesService } from 'src/app/services/funciones/funciones.service';
import { Contrato } from '../../../interfaces/contrato';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { PasswordService } from 'src/app/services/password/password.service';
import { LoginDialogComponent } from '../../dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { cambiosNotificacionComponent } from '../../dialogs/cambiosNotificacion/cambiosNotificacion-dialog.component';
import { FacturasService } from 'src/app/services/facturas/facturas.service';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-cambios',
  templateUrl: './cambios.component.html',
  styleUrls: ['./cambios.component.scss']
})

export class CambiosComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 1350) {
      this.verAlturaLista(true);
    } else {
      this.verAlturaLista(false);
    }
  }

  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  monopunto: boolean;
  contratoSeleccionado: Contrato;
  dataSource: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];
  panelOpenState = false;
  isProductoPrecarga: any = true;

  listadoDeMeses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  listadoRecarga: any[] = [];
  rangoRecarga: any = 0;
  listadoRangoRecargas: any = [];
  charRecargaDistinta: any = { series: [{ data: [] }] };
  options: any;
  Highcharts = Highcharts;
  datosGrafico: any = [];
  cambiarRecargaDistintaModel: any = 1;
  recargaDistintaModel: any = 1;
  cambioPass: FormGroup;
  mensualidadPeriodicidad: any;

  NombreCliente: string;
  Apellido1Cliente: string;
  Apellido2Cliente: string;
  Email: string;
  DireccionCliente: string;
  IdentificadorClienteDNI: string;
  IBAN: string;
  nombrePagador: string;
  Tlf: string;
  TarifaGrupo: string;
  Servicio: string;
  newNameTitular: string;
  newEmailTitular: string;
  newLecturaTitular: string;
  newFechaLecturaTitular: any;
  IsPrecarga: Boolean = false;
  tarifaGrupoSeleccionado: any = {};
  historicoRangoRecarga: any;

  editarDatosPersonales: boolean = false;
  editarDatosBancarios: boolean = false;
  productosList: any = [];
  isLoading: Boolean = false;
  bloquearAltaCambioRecarga: Boolean = true;

  IsEnergiaVerde: Boolean;
  IsPlasticBank: Boolean;
  IsTreedom: Boolean;
  filtroTabla: any = '';
  @ViewChild('rangeRecarga') rangeRecarga: ElementRef;

  constructor(
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
    private _GraficosService: GraficosService,
    private router: Router,
    private almnService:AlmacenamientoService,
    private _passwordService: PasswordService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _loginService: LoginService,
    private _facturasService: FacturasService,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    let refToThis = this;
    setInterval(function () {
      const respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        // console.log(respuesta);
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    if (window.innerWidth <= 1350) {
      setTimeout(() => {
        this.verAlturaLista(true);
      }, 1000);
    }

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);

    this.cambioPass = this.fb.group({
      contrasenaActual: ['', Validators.required],
      contrasenaNueva: ['', [Validators.required, Validators.minLength(4)]],
      contrasenaNuevaD: ['', [Validators.required]],
    },
      {
        validators: [this.validatePassword('contrasenaNueva', 'contrasenaNuevaD'), this.checkActualPass('contrasenaActual')]
      });
  }

  comprobarDatosContratos() {
    console.log('CAMBIOS CONTROLLER');

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")))
    } else {
      this.cargarListadoContratos();
    }
  }

  verAlturaLista(sett) {
    const pasosLista = document.querySelectorAll('.listaCambioTarifa');

    pasosLista.forEach((value, key) => {
      if (key != 0) {
        value.children[0].children[2]['style'].height = 40 + 'px';
        value.children[0].children[2]['style'].height = sett ? (value.clientHeight - 50) + 'px' : 0 + 'px';
      }
    })
  }

  cargarListadoContratos() {
    this._contratosService
      .getListadoContratos(this.almnService.getValue('idCliente'))
      .subscribe(async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data));
        this.listarContratos(data);
      }, (err) => {
        // this._funcionesService.controlStatusCallBacks(err, 'contratos');
      }
      );
  }

  listarContratos(auxContratos) {
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    // Recuperacion contrato Activo si está en sesión
    const aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    if(ajuste){this.almnService.clear('soft')}
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;
    this.dataSource[0] = this.ultimoContratoActivo;
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));

    this.loadData();

    if (this.dataSource[0].IsPrecarga) {
      this.cargarHistoricoRecarga();
    }
    //TODO cargar datos de contrato
    // this.buscaFacturas();
  }

  cargarHistoricoRecarga() {
    this._facturasService.getRecarga(this.dataSource[0].Entorno == 'Luz' ? 'E1' : 'E2', new Date("01-01-" + new Date().getFullYear()), this.dataSource[0].IdContrato, this.dataSource[0].IdCliente).subscribe(
      async datosRecarga => {
        var recarga = this._funcionesService.decodificarToken(datosRecarga);
        this.historicoRangoRecarga = [];
        // console.log(recarga);
        var recargaOrdenadaPorFecha = recarga.sort((a, b) => (a.Fecha > b.Fecha) ? 1 : ((b.Fecha > a.Fecha) ? -1 : 0));
        this.historicoRangoRecarga = recargaOrdenadaPorFecha;
        this.cargarGraficos();
      }, (err) => {
        // this._funcionesService.controlStatusCallBacks(err, 'recarga');
      })
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  changePassword() {
    this._passwordService.getCambioPassword(this.almnService.getValue('idCliente'), this.cambioPass.controls.contrasenaNueva.value, this.almnService.getValue('token')).subscribe(
      datos => {
        const respuesta = this._funcionesService.decodificarToken(datos);
        // console.log(respuesta);
        // this.textoModal = this.respuesta.ResultadoUpdate;
        let textoModal = 'La contraseña se ha cambiado satisfactoriamente. Inicie sesión con su nueva contraseña.';
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            texto: textoModal,
            titulo: 'Cambio de Contraseña',
            estado: true
          }
        });

        this.router.navigate(['/login']);
      },
      err => {
        let textoModal = 'No se ha podido modificar la contraseña';
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            texto: textoModal,
            titulo: 'Error en Cambio Contraseña',
            estado: false
          }
        });
      });
  }

  validatePassword(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);
      if (checkControl.errors && !checkControl.errors.matching) {
        return null;
      }
      if (control.value !== checkControl.value) {
        controls.get(checkControlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  checkActualPass(controlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);

      if (control.errors && !control.errors.matching) {
        return null;
      }
      if (control.value !== this._funcionesService.decodificarToken(JSON.parse(this.almnService.getValue('p')))) {
        controls.get(controlName).setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  cargarGraficos() {
    // Asegúrate de que 'listMes', 'datosGrafico' y 'this.datosGrafico' están inicializados.
  let listMes = [];
  let datosGrafico = [];
  this.datosGrafico = [];

// Recorremos el array 'historicoRangoRecarga'
this.historicoRangoRecarga.forEach((element, index) => {
  var fechaRecarga = new Date(element.Fecha);
  listMes.push(this.listadoDeMeses[fechaRecarga.getMonth()].substring(0, 3)); // Para cargar el gráfico
  datosGrafico.push(element.Cuota); // Para cargar el gráfico

  var ocultar = false;

  // Validamos la periodicidad
  switch (this.dataSource[0].Periodicidad) {
    case 'Mensual':
        ocultar = false;
        break;
    case 'Bimestral':
      ocultar = index % 2 != 0;
      break;
    case 'Trimestral':
      ocultar = ![0, 3, 6, 9].includes(index);
      break;
     default:
         ocultar = index % 2 != 0;
      break;

  }

  // Añadimos datos al gráfico
  this.datosGrafico.push({
    mesesShort: this.listadoDeMeses[fechaRecarga.getMonth()].substring(0, 3) + ' - ' + fechaRecarga.getFullYear().toString().substring(2, 4),
    meses: this.listadoDeMeses[fechaRecarga.getMonth()] + ' - ' + fechaRecarga.getFullYear(),
    cuotas: element.Cuota,
    disabled: fechaRecarga.getMonth() <= new Date().getMonth(),
    ocultar: ocultar,
    fecha: element.Fecha
  });
});


    // console.log(this.datosGrafico);

    this.options = JSON.parse(this._GraficosService.graficosOption);

    this.charRecargaDistinta = {
      lang: this.options,
      chart: {
        type: 'column'
      },
      legend: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: listMes,
        crosshair: false
      },
      yAxis: {
        visible: false,
        min: 0,
        title: {
          text: ''
        }
      },
      tooltip: {
        useHTML: true,
        shared: true,
        backgroundColor: '#ffffff00',
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0
        },
        formatter: function () {
          return '<table style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;padding: 8px;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
            '<tr><td style="padding: 9px; display: flex; align-items: center;"><div style="background-color:#6f49ce;width: 20px; height: 20px; border-radius: 50%; margin-right: 10px;"></div> Importe: </td' +
            '<td style="padding: 6px;">' + this.y + '€</td></tr></table>'
        }

      },
      plotOptions: {
        column: {
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: 20,
          borderRadius: 10
        }
      },
      series: [{
        name: 'Consumo',
        data: datosGrafico,
        color: '#6f49ce'
      }]
    }
  }

  comprobarCambioRecarga(data, origen) {
    if (origen) {
      if (Boolean(this.historicoRangoRecarga)) {
        this.historicoRangoRecarga.forEach(element => {
          if (data.fecha == element.Fecha) {
            if(data.cuotas != this.dataSource[0].RecargaActual) {
              this.bloquearAltaCambioRecarga = data.cuotas == element.Cuota;
            } 
          }
        });
      } else {
        this.bloquearAltaCambioRecarga = false;
      }

      this.datosGrafico.forEach((ele, index) => {
        if (!this.datosGrafico[index].ocultar) {

          switch (this.mensualidadPeriodicidad) {
            case 'Bimestral': case 'Cuatrimestral': case 2:
              this.datosGrafico[index + 1].cuotas = this.datosGrafico[index].cuotas;
              break;
            case 'Trimestral':
              this.datosGrafico[index + 1].cuotas = this.datosGrafico[index].cuotas;
              this.datosGrafico[index + 2].cuotas = this.datosGrafico[index].cuotas;
              break;
            default:
               this.datosGrafico[index + 1].cuotas = this.datosGrafico[index].cuotas;
            break;
              
          }
        }
      });
    } else {
      if(this.listadoRangoRecargas[this.rangoRecarga] != this.dataSource[0].RecargaActual) {
        this.bloquearAltaCambioRecarga = false;
      } else {
        this.bloquearAltaCambioRecarga = true;
      }
    }
  }

  cargarDatos() {
    this.IdentificadorClienteDNI = this.dataSource[0].Identidad;
    this.NombreCliente = this.dataSource[0].NombreCliente;
    this.Apellido1Cliente = this.dataSource[0].PrimerApellido;
    this.Apellido2Cliente = this.dataSource[0].SegundoApellido;
    this.IBAN = this.dataSource[0].IBAN;
    this.nombrePagador = this.dataSource[0]['NombrePagador'];
    this.Tlf = this.dataSource[0].Movil;
    this.Email = this.almnService.getValue('mailCliente');
    this.TarifaGrupo = this.dataSource[0].TarifaGrupo.toUpperCase();
    this.Servicio = this.dataSource[0].Entorno.toUpperCase();
    this.IsEnergiaVerde = this.dataSource[0].IsEnergiaVerde;
    this.IsPlasticBank = this.dataSource[0].IsPlasticBank;
    this.IsTreedom = this.dataSource[0].IsTreedom;
  }

  loadData() {
    // console.log(this.dataSource[0]);
    this.cargarDatos();

    this.isLoading = true;
    this._contratosService.getListadoProducto(this.dataSource[0].Entorno)
      .subscribe((data: any) => {
        this.isLoading = false;
        const producto = JSON.parse(this._funcionesService.getDecodedAccessToken(data).iss);
        this.productosList = producto;

        if (this.productosList.length > 0) {

          this.productosList.forEach((element, key) => {
            element.SegundoTextoPromocionTarifaGrupo = element.TextoPromocionTarifaGrupo.split('-/-')[1];
            element.TextoPromocionTarifaGrupo = element.TextoPromocionTarifaGrupo.split('-/-')[0];
          });
          // console.log(this.productosList);

          if (this.dataSource[0]['IdPromocionTarifaGrupo'] == 0) {
            if (this.dataSource[0].Entorno == 'Luz') {
              if (this.dataSource[0].IsPrecarga) {
                this.dataSource[0]['IdPromocionTarifaGrupo'] = 2; //Producto RECARGA
              } else if (this.dataSource[0]['TarifaGrupo'].toUpperCase().includes('FIJO')) {
                this.dataSource[0]['IdPromocionTarifaGrupo'] = 1; //Producto FIJO
              } else {
                this.dataSource[0]['IdPromocionTarifaGrupo'] = 3; //Producto INDEXADO
              }
            } else {
              if (this.dataSource[0].IsPrecarga) {
                // this.dataSource[0]['IdPromocionTarifaGrupo'] = 2; //Producto RECARGA
              } else if (this.dataSource[0]['TarifaGrupo'].toUpperCase().includes('FIJO')) {
                this.dataSource[0]['IdPromocionTarifaGrupo'] = 4; //Producto FIJO
              } else {
                this.dataSource[0]['IdPromocionTarifaGrupo'] = 5; //Producto INDEXADO
              }
            }
          }

          setTimeout(() => {
            const collection = document.getElementsByName("seleccionProducto") as any;

            for (let i = 0; i < collection.length; i++) {
              if (collection[i]['value'] == this.dataSource[0]['IdPromocionTarifaGrupo']) {
                collection[i]['checked'] = true;
              }
            }

            this.controlTarifas(this.dataSource[0]['IdPromocionTarifaGrupo'], true);
          }, 4);

        } else {
          this.tarifaGrupoSeleccionado.IdPromocionTarifaGrupo = this.dataSource[0]['IdPromocionTarifaGrupo'];
          // this._funcionesService.controlStatusCallBacks('', 'productos web');
          return
        }

      }, (err) => {
        this.isLoading = false;
        // this._funcionesService.controlStatusCallBacks(err, 'productos web');
      });
  }

  controlTarifas(idPromocionyPeriodicidad, isProductoWeb) {
  
    this.IsPrecarga = isProductoWeb ? false : true;
    const entornoRecarga = this.dataSource[0].Entorno.toUpperCase() == 'LUZ' ? 'E1' : 'E2';
    const codigoTarifa = this.listadoTarifas(this.dataSource[0].Tarifa);

    if (isProductoWeb) {
      this.productosList.forEach(obj => {
        if (obj.IdPromocionTarifaGrupo == idPromocionyPeriodicidad) {
          obj.ListadoProductos.forEach(element => {
            if (element.CodigoComunicacion == codigoTarifa) { //Si el codigoATR del producto web coincide con el que trae SIPS
              this.tarifaGrupoSeleccionado = {
                IdTarifaGrupo: element.IdTarifaGrupo,
                TextoPromocion: obj.TextoPromocionTarifaGrupo,
                IsPrecarga: element.IsPrecarga,
                IdPromocionTarifaGrupo: obj.IdPromocionTarifaGrupo
              };
              this.IsPrecarga = element.IsPrecarga; //Compruebo si el TG seleccionado es un producto precarga
            }
          });
        }
      });

      const collection = document.getElementsByName("periodicidad") as any;

      for (let i = 0; i < collection.length; i++) {
        if (this.IsPrecarga) {
          if (collection[i]['value'] == this.dataSource[0]['Periodicidad']) {
            collection[i]['checked'] = true;
          }
        }
      }
      if (!Boolean(this.dataSource[0]['Periodicidad'])) {
        collection[1]['checked'] = true;
      }
    }

    if (this.IsPrecarga) {
      if (isNaN(idPromocionyPeriodicidad)) {
        this.forzarRegularizacion(idPromocionyPeriodicidad, 2);
        this.bloquearAltaCambioRecarga = false;
      }
      this.listadoRangoRecargas = [];//this.dataSource[0].RecargaActual

      const potenciaMax = Math.max(this.dataSource[0].P1, this.dataSource[0].P2, this.dataSource[0].P3, this.dataSource[0].P4, this.dataSource[0].P5, this.dataSource[0].P6);
      if(Boolean(this.dataSource[0]['Periodicidad'])) {

      }
      const periodicidad = Boolean(this.dataSource[0]['Periodicidad']) ? this.dataSource[0]['Periodicidad'] : idPromocionyPeriodicidad;
      this.isLoading = true;
      this._contratosService.ListadoRangosRecarga(entornoRecarga, potenciaMax, periodicidad, codigoTarifa).subscribe((
        data: any) => {
        this.isLoading = false;
        const listadoRecargas = JSON.parse(this._funcionesService.getDecodedAccessToken(data).iss);
        // console.log("LISTADO RECARGAS: ", listadoRecargas);
        var posRecarga = listadoRecargas.findIndex(x => x.Cuota == this.dataSource[0].RecargaActual);

        if (posRecarga == -1) { //En el supuesto caso que la recarga contratada no coincida con ninguna del listado, recalculamos con el consumo estimado
          const mediaConsumo = this.dataSource[0].ConsumoAnualEstimado / 12;
          posRecarga = listadoRecargas.findIndex(x => x.ValorMinimo <= mediaConsumo && x.ValorMaximo >= mediaConsumo && x.Entorno == entornoRecarga);

          if (posRecarga == -1) {
            console.log('No hay ninguna recarga que conincida')
          }
        }
      
        let multiplier = this.mensualidadPeriodicidad == "Bimestral" ? 2 : this.mensualidadPeriodicidad == "Trimestral" ? 3 : 1;
        for (var i = -2; i <= 2; i++) {
          if (listadoRecargas[posRecarga + i]) {

            this.listadoRangoRecargas.push(listadoRecargas[posRecarga + i].Cuota * multiplier);
          }
        }
        console.log(this.dataSource[0].RecargaActual);
        
        this.rangoRecarga = this.listadoRangoRecargas.findIndex(x => x == this.dataSource[0].RecargaActual * multiplier);
        if (this.rangoRecarga == -1) { this.rangoRecarga = 0; }

        setTimeout(() => {
          this.renderer.setProperty(this.rangeRecarga.nativeElement, 'value', this.rangoRecarga);
        }, 4);

      }, (err) => {
        this.isLoading = false;
        // this._funcionesService.controlStatusCallBacks(err, 'listado de recargas');
      });

    }
  }

  forzarRegularizacion(periodicidad, tgp) {
    if (tgp == 2) {

      if (this.dataSource[0]['Periodicidad'] == periodicidad) {
        this.cargarGraficos();
        return;
      }
      var fecha = new Date(this.dataSource[0].FechaAlta);
      this.datosGrafico = [];

      var ocultar = false as any;
      for (var i = 0; i < 12; i++) {

        switch (periodicidad) {
          case 'Mensual':
            ocultar = false;
            break;
          case 'Bimestral':
            ocultar = i % 2 != 0;
            break;
          case 'Trimestral':
            ocultar = ![0, 3, 6, 9].includes(i);
            break;
          default:
            ocultar = i % 2 != 0;
            break;
        }

        this.datosGrafico.push({
          mesesShort: this.listadoDeMeses[fecha.getMonth()].substring(0, 3) + ' - ' + fecha.getFullYear().toString().substring(2, 4),
          meses: this.listadoDeMeses[fecha.getMonth()] + ' - ' + fecha.getFullYear(),
          cuotas: this.dataSource[0].RecargaActual || 0,
          disabled: fecha.getMonth() <= new Date().getMonth() && fecha.getFullYear() <= new Date().getFullYear(),
          ocultar: ocultar,
          fecha: fecha
        });
        fecha = new Date(fecha.setMonth(fecha.getMonth() + 1));
        
      }
    }
  }

  actualizarListadoRecargas(Cuota: Number) {
    this.datosGrafico.forEach(element => {
      if (!element.disabled && !element.ocultar) { //!Boolean(this.dataSource[0]['Periodicidad'])
        element.cuotas = Cuota;
        this.comprobarCambioRecarga([], true);
      }
    });
  }

  listadoTarifas(textoTarifa) {
    let codigoTarifaEnVigor: String;

    switch (textoTarifa) {
      case "2.0TD": case "2.0TD ML":
        codigoTarifaEnVigor = "018";
        break;
      case "3.0TD": case "3.0TD ML":
        codigoTarifaEnVigor = "019";
        break;
      case "6.1TD": case "6.1TD ML":
        codigoTarifaEnVigor = "020";
        break;
      case "6.2TD": case "6.2TD ML":
        codigoTarifaEnVigor = "021";
        break;
      case "6.3TD": case "6.3TD ML":
        codigoTarifaEnVigor = "022";
        break;
      case "6.4TD": case "6.4TD ML":
        codigoTarifaEnVigor = "023";
        break;
      case "3.0TDVE": case "3.0TDVE ML":
        codigoTarifaEnVigor = "024";
        break;
      case "6.1TDVE": case "6.1TDVE ML":
        codigoTarifaEnVigor = "025";
        break;
      case "RL. 1": case "RL.1":
        codigoTarifaEnVigor = 'R1';
        break;
      case "RL. 2": case "RL.2":
        codigoTarifaEnVigor = 'R2';
        break;
      case "RL. 3": case "RL.3":
        codigoTarifaEnVigor = 'R3';
        break;
      case "RL. 4": case "RL.4":
        codigoTarifaEnVigor = 'R4';
        break;
      case "RL. 5": case "RL.5":
        codigoTarifaEnVigor = 'R5';
        break;
      case "RL. 6": case "RL.6":
        codigoTarifaEnVigor = 'R6';
        break;
      case "RL. 7": case "RL.7":
        codigoTarifaEnVigor = 'R7';
        break;
      case "RL. 8": case "RL.8":
        codigoTarifaEnVigor = 'R8';
        break;
      case "RL. 9": case "RL.9":
        codigoTarifaEnVigor = 'R9';
        break;
    }

    return codigoTarifaEnVigor;
  }

  limpiaEspacios(string: String) {
    if (Boolean(string)) {
      return string.replace(' ', '').toUpperCase();
    }
  }

  @ViewChild('usoColaboroB', { static: true }) usoColaboroB: ElementRef;
  @ViewChild('noColaboroB', { static: true }) noColaboroB: ElementRef;
  @ViewChild('usoColaboro', { static: true }) usoColaboro: ElementRef;
  @ViewChild('noColaboro', { static: true }) noColaboro: ElementRef;
  @ViewChild('usoEnergiaVerde', { static: true }) usoEnergiaVerde: ElementRef;
  @ViewChild('noEnergiaVerde', { static: true }) noEnergiaVerde: ElementRef;

  guardarInfo(tipo: string) {
    var mensaje = [];
    // console.log(this.dataSource[0])
    var contratarProducto = '' as any;
    switch (tipo) {
      case "DatosPersonales":
        if(this.limpiaEspacios(this.NombreCliente) != this.dataSource[0].NombreCliente){mensaje.push("El nombre <b>"+this.dataSource[0].NombreCliente+"</b> ha cambiado a <b>"+this.NombreCliente+"</b>.")}
        if(this.limpiaEspacios(this.Apellido1Cliente) != this.dataSource[0].PrimerApellido){mensaje.push("El primer apellido <b>"+this.dataSource[0].PrimerApellido+"</b> ha cambiado a <b>"+this.Apellido1Cliente+"</b>.")}
        if(this.limpiaEspacios(this.Apellido2Cliente) != this.dataSource[0].SegundoApellido){mensaje.push("El segundo apellido <b>"+this.dataSource[0].SegundoApellido+"</b> ha cambiado a <b>"+this.Apellido2Cliente+"</b>.")}
        if(this.limpiaEspacios(this.Tlf) != this.dataSource[0].Movil){mensaje.push("El telefono <b>"+this.dataSource[0].Movil+"</b> ha cambiado a <b>"+this.Tlf+"</b>.")}
        if(this.limpiaEspacios(this.Email) != this.dataSource[0].Email){mensaje.push("El email <b>"+ this.almnService.getValue('mailCliente')+"</b> ha cambiado a <b>"+this.Email+"</b>.")}
        break;
      case "Tarifa":
        // console.log(this.datosGrafico);
        if (this.tarifaGrupoSeleccionado.IdPromocionTarifaGrupo != this.dataSource[0]['IdPromocionTarifaGrupo']) {

          this.mensualidadPeriodicidad = this.mensualidadPeriodicidad == 2 ? 'Bimestral' : this.mensualidadPeriodicidad;
          var textoTarifaGrupo = this.dataSource[0]['IdPromocionTarifaGrupo'] == 1 ? 'Tarifa Fija' : this.dataSource[0]['IdPromocionTarifaGrupo'] == 2 ? 'Tarifa We(kiwi)Win' : 'Tarifa Indexada';

          mensaje.push("<p>La tarifa <b>" + textoTarifaGrupo + "</b> ha cambiado a Tarifa <b>" + this.tarifaGrupoSeleccionado.TextoPromocion + "</b>.</p>")
        
          if (this.tarifaGrupoSeleccionado.IsPrecarga && this.limpiaEspacios(this.mensualidadPeriodicidad) != this.limpiaEspacios(this.dataSource[0].Periodicidad)) {
            mensaje.push("<p>Ha elegido una periodicidad <b>" + this.mensualidadPeriodicidad + "</b> para su recarga.</p>")
          }
          // if (this.tarifaGrupoSeleccionado.IsPrecarga && this.listadoRangoRecargas[this.rangoRecarga] != this.dataSource[0]['RecargaActual']) {
          //   mensaje.push("<p>La recarga seleccionada ha sido de <b>" + this.listadoRangoRecargas[this.rangoRecarga] + "€</b> durante todo el año.</p>")
          // }

          if (this.tarifaGrupoSeleccionado.IsPrecarga && !this.bloquearAltaCambioRecarga) { //Modificación de periodicidad y recarga
            var tableHTML = '<table class="tablaRecargas"><tbody><tr><th colspan="2">Configuración precarga</th></tr>';

            this.datosGrafico.forEach(element => {
                tableHTML += '<tr><td>' + element.meses + '</td><td><b>' + element.cuotas + '&#8364;</b></td>';
            });

            mensaje.push("<p>La configuración precarga es la siguiente:</p>");
            mensaje.push(tableHTML + '</tbody></table>');
          }
        } else {
          if (this.tarifaGrupoSeleccionado.IsPrecarga && this.limpiaEspacios(this.mensualidadPeriodicidad) != this.limpiaEspacios(this.dataSource[0].Periodicidad)) {
            mensaje.push("<p>La periodicidad <b>" + this.dataSource[0]['Periodicidad'] + "</b> ha cambiado a <b>" + this.mensualidadPeriodicidad + "</b>.</p>");

            if (this.tarifaGrupoSeleccionado.IsPrecarga && !this.bloquearAltaCambioRecarga) { //Modificación de periodicidad y recarga
              var ajusteControl = false;
              var tableHTML = '<table class="tablaRecargas"><tbody><tr><th colspan="2">Configuración precarga</th></tr>';

              this.datosGrafico.forEach(element => {
                if(element.cuotas != this.dataSource[0].RecargaActual) {
                  ajusteControl = true;
                  tableHTML += '<tr><td>' + element.meses + '</td><td><b>' + element.cuotas + '&#8364;</b></td>';
                }
              });

              if(ajusteControl) {
                // mensaje.push("<p>Se ha modificado la precarga con los siguientes registros:</p>");
                mensaje.push(tableHTML + '</tbody></table>');
              }
            }
          } else if (this.tarifaGrupoSeleccionado.IsPrecarga && !this.bloquearAltaCambioRecarga) { //Solo modificación de recarga
            var ajusteControl = false;
            var tableHTML = '<table class="tablaRecargas"><tbody><tr><th colspan="4">Modificaciones precarga</th></tr>';

            this.datosGrafico.forEach(element => {
              this.historicoRangoRecarga.filter(function (x) {
                if (element.fecha == x.Fecha && element.cuotas != x.Cuota) {
                  ajusteControl = true;
                  tableHTML += '<tr><td>' + element.meses + '</td><td>' + x.Cuota + '&#8364;</td><td><img src="https://ovwekiwitest.portalswitching.com/assets/img/Rarrow.png"></td><td><b>' + element.cuotas + '&#8364;</b></td>';
                }
              })
            });

            if(ajusteControl) {
              // mensaje.push("<p>Se ha modificado la precarga con los siguientes registros:</p>");
              mensaje.push(tableHTML + '</tbody></table>');
            }
          }

          // if(this.tarifaGrupoSeleccionado.IsPrecarga && this.listadoRangoRecargas[this.rangoRecarga] != this.dataSource[0]['RecargaActual']) {
          //   mensaje.push("<p>La recarga de <b>" + this.dataSource[0]['RecargaActual'] + "€</b> ha cambiado a <b>" + this.listadoRangoRecargas[this.rangoRecarga] + "€</b>.</p>")
          // }

        }
        break;
      case "cambioTitular":
        mensaje.push("<p>El nombre del nuevo titular va a ser <b>" + this.newNameTitular + "</b>, el correo <b>" + this.newEmailTitular + "</b>.</p>")
        if (this.newLecturaTitular == 'fecha y lectura de la última factura') {
          mensaje.push("<p>El cambio se realizará en la <b>" + this.newLecturaTitular + "</b></p>")
        } else {
          mensaje.push("<p>El cambio se realizará el día <b>" + new Date(this.newFechaLecturaTitular) + "</b></p>")
        }
        break;
      case "DatosBancarios":
        if (this.limpiaEspacios(this.IBAN) != this.limpiaEspacios(this.dataSource[0].IBAN)) {
          mensaje.push("<p>El Iban <b>" + this.dataSource[0].IBAN + "</b> ha cambiado a <b>" + this.IBAN + "</b>.</p>")
        }

        if (this.limpiaEspacios(this.nombrePagador) != this.limpiaEspacios(this.dataSource[0]['NombrePagador'])) {
          mensaje.push("<p>El nombre del pagador <b>" + this.dataSource[0]['NombrePagador'] + "</b> ha cambiado a <b>" + this.nombrePagador + "</b>.</p>")
        }
        break;
      case "EnergiaVerde":
        if (this.noEnergiaVerde.nativeElement.checked && this.dataSource[0]['IsEnergiaVerde']) {
          mensaje.push("<p>Va a dejar de usar <b>energía verde</b>.</p>");
          contratarProducto = false;
        } else if (this.usoEnergiaVerde.nativeElement.checked && !this.dataSource[0]['IsEnergiaVerde']) {
          mensaje.push("<p>Va a empezar a usar <b>energía verde</b>.</p>");
          contratarProducto = true;
        }
        break;
      case "PlasticBank":
        if (this.noColaboroB.nativeElement.checked && this.dataSource[0]['IsPlasticBank']) {
          mensaje.push("<p>Va a dejar de colaborar con <b>Plastic Bank</b>.</p>");
          contratarProducto = false;
        } else if (this.usoColaboroB.nativeElement.checked && !this.dataSource[0]['IsPlasticBank']) {
          mensaje.push("<p>Va a empezar a colaborar con <b>Plastic Bank</b>.</p>");
          contratarProducto = true;
        }
        break;
      case "Treedom":
        if (this.noColaboro.nativeElement.checked && this.dataSource[0]['IsTreedom']) {
          mensaje.push("<p>Va a dejar de colaborar con <b>Treedom</b>.</p>");
          contratarProducto = false;
        } else if (this.usoColaboro.nativeElement.checked && !this.dataSource[0]['IsTreedom']) {
          mensaje.push("<p>Va a empezar a colaborar con <b>Treedom</b>.</p>");
          contratarProducto = true;
        }
        break;
    }

    if (mensaje.length > 0) {
      this.dialog.open(cambiosNotificacionComponent, {
        disableClose: true,
        data: {
          'mensaje': mensaje,
          'destinatario': this.almnService.getValue('mailCliente'),
          'codContrato': this.dataSource[0].CodigoContrato,
          'codCups': this.dataSource[0].Cups,
          'Identidad': this.dataSource[0].Identidad,
          'ventana': 'cambios' //Contratar producto - Baja producto
        },
        panelClass: 'cambiosNotificacion'
      }).afterClosed().subscribe(data => {
        this.cargarDatos();
      })
    }
  }
}