import { Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { GraficosService } from 'src/app/services/config/graficos/graficos.service';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { FuncionesService } from 'src/app/services/funciones/funciones.service';
import { Contrato } from '../../../interfaces/contrato';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { PasswordService } from 'src/app/services/password/password.service';
import { LoginDialogComponent } from '../../dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FacturasService } from 'src/app/services/facturas/facturas.service';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';
import { MailService } from 'src/app/services/mail/mail.service';
import { environment } from 'src/environments/environment';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})

export class WalletComponent implements OnInit {
  importeCliente: any;
  numeroFacturas: any;
  CodigoContratos: any;
  CodigoCups: any;
  comprobarEnvioCorreos: Boolean = false;

  constructor(
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
    private almnService:AlmacenamientoService,
    public dialog: MatDialog,
    private _mailService: MailService
  ) {
  }
  ngOnInit() {
    this._contratosService.getWalletCliente(this.almnService.getValue('idCliente'))
    .subscribe(data => {
      const respuesta = this._funcionesService.decodificarToken(data);
      this.importeCliente = respuesta.ImporteCliente.replace(',','.');
      this.numeroFacturas = respuesta.NumeroFactura;
      this.CodigoCups = respuesta.CodigoCups;
      this.CodigoContratos = respuesta.CodigoContrato;
      this.almnService.setValue('importeCliente', parseInt(respuesta.ImporteCliente) >= 0 ? parseInt(respuesta.ImporteCliente).toFixed(0) + '€' : 'E.T' );
    }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'wallet');
    })
  }

  reembolsarImporte() {
    let text = "Se va a crear una solicitud para reembolsar el importe de " + this.importeCliente + "€\n\n ¿Desea continuar?";
    if (confirm(text) == true) {
      this._contratosService.updateWalletCliente(this.almnService.getValue('idCliente'))
      .subscribe(data => {
        const respuesta = this._funcionesService.decodificarToken(data);
        // console.log(respuesta)
        if(respuesta.includes('correctamente')) {
          const data = [{
            destinatario: this.almnService.getValue('mailCliente'),
            Identidad: '',
            codContrato: '',
            codCups: '',
            mensaje: '<p>Hola ' + this.almnService.getValue('nombreCliente') + 
            '</p><p> Tu solicitud está siendo validada y se hará efectiva en los próximos días. </p><p> Gracias por formar parte de los que se atreven a cambiar las cosas.</p><p>Si no te gusta tu energía, dale la vuelta.</p><p>Wekiwi Team</p>',
            interno: false,
            ventana: 'wallet'
          },
          {
            destinatario: environment.email,
            Identidad: '',
            codContrato: '',
            codCups: '',
            mensaje: '<p>' + this.almnService.getValue('nombreCliente') + '</p><p>Identidad: ' + this.almnService.getValue('usuarioCambioPass') + 
            '</p><p>Códigos de contrato: ' + this.CodigoContratos.toString() + '</p><p>Códigos CUPS: ' + this.CodigoCups.toString() + 
            '</p><p>Importe: ' + this.importeCliente + '&#8364;</p><p>Solicitud: Reembolso WALLET</p>',
            interno: true,
            ventana: 'wallet'
          }]
          this.enviarCorreoConfirmacion(data);
        } else {
          alert("No se ha podido realizar el proceso, si el error persiste contacta con nosotros")
        }
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'cadena');
      })
    }
  }

  enviarCorreoConfirmacion(data){
    data.forEach(element => {
      this._mailService.sendMail(element.destinatario, element, element.interno).subscribe(data => {
        if (this.comprobarEnvioCorreos) {
          window.location.reload();
        }
        this.comprobarEnvioCorreos = true;
        // console.log(data)
      })
    });

    
  }
}