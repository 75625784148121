import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-datos-suministro-dialog',
    templateUrl: './datos-suministro-dialog.html',
    styleUrls: ['./datos-suministro-dialog.scss']
  })
  export class DatosSuministroDialogComponent implements OnInit{
    ngOnInit() {
    }


  }