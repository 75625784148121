import { Component, OnInit } from '@angular/core';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { NgModel, FormControl } from '@angular/forms';
import { Contrato } from '../../../interfaces/contrato';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import HC_no_data_to_display from 'highcharts/modules/no-data-to-display';
import { CchService } from 'src/app/services/cch/cch.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
HC_no_data_to_display(Highcharts);

@Component({
  selector: 'app-prevision',
  templateUrl: './prevision.component.html',
  styleUrls: ['./prevision.component.scss']
})

export class PrevisionComponent implements OnInit {

  DatosCupsMultipunto: any;
  CupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;
  CodigoContrato: any;
  cupsFiltered: string[];
  arrayCups: any[] = [];
  isProductoPrecarga: any = true;

  ultimosContratos: Contrato[] = [];
  ultimosContratosAUX: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  monopunto: boolean;
  contratoSeleccionado: Contrato;
  dataSource2: Contrato[] = [{
    CodigoContrato: '',
    Entorno: '',
    TarifaGrupo: '',
    NombreCliente: '',
    DireccionSuministro: ''
  }];

  fechaActual: any = new Date();
  listadoMeses : any[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  mesFiltradoConsumo : any = this.listadoMeses[this.fechaActual.getMonth()];
  Highcharts = Highcharts;
  chartConsumoMesxDias: any = {legend:{enabled: false},series: [{ data: [] }] };
  options: any;
  saveInstance:any;

  estiloVidaFiltro: any;
  edadEstiloVida: any;
  actividadEstiloVida: any;
  checkJardin: any;

  selectedCups = new FormControl();
  filtroTabla: any = '';

  previsionGasto: any = {
    anio: 11 == new Date().getMonth() ? new Date().getFullYear() + 1 : new Date().getFullYear(),
    mes: this.listadoMeses[new Date().getMonth() + 1],
    porcentajeRecarga: 0,
    porcentajeConsumo: 0,
    porcentajeDiffConsumo: 0
  }

  tusDescuentosTarifaGrupo: any = [];
  descuentoOnline: any;
  mostrarAvisos: Boolean = environment.mostrarAvisos;
  mostrarConsejos: Boolean = environment.mostrarConsejos;

  estimacionConsumo: any = {
    recargaActual: 0,
    periodicidadActual: '',
    porcentajeConsumo: 0,
    porcentajeRecarga: 0,
    porcentajeDiffConsumo: 0,
    precioConsumo: 0,
    consumoTotal: 0,
    anio: new Date().getUTCFullYear(),
    mes: this.listadoMeses[new Date().getMonth()]
  };
  constructor(
    private _funcionesService: FuncionesService,
    private _contratosService: ContratosService,
    private almnService:AlmacenamientoService,
    private _CchService: CchService,
    private router: Router
  ) {
  }

  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        console.log(respuesta);
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);
  }

  comprobarDatosContratos() {
    console.log('PREVISION CONTROLLER');

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")))
    } else {
      this.cargarListadoContratos();
    }      
  }

  search(query: string) {
    query = query.toLowerCase();
    const result = this.select(query);
    this.cupsFiltered = result;
  }

  select(query: string): string[] {
    const result: string[] = [];
    for (const a of this.arrayCups) {
      if (a.CodigoCups.toLowerCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  cargarListadoContratos() {
    this._contratosService
      .getListadoContratos(this.almnService.getValue('idCliente'))
      .subscribe(async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data));
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      }
    );
  }

  listarContratos(auxContratos) {
    // console.log(auxContratos);
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    this.ultimosContratosAUX = this._funcionesService.decodificarToken(auxContratos);
    // Recuperacion contrato Activo si está en sesión
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.IdContrato, false);
  }

  filtraListadoContratos(contrato, ajuste) {
    if(ajuste){this.almnService.clear('soft')}
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { return cont.IdContrato === contrato })[0];
    this.isProductoPrecarga = this.ultimoContratoActivo.IsPrecarga;
    this.dataSource2[0] = this.ultimoContratoActivo;
    this.estimacionConsumo.recargaActual = this.ultimoContratoActivo.RecargaActual;
    this.estimacionConsumo.periodicidadActual = this.ultimoContratoActivo.Periodicidad;
    if(this.isProductoPrecarga) {
      this.TarifaGrupo = 'recarga';
    } else if(this.dataSource2[0]['TarifaGrupo'].toUpperCase().includes('FIJO')) {
      this.TarifaGrupo = 'fijo';
    } else {
      this.TarifaGrupo = 'indexada';
    }
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));
    this.cargarGraficos();

    this._contratosService.getDescuentosOnline(this.ultimoContratoActivo, new Date()).subscribe(
      data=> {
        this.descuentoOnline = this._funcionesService.decodificarToken(data);
      }, (err) => {
      this._funcionesService.controlStatusCallBacks(err, 'descuentos');
    })

    this.cargarDescuentos();
    //TODO cargar datos de contrato

    // this.buscaFacturas();
  }

  cargarEstimacion(precios) {
    this._contratosService.getCalculoEstimacion(this.dataSource2[0].CodigoContrato, this.dataSource2[0]['EntornoBD']).subscribe(
      async data => {
        var consumos = this._funcionesService.decodificarToken(data);
        // console.log(consumos);

        var fecha = new Date(consumos.FechaFin).getTime() - new Date(consumos.FechaInicio).getTime();
        fecha = Math.ceil(fecha / (1000 * 3600 * 24));

        var listadoNomPotencias = ['P1','P2','P3','P4','P5','P6'];
        var calculoPrecioPotencia = 0;
        var calculoPrecioEnergia = 0;
  
        if(fecha > 0){
          precios.forEach((element, key) => {
            calculoPrecioPotencia += (this.dataSource2[0][listadoNomPotencias[key]] * element.PrecioP) * fecha;
            calculoPrecioEnergia += consumos.Consumos[key + 1] * element.PrecioE;
          });
        }
        
  
        this.tusDescuentosTarifaGrupo.forEach(element => {
          calculoPrecioEnergia = calculoPrecioEnergia * (100 - Math.abs(element.CargoDescuentoEnergia)) / 100;
        });

        var calculoFinal = Math.round(calculoPrecioPotencia + calculoPrecioEnergia);
        
        if(this.isProductoPrecarga) {
          const valorMax = calculoFinal > this.dataSource2[0].RecargaActual ? calculoFinal + (calculoFinal * 0.1) : this.dataSource2[0].RecargaActual + (this.dataSource2[0].RecargaActual * 0.1);

          this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
          this.estimacionConsumo.porcentajeRecarga = Math.round((parseInt(this.dataSource2[0].RecargaActual as any) * 100) / valorMax);
          this.estimacionConsumo.porcentajeDiffConsumo = parseInt(calculoFinal as any) * 100 / (parseInt(this.dataSource2[0].RecargaActual as any));
          this.estimacionConsumo.porcentajeDiffConsumo = Math.trunc(this.estimacionConsumo.porcentajeDiffConsumo);
          // this.estimacionConsumo.porcentajeDiffConsumo = 120;
          
          // console.log(calculoPrecioEnergia);
          // console.log(this.estimacionConsumo);
        } else {
          if('fijo' == this.TarifaGrupo) {
            this.estimacionConsumo.precioConsumo = calculoFinal;

            const valorMax = calculoFinal + (calculoFinal * 0.1);
            this.estimacionConsumo.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
          }

          if('indexada' == this.TarifaGrupo) {
            consumos.Consumos.forEach(element => {
              this.estimacionConsumo.consumoTotal += element;
            });

            this.estimacionConsumo.valorMax = calculoFinal + (calculoFinal * 0.1);
            this.estimacionConsumo.consumo = Math.round((calculoFinal * 100) / this.estimacionConsumo.valorMax);
          }
        }
        // console.log(this.estimacionConsumo);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'los consumos');
      });
  }

  cargarPrevision(precios) {
    this._contratosService.getCalculoPrevision(this.dataSource2[0].CodigoContrato, this.dataSource2[0]['EntornoBD']).subscribe(
      async data => {
        var consumos = this._funcionesService.decodificarToken(data);
        // console.log(consumos);

        var fecha = new Date(consumos.FechaFin).getTime() - new Date(consumos.FechaInicio).getTime();
        fecha = Math.ceil(fecha / (1000 * 3600 * 24));

        var listadoNomPotencias = ['P1','P2','P3','P4','P5','P6'];
        var calculoPrecioPotencia = 0;
        var calculoPrecioEnergia = 0;
  
        precios.forEach((element, key) => {
          calculoPrecioPotencia += (this.dataSource2[0][listadoNomPotencias[key]] * element.PrecioP) * fecha;
          calculoPrecioEnergia += consumos.Consumos[key + 1] * element.PrecioE;
        });
  
        this.tusDescuentosTarifaGrupo.forEach(element => {
          calculoPrecioEnergia = calculoPrecioEnergia * (100 - Math.abs(element.CargoDescuentoEnergia)) / 100;
        });

        var calculoFinal = Math.round(calculoPrecioPotencia + calculoPrecioEnergia);

        const valorMax = calculoFinal > this.dataSource2[0].RecargaActual ? calculoFinal + (calculoFinal * 0.1) : this.dataSource2[0].RecargaActual + (this.dataSource2[0].RecargaActual * 0.1);

        this.previsionGasto.porcentajeConsumo = Math.round((calculoFinal * 100) / valorMax);
        this.previsionGasto.porcentajeRecarga = Math.round((parseInt(this.dataSource2[0].RecargaActual as any) * 100) / valorMax);
        this.previsionGasto.porcentajeDiffConsumo = parseInt(calculoFinal as any) * 100 / (parseInt(this.dataSource2[0].RecargaActual as any));
        this.previsionGasto.porcentajeDiffConsumo = Math.trunc(this.previsionGasto.porcentajeDiffConsumo);
          
          // console.log(calculoFinal);
          // console.log(this.previsionGasto);
        // console.log("PREVISION-> ", this.previsionGasto);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'los consumos');
      });
  }

  cargarDescuentos() {
    const numPeriodicidad = this.dataSource2[0].IsPrecarga ? 'MENSUAL' == (this.dataSource2[0].Periodicidad || "").toUpperCase() ? 1 : 'BIMENSUAL' == (this.dataSource2[0].Periodicidad || "").toUpperCase() ? 2 : 3 : 0;

    this._contratosService.getDescuentosTarifaGrupo(this.dataSource2[0].IdTarifaGrupo, numPeriodicidad).subscribe(
      async data => {
        var descuentos = this._funcionesService.decodificarToken(data);
        descuentos = this._contratosService.recalcularDescuentos(descuentos);
        if(descuentos.length != 0) {
          this.tusDescuentosTarifaGrupo = descuentos.sort((a,b) => (a.TipoCargoDescuento < b.TipoCargoDescuento) ? 1 : ((b.TipoCargoDescuento < a.TipoCargoDescuento) ? -1 : 0));
        } else {
          this.tusDescuentosTarifaGrupo = []
        }
        
        this._contratosService.getPreciosContrato(this.dataSource2[0].CodigoContrato, this.dataSource2[0]['EntornoBD']).subscribe(
          async data => {
            var precios = this._funcionesService.decodificarToken(data);
            this.cargarEstimacion(precios);
            if(this.isProductoPrecarga) {
              this.cargarPrevision(precios);
            }
        }, (err) => {
          this._funcionesService.controlStatusCallBacks(err, 'la estimación');
        })

        if(!this.isProductoPrecarga) {
          this.previsionGasto.porcentajeConsumo = 0;
          this.previsionGasto.porcentajeRecarga = 0;
          this.previsionGasto.porcentajeDiffConsumo = 0;
        }
        
    }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'tus descuentos');
    })
  }

  irDetallesContrato() {
    this.almnService.setValue('mostrarContrato', 'true');
    this.router.navigate(['/informacion/contratos']);
  }

  cargarGraficos() {
    let indexMesGrafico = this.listadoMeses.indexOf(this.mesFiltradoConsumo);
    var FecInicio = new Date(this.fechaActual.getFullYear(), indexMesGrafico, 1);
    var fechaFin = new Date(this.fechaActual.getFullYear(), indexMesGrafico + 1, 0);

    this._CchService.getTCurvas([parseInt(this.dataSource2[0].CodigoContrato)], moment(FecInicio).set({hour:1,minute:0}).format(), moment(fechaFin).format()).subscribe(
      async data => { 
        var datosCCH = this._funcionesService.decodificarToken(data);
        var diasMes = []; var consumoxDias = []; var consumoPrecio = [];
        var prueba = []; var tempo = 0; var media = 0; var contDias = 0;
        
        if(datosCCH.length != 0) {
          datosCCH[0].ListaCCH.forEach((element,index) => {
            diasMes.push(index + 1);
            // console.log(element);
            if(prueba[prueba.indexOf(tempo)] == new Date(element.FECHAMED).getDate()) {
              media += parseInt(element.ACTIVA);
              contDias++;
              // console.log(media, ' Activa: ', parseInt(element.ACTIVA), ' Día: ', new Date(element.FECHAMED).getDate(), " contDias: ", contDias);
            } 
  
            if(!prueba.includes(new Date(element.FECHAMED).getDate())) {
              prueba.push(new Date(element.FECHAMED).getDate());
              tempo++;
              consumoxDias.push((media == 0 ? parseInt(element.ACTIVA) : media / contDias));
              consumoPrecio.push(((media == 0 ? parseInt(element.ACTIVA) : media / contDias)).toFixed(2)); 
              media = 0;
              contDias = 0;
            }
          });

          this.chartConsumoMesxDias = {
            lang: this.options,
            chart: {
              type: 'scatter'
            },
            legend: {
              enabled: false
            },
            navigation: {
              buttonOptions: {
                enabled:false
              }
            },
            title: {
              text: ''
            },
            xAxis: {
              categories: diasMes,
              crosshair: false
            },
            yAxis: {
              min: 0,
              max: Math.max(...consumoxDias),
              tickAmount: 6,
              title: {
                text: 'kWh'
              }
            },
          
            tooltip: {
              useHTML: true,
              shared: true,
              backgroundColor: '#ffffff00',
              borderWidth: 0,
              shadow: false,
              style: {
                  padding: 0
              },
              formatter: function (x) {
                  return '<div style="background-color: rgba(220, 220, 220, 0.6);border-radius: 15px; font-weight:900;' +
                  'padding:2px 8px ;text-align:center;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">' +
                  '<p>Consumo aproximado</p><p style="font-size:1.07rem;">' +  consumoPrecio[this.point.index] + 'w</p></div>'
              }
            },
            plotOptions: {
              column: {
                pointPadding: 0,
                borderWidth: 0,
                borderRadius: 20
              },
              scatter: {
                marker: {
                    radius: 15,
                },
              }
            },
            series: [{
              name: 'Consumo',
              data: consumoxDias,
              color: '#fff523',
              radius: 30
            }]
          }
        } else {
          this. chartConsumoMesxDias = { series: [{ data: [] }] };
        }

      }, (err) => {
        this. chartConsumoMesxDias = { series: [{ data: [] }] };
        this._funcionesService.controlStatusCallBacks(err, 'conusmo por días');
      });
  }

  filtarDatosGrafico(data) {
    // console.log(data);
  }
}