import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-salir',
  templateUrl: './salir.component.html',
  styleUrls: ['./salir.component.scss']
})
export class SalirComponent implements OnInit {
  constructor(private router: Router,private almnService:AlmacenamientoService) {
    // localStorage.clear();
    this.almnService.clear('hard')
    this.router.navigate(['/login']);
  }

  ngOnInit() { }
}
