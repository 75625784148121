import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { Contrato } from '../../../interfaces/contrato';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-autolectura',
  templateUrl: './autolectura.component.html',
  styleUrls: ['./autolectura.component.scss']
})

export class AutolecturaComponent implements OnInit {
  ultimosContratos: Contrato[] = [];
  ultimoContratoActivo: Contrato;
  monopunto: boolean;
  contratoSeleccionado: Contrato;
  filtraContratos: any;
  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _contratosService: ContratosService,
    private almnService:AlmacenamientoService,
    private _funcionesService: FuncionesService,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute
  ) {
  }
  ngOnInit() {
    var refToThis = this;
    setInterval(function () {
      var respuesta = refToThis._funcionesService.comporbarContratos() as any;

      if (respuesta.__zone_symbol__value) {
        console.log(respuesta);
        refToThis.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")));
      }
    }, 90000)

    setTimeout(() => { this.comprobarDatosContratos(); }, 800);
  }

  comprobarDatosContratos() {
    console.log('AUTOLECTURA CONTROLLER');

    if (this.almnService.getValue("listadoContratos") && this.almnService.getValue("listadoContratos").includes('TextoCifrado')) {
      this.listarContratos(JSON.parse(this.almnService.getValue("listadoContratos")))
    } else {
      this.cargarListadoContratos();
    }      
  }

  cargarListadoContratos() {
    this._contratosService
      .getListadoContratos(this.almnService.getValue('idCliente'))
      .subscribe(async data => {
        this.almnService.setValue("listadoContratos", JSON.stringify(data));
        this.listarContratos(data);
      }, (err) => {
        this._funcionesService.controlStatusCallBacks(err, 'contratos');
      });
  }

  listarContratos(auxContratos) {
    // Listado contratos
    this.ultimosContratos = this._funcionesService.decodificarToken(auxContratos);
    // console.log(this.ultimosContratos);
    this.monopunto = (this.ultimosContratos.length === 1) ? true : false;
    // Recuperacion contrato Activo si está en sesión
    var aux = this.almnService.getValue("ContratoVisible");
    this.contratoSeleccionado = aux != 'dW5kZWZpbmVk' && aux ? JSON.parse(atob(aux)) : this.ultimosContratos[0];
    this.filtraListadoContratos(this.contratoSeleccionado.DireccionSuministro);
    this.filtraContratos = this.contratoSeleccionado.DireccionSuministro;
  }

  filtraListadoContratos(contrato) {
    this.ultimoContratoActivo = this.ultimosContratos.filter(function (cont) { console.log(); return cont.DireccionSuministro === contrato })[0];
    // console.log(this.ultimoContratoActivo);
    this.almnService.setValue("ContratoVisible", btoa(JSON.stringify(this.ultimoContratoActivo)));
    //TODO cargar datos de contrato
    // this.buscaFacturas();
  }
}
